import { createReducer } from '../../utils/misc'

import {
  userConstants
} from '../constants/user'
import {
  authConstants
} from '../constants/auth'
import { formatProfile } from '../../utils/misc'

const initialState = {
  fetching: false,
  id: null,
  username: '',
  email: '',
  userType: '',
  profiles: [],
  currentProfile: null,
  selectedUser: null,
  isAdminModalOpen: false,
  error: null
}

export default createReducer(initialState, {
  [authConstants.AUTH_USER_SUCCESS]: (state, payload) =>
    Object.assign({}, state, {
      id: payload.id,
      username: payload.username,
      email: payload.email,
      userType: payload.userType,
      profiles: payload.profiles.map(p => formatProfile(p)),
      currentProfile: payload.profiles.length > 0 ? payload.profiles[0].id : null,
    }),
  [authConstants.UNAUTH_USER]: (state) =>
    Object.assign({}, state, {
      userType: '',
      profiles: [],
      currentProfile: null
    }),
  [userConstants.FETCH_USER_REQUEST]: (state) =>
    Object.assign({}, state, {
      fetching: true
    }),
  [userConstants.FETCH_USER_SUCCESS]: (state, payload) => 
    Object.assign({}, state, {
      id: payload.id,
      username: payload.username,
      email: payload.email,
      fetching: false
    }),
  [userConstants.FETCH_USER_ERROR]: (state) =>
    Object.assign({}, state, {
      username: '',
      email: '',
      id: null,
      fetching: false
    }),
  [userConstants.CHANGE_PROFILE]: (state, payload) =>
    Object.assign({}, state, {
      currentProfile: payload
    }),
  [userConstants.OPEN_ADMIN_USER_MODAL]: (state, payload) =>
    Object.assign({}, state, {
      selectedUser: payload,
      isAdminModalOpen: true
    }),
  [userConstants.CLOSE_ADMIN_USER_MODAL]: (state) =>
    Object.assign({}, state, {
      selectedUser: null,
      isAdminModalOpen: false
    }),
})
