
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { cognitoCodeLogin, checkAuthUser } from '../../store/actions/auth'
import AuthLoader from '../../components/AuthLoader';
import { Result, Button } from 'antd'

class CognitoCallback extends React.Component {
  componentDidMount () {
    if (this.props.location.search) {
      const queryParams = this.props.location.search
      const queryObj = queryString.parse(queryParams)
      // FALTA COMPARAR STATES DE QUERY
      if (queryObj.code !== undefined) {
        const cognitoCode = queryObj.code
        this.props.cognitoCodeLogin(cognitoCode)
        return
      }
    }
    // code parameter was not found, check if the user is still logged in.
    this.props.checkAuthUser()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.authenticating && !prevProps.isAuthenticated
      && !this.props.authenticating && this.props.isAuthenticated) {
        this.props.history.push('/')
    }
  }

  render () {
    const {
      authenticating
    } = this.props
    if (authenticating)
      return <AuthLoader/>
    else{
      const errorStyle = {
        marginTop: '4rem',
      }
      return <Result
        status="error"
        title="Error al autenticar usuario."
        subTitle={ this.props.errorMessage }
        style={ errorStyle }
        extra={
          this.props.errorMessage !== '' ?
          (<Button type="primary" onClick={() => this.props.history.push('/')}>
                  Ir al Inicio
          </Button>) :
          null
        }
      >
      </Result>
    }   
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    cognitoCodeLogin,
    checkAuthUser,
  }, dispatch)

const mapStateToProps = (state) => ({
  authenticating: state.auth.authenticating,
  isAuthenticated: state.auth.isAuthenticated,
  errorMessage: state.auth.errorMessage,
})

export default connect(mapStateToProps, mapDispatchToProps)(CognitoCallback)
