import React from 'react'
import {
  Icon,
  Upload,
} from 'antd'

const { Dragger } = Upload

class Component extends React.Component {

  getDraggerProps = () => ({
    name: 'file',
    multiple: false,
    accept: '.csv,.txt',
    beforeUpload: (file => {
      const { onRead, reading } = this.props
      reading()
      const reader = new FileReader()
      reader.onload = ({target}) => {
        const {result} = target
        onRead(result)
      }
      reader.readAsText(file)
      return false // Prevent "Real" Uploading
    })
  })

  render() {
    const { disabled } = this.props
    return (
      <Dragger disabled={disabled} {...this.getDraggerProps()}>
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        {
          disabled?
          <p className="ant-upload-text">Seleccione una empresa para activar la carga</p> 
          : <>
            <p className="ant-upload-text">Haga Click o Arrastre CSV para comenzar la carga</p>
            <p className="ant-upload-hint">
              Suba un único archivo CSV. Este será validado. En caso de ser necesario podrá editar el contenido.
              Para realizar una carga correcta, todas las solicitudes deben ser válidas.
            </p>
          </>
        }
      </Dragger>
    )
  }
}

export default Component
