import React from 'react'
import { Select } from 'antd'

const { Option } = Select

const Component = (props) => {
  const {
    value,
    onChange,
    width,
    options, // Array of field objects to order by
    // Each field should have a value key, and a name key for display
  } = props
  return (
    <Select
      value={value}
      onChange={(value) => {
        onChange(value)
      }}
      style={{width: width || '100%'}}
    >
      {options.map(option => 
        <Option
        key={`order-by-${option.value}`}
        value={option.value}
        >
        {option.name}
        </Option>
      )}
    </Select>
  )
}

export default Component