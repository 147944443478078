import { combineReducers } from 'redux'
import {
  createReducer,
  getByIdMap
} from '../../utils/misc'
import {
  workRequestsConstants
} from '../constants/workRequests'
import {
  programsConstants
} from '../constants/programs'
import {
  userConstants
} from '../constants/user'
import {
  profileRolesConstants
} from '../constants/profileRoles'
import { entitiesConstants } from '../constants/entities'
import { filterConstants } from '../constants/filter'
import _ from 'lodash'

const updateAction = (state, payload) => ({
  // Update a single element.
  ...state,
  [payload.id]: { ...state[payload.id], ...payload }
})
const batchUpdateAction = (state, payload) => {
  // Update a list of elements.
  const result = { ...state }
  for (const request of payload) {
    result[request.id] = { ...state[request.id], ...request }
  }
  return result
}

const filterAction = (state, payload) => ({ ...getByIdMap(payload) })
const reMapAction = (state, payload) => payload.map(item => item.id)
const insertSorted = (state, payload) => [payload.id, ...state]
const massiveInsertSorted = (state, payload) => [..._.reverse(payload.map(item => item.id)), ...state]
const removeFromIdOrderIfDeleted = (state, payload) => {
  if (payload && payload.is_deleted) {
    return state.filter(item => item !== payload.id)
  }
  return state
}

const changeWorkRequestSelection = (state, workRequestId) =>
  ({ ...state, [workRequestId]: !state[workRequestId] })
const resetWorkRequestSelection = () => ({})
const resetIdOrder = () => []

const toggleUserVisibilityReducer = {
  [userConstants.TOGGLE_DISABLE_USER_SUCCESS]: (state, payload) => {
    return ({
      ...state,
      [payload.id]: { ...state[payload.id], ...payload }
    })
  },
  [entitiesConstants.CHANGE_USER_TYPE_SUCCESS]: (state, payload) => ({
    ...state,
    [payload.id]: {
      ...state[payload.id],
      ...payload
    }
  })
}

const extraWorkRequestReducers = {
  [workRequestsConstants.CHANGE_WORK_REQUEST_STATUS_SUCCESS]: batchUpdateAction,
  [workRequestsConstants.CONFIRM_REQUEST_WORKS_SUCCESS]: updateAction,
  [filterConstants.FILTER_WORK_REQUEST_SUCCESS]: filterAction,
  [workRequestsConstants.EDIT_WORK_REQUEST_SUCCESS]: updateAction,
  [workRequestsConstants.ADD_WORK_REQUEST_MASSIVE_SUCCESS]: batchUpdateAction
}

const extraRequestModificationReducers = {
  [workRequestsConstants.APPLY_REQUEST_MODIFICATION_SUCCESS]: updateAction
}

const idOrderReducerMap = {
  [workRequestsConstants.ADD_WORK_REQUEST_SUCCESS]: insertSorted,
  [workRequestsConstants.ADD_WORK_REQUEST_MASSIVE_SUCCESS]: massiveInsertSorted,
  [filterConstants.FILTER_WORK_REQUEST_SUCCESS]: reMapAction,
  [workRequestsConstants.FETCH_WORK_REQUESTS]: resetIdOrder,
  [workRequestsConstants.FETCH_WORK_REQUESTS_SUCCESS]: reMapAction,
  [workRequestsConstants.EDIT_WORK_REQUEST_SUCCESS]: removeFromIdOrderIfDeleted
}

const selectedByIdReducerMap = {
  [workRequestsConstants.CHANGE_WORK_REQUEST_SELECTION]: changeWorkRequestSelection,
  [workRequestsConstants.FETCH_WORK_REQUESTS]: resetWorkRequestSelection,
  [filterConstants.FILTER_WORK_REQUEST]: resetWorkRequestSelection,
  [workRequestsConstants.CHANGE_WORK_REQUEST_STATUS_SUCCESS]: resetWorkRequestSelection,
  [workRequestsConstants.CLEAR_WORK_REQUEST_SELECTION]: resetWorkRequestSelection
}

const extraBaseWorkRequestReducers = {
  idOrder: createReducer([], idOrderReducerMap),
  selectedById: createReducer({}, selectedByIdReducerMap)
}


const extraUsersBaseReducers = {
  fetchingUsers: createReducer(false, {
    [userConstants.FETCH_USER_LIST_REQUEST]: () => true,
    [userConstants.FETCH_USER_LIST_SUCCESS]: () => false,
    [userConstants.FETCH_USER_LIST_ERROR]: () => false,
  })
}

const extraCompaniesBaseReducers = {
  fetchingCompanies: createReducer(false, {
    [entitiesConstants.FETCH_COMPANIES]: () => true,
    [entitiesConstants.FETCH_COMPANIES_SUCCESS]: () => false,
    [entitiesConstants.FETCH_COMPANIES_ERROR]: () => false,
  })
}

const createEntityReducer = (fetchAction, receiveAction, addAction = 'DUMMY', editAction = 'DUMMY', extraReducers = {}, extraBaseReducers = {}) => {
  const entitiesById = createReducer({}, {
    [fetchAction]: (state) => ({}),
    [receiveAction]: (state, payload) =>
      getByIdMap(payload),
    [addAction]: (state, payload) =>
      ({ [payload.id]: payload, ...state }),
    [editAction]: (state, payload) =>
      ({
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload
        }
      }),
    ...extraReducers
  })

  const allEntities = createReducer([], {
    [fetchAction]: (state) => [],
    [receiveAction]: (state, payload) =>
      payload.map((item) => item.id),
    [addAction]: (state, payload) =>
      [...state, payload.id]
  })

  const baseReducers = {
    byId: entitiesById,
    allIds: allEntities,
    ...extraBaseReducers
  }

  return combineReducers(baseReducers)
}

const programsReducer = combineReducers({
  current: createReducer({}, {
    [programsConstants.FETCH_PROGRAMS]: (state) => ({}),
    [programsConstants.FETCH_PROGRAMS_SUCCESS]: (state, payload) =>
      _.find(payload, {
        current: true
      }) || {}
  }),
  preliminary: createReducer({}, {
    [programsConstants.FETCH_PROGRAMS]: (state) => ({}),
    [programsConstants.FETCH_PROGRAMS_SUCCESS]: (state, payload) =>
      _.find(payload, {
        preliminary: true
      }) || {},
    [programsConstants.UPDATE_PRELIMINARY_EDITION_SUCCESS]: (state, payload) => payload
  }),
  historic: createReducer({}, {
    [programsConstants.FETCH_PROGRAMS]: (state) => ({}),
    [programsConstants.FETCH_PROGRAMS_SUCCESS]: (state, payload) =>
      getByIdMap(_.filter(payload, {
        preliminary: false,
        current: false
      }))
  }),
  selectedProgram: createReducer('current', {
    [programsConstants.SELECT_PROGRAM]: (state, program) => {
      const programs = ['current', 'preliminary', 'historic']
      if (programs.indexOf(program) !== -1) {
        return program
      }
      throw new Error(`program is not one of ${programs}`)
    },
    [programsConstants.FETCH_PROGRAMS]: () => 'current'
  }),
  movingPrograms: createReducer(false, {
    [programsConstants.MOVE_PROGRAMS]: () => true,
    [programsConstants.MOVE_PROGRAMS_SUCCESS]: () => false,
    [programsConstants.MOVE_PROGRAMS_ERROR]: () => false
  }),
  currentHistoric: createReducer(null, {
    [programsConstants.SELECT_HISTORIC_PROGRAM]: (state, payload) => payload,
    [programsConstants.SELECT_PROGRAM]: () => null
  })
})

const templateReducers = combineReducers({
  massiveLoad: createReducer({}, {
    [entitiesConstants.FETCH_FILE_TEMPLATES]: (state, payload) => ({}),
    [entitiesConstants.FETCH_FILE_TEMPLATES_SUCCESS]: (state, payload) =>
      _.find(payload, (template, key) => {
        return key === 'massive_load'
      }) || {},
    [entitiesConstants.FETCH_FILE_TEMPLATES_ERROR]: (state, payload) => {},
  })
})

export default combineReducers({
  programs: programsReducer,
  workRequests: createEntityReducer(
    workRequestsConstants.FETCH_WORK_REQUESTS,
    workRequestsConstants.FETCH_WORK_REQUESTS_SUCCESS,
    workRequestsConstants.ADD_WORK_REQUEST_SUCCESS,
    'DUMMY',
    extraWorkRequestReducers,
    extraBaseWorkRequestReducers
  ),
  users: createEntityReducer(
    userConstants.FETCH_USER_LIST_REQUEST,
    userConstants.FETCH_USER_LIST_SUCCESS,
    userConstants.ADD_USER_SUCCESS,
    entitiesConstants.CHANGE_USER_COMPANY_ROLES_SUCCESS,
    toggleUserVisibilityReducer,
    extraUsersBaseReducers
  ),
  profileRoles: createEntityReducer(
    profileRolesConstants.FETCH_PROFILE_ROLES_REQUEST,
    profileRolesConstants.FETCH_PROFILE_ROLES_SUCCESS,
    null,
    null
  ),
  companies: createEntityReducer(
    entitiesConstants.FETCH_COMPANIES,
    entitiesConstants.FETCH_COMPANIES_SUCCESS,
    null,
    entitiesConstants.UPDATE_COMPANY_SUCCESS,
    undefined,
    extraCompaniesBaseReducers
  ),
  proyectos: createEntityReducer(
    entitiesConstants.FETCH_PROYECTO,
    entitiesConstants.FETCH_PROYECTO_SUCCESS,
    entitiesConstants.ADD_PROYECTO,
    null
  ),  
  infotecnicaCompanies: createEntityReducer(
    entitiesConstants.FETCH_INFOTECNICA_COMPANIES,
    entitiesConstants.FETCH_INFOTECNICA_COMPANIES_SUCCESS,
    entitiesConstants.ADD_INFOTECNICA_COMPANY,
    null
  ),
  status: createEntityReducer(
    entitiesConstants.FETCH_STATUS_OPTIONS,
    entitiesConstants.FETCH_STATUS_OPTIONS_SUCCESS,
    entitiesConstants.ADD_OPTION,
    null
  ),
  central: createEntityReducer(
    entitiesConstants.FETCH_CENTRAL,
    entitiesConstants.FETCH_CENTRAL_SUCCESS,
    entitiesConstants.ADD_CENTRAL,
    null
  ),
  unit: createEntityReducer(
    entitiesConstants.FETCH_UNIT,
    entitiesConstants.FETCH_UNIT_SUCCESS,
    entitiesConstants.ADD_UNIT,
    null
  ),
  line: createEntityReducer(
    entitiesConstants.FETCH_LINE,
    entitiesConstants.FETCH_LINE_SUCCESS,
    entitiesConstants.ADD_LINE,
    null
  ),
  section: createEntityReducer(
    entitiesConstants.FETCH_SECTION,
    entitiesConstants.FETCH_SECTION_SUCCESS,
    entitiesConstants.ADD_SECTION,
    null
  ),
  substation: createEntityReducer(
    entitiesConstants.FETCH_SUBSTATION,
    entitiesConstants.FETCH_SUBSTATION_SUCCESS,
    entitiesConstants.ADD_SUBSTATION,
    null
  ), 
  element: createEntityReducer(
    entitiesConstants.FETCH_ELEMENT,
    entitiesConstants.FETCH_ELEMENT_SUCCESS,
    entitiesConstants.ADD_ELEMENT,
    null
  ), 
  sub_barra: createEntityReducer(
    entitiesConstants.FETCH_SUB_BARRA_SUCCESS,
    entitiesConstants.FETCH_SUB_BARRA_SUCCESS,
    entitiesConstants.ADD_SUB_BARRA,
    null
  ),  
  sub_pano: createEntityReducer(
    entitiesConstants.FETCH_SUB_PANO_SUCCESS,
    entitiesConstants.FETCH_SUB_PANO_SUCCESS,
    entitiesConstants.ADD_SUB_PANO,
    null
  ),  
  
  //////////////////////////////////////////////////////////////////
  sub_transformadores2D: createEntityReducer(
    entitiesConstants.FETCH_SUB_TRANSFORMADORES2D_SUCCESS,
    entitiesConstants.FETCH_SUB_TRANSFORMADORES2D_SUCCESS,
    entitiesConstants.ADD_SUB_TRANSFORMADORES2D,
    null
  ),  

  sub_transformadores3D: createEntityReducer(
    entitiesConstants.FETCH_SUB_TRANSFORMADORES3D_SUCCESS,
    entitiesConstants.FETCH_SUB_TRANSFORMADORES3D_SUCCESS,
    entitiesConstants.ADD_SUB_TRANSFORMADORES3D,
    null
  ), 

  sub_bancoscondensadores: createEntityReducer(
    entitiesConstants.FETCH_SUB_BANCOSCONDENSADORES_SUCCESS,
    entitiesConstants.FETCH_SUB_BANCOSCONDENSADORES_SUCCESS,
    entitiesConstants.ADD_SUB_BANCOSCONDENSADORES,
    null
  ), 
  
  sub_sistcomper: createEntityReducer(
    entitiesConstants.FETCH_SUB_SISTCOMPER_SUCCESS,
    entitiesConstants.FETCH_SUB_SISTCOMPER_SUCCESS,
    entitiesConstants.ADD_SUB_SISTCOMPER,
    null
  ), 
  
  sub_compensadoresactivos: createEntityReducer(
    entitiesConstants.FETCH_SUB_COMPENSADORESACTIVOS_SUCCESS,
    entitiesConstants.FETCH_SUB_COMPENSADORESACTIVOS_SUCCESS,
    entitiesConstants.ADD_SUB_COMPENSADORESACTIVOS,
    null
  ), 
  
  sub_condensadoresserie: createEntityReducer(
    entitiesConstants.FETCH_SUB_COMPENSADORESSERIE_SUCCESS,
    entitiesConstants.FETCH_SUB_COMPENSADORESSERIE_SUCCESS,
    entitiesConstants.ADD_SUB_COMPENSADORESSERIE,
    null
  ), 
  
  sub_condensadoressincronos: createEntityReducer(
    entitiesConstants.FETCH_SUB_COMPENSADORESSINCRONOS_SUCCESS,
    entitiesConstants.FETCH_SUB_COMPENSADORESSINCRONOS_SUCCESS,
    entitiesConstants.ADD_SUB_COMPENSADORESSINCRONOS,
    null
  ), 
  
  sub_reactores: createEntityReducer(
    entitiesConstants.FETCH_SUB_REACTORES_SUCCESS,
    entitiesConstants.FETCH_SUB_REACTORES_SUCCESS,
    entitiesConstants.ADD_SUB_REACTORES,
    null
  ), 
  
  sub_medfacturacion: createEntityReducer(
    entitiesConstants.FETCH_SUB_MEDFACTURACION_SUCCESS,
    entitiesConstants.FETCH_SUB_MEDFACTURACION_SUCCESS,
    entitiesConstants.ADD_SUB_MEDFACTURACION,
    null
  ),   
///////////////////////////////////////////////////////////////////////////////////////
  region: createEntityReducer(
    entitiesConstants.FETCH_REGION,
    entitiesConstants.FETCH_REGION_SUCCESS,
    entitiesConstants.ADD_REGION,
    null
  ),
  requestModification: createEntityReducer(
    entitiesConstants.FETCH_WORK_REQUEST_MODIFICATIONS,
    entitiesConstants.FETCH_WORK_REQUEST_MODIFICATIONS_SUCCESS,
    workRequestsConstants.ADD_MODIFICATION_REQUEST,
    'DUMMY',
    extraRequestModificationReducers
  ),
  templates: templateReducers
})
