import React from 'react'
import { Spin } from 'antd'
import './AuthLoader.css'

class AuthLoader extends React.Component {
  render () {
    return (
      <div className='AuthLoader'>
        <Spin className='Loader' size='large' />
      </div>
    )
  }
}

export default AuthLoader
