import React from 'react'
import TimeLine from 'react-gantt-timeline'
import { Typography } from 'antd'
import {
  formatWRGanttEntry
} from '../../utils/misc'

const { Paragraph } = Typography

class Component extends React.Component {

  getConfig = () => ({
    header: {
      middle: {
        style: {
          backgroundColor: "#3ca7ac"
        }
      }
    },
    taskList: {
      title: {
        label: "Listado de Solicitudes"
      }
    }
  })

  setEliphsis = (data) => data.map(entry => ({
    ...entry,
    name: <Paragraph ellipsis={{ rows: 1, expandable: true }}>entry.name</Paragraph>
  }))

  render () {
    const config = this.getConfig()
    const {
      workRequestsById,
      allWorkRequests
    } = this.props
    const allWorkRequestsFiltered = allWorkRequests.filter(id => workRequestsById[id] !== undefined)
    const data = allWorkRequestsFiltered.map(wr => {
      return formatWRGanttEntry(workRequestsById[wr], this.props.entities)
    })
    return (
      <TimeLine 
        data={data} 
        config={config}
        itemheight={50}
        nonEditableName={true}
      />
    )
  }
}

export default Component
