export const userConstants = {
  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  FETCH_USER_LIST_REQUEST: 'FETCH_USER_LIST_REQUEST',
  FETCH_USER_LIST_SUCCESS: 'FETCH_USER_LIST_SUCCESS',
  FETCH_USER_LIST_ERROR: 'FETCH_USER_LIST_ERROR',
  TOGGLE_DISABLE_USER_REQUEST: 'TOGGLE_DISABLE_USER_REQUEST',
  TOGGLE_DISABLE_USER_SUCCESS: 'TOGGLE_DISABLE_USER_SUCCESS',
  TOGGLE_DISABLE_USER_ERROR: 'TOGGLE_DISABLE_USER_ERROR',
  CHANGE_PROFILE: 'CHANGE_PROFILE',
  OPEN_ADMIN_USER_MODAL: 'OPEN_ADMIN_USER_MODAL',
  CLOSE_ADMIN_USER_MODAL: 'CLOSE_ADMIN_USER_MODAL'
}
