import React from 'react'
import moment from 'moment'
import { Modal, Form, DatePicker, Typography, Row } from 'antd'
import FormItem from '../../components/FormItem'
import locale from 'antd/es/date-picker/locale/es_ES'
import { defaultTimeFormat as timeFormat } from '../../utils/workRequestUtils'
import { showNotification } from '../../utils/notification'

const { Paragraph } = Typography

class Component extends React.Component {

  basicRule = (required, message) => ({
    rules: [{
      required, 
      message
    }]
  })
  
  disabledDate = (current) => {
    return current < moment().endOf('day')
  }

  handleSubmit = () => { 
    const { movePrograms, handleCancel, form } = this.props
    form.validateFields((err, values) => {
      if (!err) {
        movePrograms(values.limit_date)
        handleCancel()
      } else {
        showNotification(
          'Error de Validación',
          'Debe ingresar una fecha límite para el traspaso de solicitudes.',
          'error'
        )
      }
    })
    
  }

  render () {
    const { handleCancel, form, disabled, width, isVisible } = this.props
    return (
      <Modal
        title='Confirmación de acción'
        visible={isVisible}
        okText='Si, estoy seguro'
        cancelText='Cancelar'
        onCancel={handleCancel}
        onOk={() => {
          this.handleSubmit()
        }}
      >
        <>
          <Paragraph>
            El programa preliminar entrará en vigencia y el programa vigente pasará a históricos.
            ¿Estás seguro de realizar la acción?
          </Paragraph>
          <Form>
            <Row>
              <FormItem
                id='period-form-item'
                span={24}
                label='Fecha límite de inicio de solicitudes:' 
                decorator={form.getFieldDecorator}
                formId='limit_date'
                extra='El nuevo programa vigente contendrá las solicitudes del programa preliminar,
                las solicitudes del programa vigente que estén en curso, y aquellas que no hayan
                iniciado y su fecha de inicio sea menor al límite especificado.'
                args={{
                  ...this.basicRule(true, 'Seleccione Fechas Límite'),
                }}
              >
                <DatePicker
                  disabled={disabled}
                  style={{ width: width || '100%' }}
                  locale={locale}
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: [moment('00:00:00', 'HH:mm:ss')],
                    hideDisabledOptions: true
                  }}
                  format={timeFormat}
                  disabledDate={(current) => this.disabledDate(current)}
                />
              </FormItem>
            </Row>
          </Form>
          <br />
        </>
      </Modal>
    )
  } 
}

const wrappedComponent = Form.create()(Component)

export default wrappedComponent
