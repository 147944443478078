import React from 'react'
import {Input} from 'antd'

const CorrelativeFilter = (props) => {
  const { value, onChange, onPressEnter, width } = props
  return (
    <Input
      value={value}
      onPressEnter={onPressEnter}
      onChange={(value) => onChange(value)} 
      style={{width: width || '100%'}}
      placeholder='2020000001'
    />
  )
}

export default CorrelativeFilter
