import React from 'react'
import Joyride from 'react-joyride'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Col, Row, Tabs, Button, Spin, Radio, Icon, Tooltip, Popconfirm, Popover } from 'antd'
import WorkRequestList from '../../components/WorkRequestList'
import ProgramGantt from '../ProgramGantt'
import AddWorkRequest from '../AddRequest'
import AddRequestMassive from '../AddRequestMassive'
import FilterRequests from '../FilterRequests'
import {
  toggleCalendarView,
  openAddRequestModal,
  closeAddRequestModal,
  openAprovalRequestModal,
  closeAprovalRequestModal,
  openDeleteRequestModal,
  closeDeleteRequestModal,
  openMassiveRequestModal,
  closeMassiveRequestModal
} from '../../store/actions/pmpm'
import {
  movePrograms,
  selectProgram,
  getPrograms
} from '../../store/actions/programs'
import {
  getWorkRequests,
  clearWorkRequestSelection
} from '../../store/actions/workRequests'
import _ from 'lodash'
import './ProgramTabs.css'
import {
  fetchCentrals,
  fetchLines,
  fetchSubstations,
  fetchSubstationElements,
  fetchSections,
  //5941
  /*
  fetchBarras,
  fetchPanos,
  */
  //5941
  //fetchElements,
  fetchSub_Barras,
  fetchSub_Panos,
  ///////////////////////////////////////////////////////////////////
  fetchSub_transformadores2D,
  fetchSub_transformadores3D,
  fetchSub_bancoscondensadores,
  fetchSub_sistcomper,
  fetchSub_compensadoresactivos,
  fetchSub_condensadoresserie,
  fetchSub_condensadoressincronos,
  fetchSub_reactores,
  fetchSub_medfacturacion,
  ///////////////////////////////////////////////////////////////////
  fetchUnits,
  fetchRegions,
  fetchCompanies,
  fetchProyectos,
  fetchInfotecnicaCompanies,
  fetchStatusOptions,
  fetchRequestModification,
  fetchFileTemplates
} from '../../store/actions/entities'
import DeleteRequestModal from '../DeleteRequestModal'
import AprovalModal from '../AprovalModal'
import ConfirmToCurrent from '../../components/ConfirmToCurrent'

const { TabPane } = Tabs

// Tour steps: each step could have a userTypes list with the user types
// the step should be displayed for.
const tabTourSteps = {
  // Tour for current program.
  current: [
    {
      title: "Programas",
      target: "#program-tabs .ant-tabs-nav",
      content: "Aquí puedes seleccionar el programa que quieres ver.",
      disableBeacon: true
    },
    {
      title: "Lista de solicitudes",
      target: "#program-tabs .ant-tabs-content",
      content: "Las solicitudes se mostrarán en este área.",
      disableBeacon: true
    },
    {
      title: "Agregar solicitudes",
      target: "#add-request-button",
      content: "Puedes ingresar solicitudes individualmente.",
      disableBeacon: true
    },
    {
      title: "Agregar solicitudes",
      target: "#add-batch-request-button",
      content: "Realiza carga masiva de solicitudes con un archivo CSV.",
      disableBeacon: true
    },
    {
      title: "Cambiar de vista",
      target: "#request-view-selector",
      content: "Selecciona entre la vista de lista o carta Gantt.",
      disableBeacon: true
    },
    {
      title: "Filtrar solicitudes",
      target: "#request-filter",
      content: "Aquí puedes filtrar las solicitudes del programa seleccionado.",
      disableBeacon: true
    },
    {
      title: "Limpiar filtros",
      target: "#clean-filters-button",
      content: "Reinicia la lista de solicitudes que se muestran.",
      disableBeacon: true
    },
    {
      title: "Informe Plexos - PLP",
      target: "#download-plexos-plp-button",
      content: "Puedes descargar el informe Plexos - PLP de las solicitudes.",
      disableBeacon: true
    },
    {
      title: "Reportes",
      target: "#download-report-button",
      content: "Descarga el reporte de las solicitudes que se muestran.",
      disableBeacon: true
    },
  ],
  // Tour for preliminary program.
  preliminary: [
    {
      userTypes: ["superadmin"],
      title: "Pasar a vigente",
      target: "#move-to-current-button",
      content: "Los usuarios del coordinador pueden pasar el programa preeliminar a vigente.",
      disableBeacon: true
    },
    {
      title: "Conoce más",
      target: "#program-tabs .ant-tabs-nav div:nth-child(2)",
      content: `Realiza un tour por la pestaña de mantenimiento vigente para conocer más
                funcionalidades.`,
      disableBeacon: true
    },
  ],
  // Tour for historic program.
  historic: [
    {
      title: "Selecionar programa",
      target: "#program-id-filter-form-item",
      content: `Para ver algún programa histórico debes filtrar, seleccionado el correlativo
                correspondiente.`,
      disableBeacon: true
    },
    {
      title: "Conoce más",
      target: "#program-tabs .ant-tabs-nav div:nth-child(2)",
      content: `Realiza un tour por la pestaña de mantenimiento vigente para conocer más
                funcionalidades.`,
      disableBeacon: true
    },
  ]
}

class ProgramTabs extends React.Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 0
    this.state = {
      moveProgramModalVisible: false,
      newRequestVisible: false,
      isTourRunning: false,
    }
  }

  componentDidMount () {
    this.props.getPrograms()
    this.props.fetchCompanies()
    this.props.fetchProyectos()
    this.props.fetchInfotecnicaCompanies()
    this.props.fetchCentrals()
    this.props.fetchLines()
    this.props.fetchSubstations()
    this.props.fetchRegions()
    this.props.fetchSections()
    //5941
    /*
    this.props.fetchBarras()
    this.props.fetchPanos()
    */
    //5941
    //this.props.fetchElements()
    this.props.fetchSub_Barras()
    this.props.fetchSub_Panos()
    ///////////////////////////////////////////////////////////////////////////////////
    this.props.fetchSub_transformadores2D()
    this.props.fetchSub_transformadores3D()
    this.props.fetchSub_bancoscondensadores()
    this.props.fetchSub_sistcomper()
    this.props.fetchSub_compensadoresactivos()
    this.props.fetchSub_condensadoresserie()
    this.props.fetchSub_condensadoressincronos()
    this.props.fetchSub_reactores()
    this.props.fetchSub_medfacturacion()

    ///////////////////////////////////////////////////////////////////////////////////
    
    this.props.fetchSubstationElements()
    this.props.fetchUnits()
    this.props.fetchStatusOptions()
    this.props.fetchRequestModification()
    this.props.fetchFileTemplates()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      currentProgram,
      getWorkRequests
    } = this.props
    const fetchCurrentProgramWorkRequests = !_.isEmpty(currentProgram) && _.isEmpty(prevProps.currentProgram)
    if (fetchCurrentProgramWorkRequests) {
      getWorkRequests(currentProgram.id)
    }
  }

  openNewRequestModal = () => {
    this.props.openAddRequestModal()
  }

  openMassiveRequestModal = () => {
    this.props.openMassiveRequestModal()
  }

  handleAddCancel = () => {
    this.props.closeAddRequestModal()
  }

  handleAddMassiveCancel = () => {
    this.props.closeMassiveRequestModal()
  }
 
  handleAprovalCancel = () => {
    this.props.closeAprovalRequestModal()
  }

  handleDeleteCancel = () => {
    this.props.closeDeleteRequestModal()
  }

  onChange = activeKey => {
    const {
      currentProgram,
      preliminaryProgram,
      getWorkRequests,
      selectProgram
    } = this.props
    selectProgram(activeKey)
    if (activeKey === 'current') {
      getWorkRequests(currentProgram.id)
    } else if (activeKey === 'preliminary') {
      getWorkRequests(preliminaryProgram.id)
    }
  }

  getTourSteps() {
    const { user: {userType}, selectedProgram } = this.props
    return tabTourSteps[selectedProgram].filter(step => {
      // Get steps that don't specify a userTypes list or that have the current
      // user type in that list.
      return step.userTypes === undefined || step.userTypes.indexOf(userType) !== -1
    })
  }

  render() {
    const {
      calendarView,
      calendarViewKey,
      workRequestsById,
      allWorkRequests,
      workRequestsIdOrder,
      fetchingPrograms,
      toggleCalendarView,
      fetchingWorkRequests,
      isFiltering,
      addRequestModalOpen,
      addRequestMassiveModalOpen,
      aprovalRequestModalOpen,
      deleteRequestModalOpen,
      selectionCount,
      selectedById,
      clearWorkRequestSelection,
      openAprovalRequestModal,
      user,
      user: { userType },
      selectedProgram,
      currentHistoric,
      movePrograms,
      movingPrograms,
      programs,
      currentRequest,
      entities,
    } = this.props
    const moveProgramButton = selectedProgram === 'preliminary' && userType === 'superadmin' ? (
      <Tooltip
        placement="bottom"
        title={`Pasar el programa preliminar a vigente.`}>
        <Button
          id="move-to-current-button"
          onClick={() => this.setState({moveProgramModalVisible: true})}
          type="primary">
          Pasar a vigente
        </Button>
      </Tooltip>
    ) : null

    const ButtonWithPopOver = ({children, ...props}) => {
      const helpText = 'El administrador ha desactivado la carga de solicitudes.'
      return props.disabled ?
        <Popover
          placement="bottom"
          content={helpText}>
          <Button {...props}>
            {children}
          </Button>
        </Popover> :
        <Button {...props}>
          {children}
        </Button>
    }
    const loadDisabled = !programs[selectedProgram].edition_enabled
    const loadButtons = selectedProgram !== 'historic' && ['superadmin', 'coordinado'].includes(userType) ? (
      <>
        <ButtonWithPopOver
          disabled={loadDisabled}
          id="add-batch-request-button"
          onClick={this.openMassiveRequestModal} 
          type="secondary">
          <Icon type="file-add" />Ingreso de Solicitudes Masivo
        </ButtonWithPopOver>
        <ButtonWithPopOver
          disabled={loadDisabled}
          id="add-request-button"
          onClick={this.openNewRequestModal}
          type="primary">
          <Icon type="plus" /> Ingreso de Solicitud
        </ButtonWithPopOver>
      </>
    ) : null
    const tutorialButton = (
      <Popconfirm
        trigger="hover"
        placement="bottomLeft"
        icon={<Icon type="question-circle" theme="filled"/>}
        title={"¿Deseas comenzar un tour de ayuda en esta pestaña?"}
        onConfirm={() => this.setState({isTourRunning: true})}
        okText="Sí"
        cancelText="No">
      <Button
        className={"help-button"}
        type="secondary">
        <Icon type="question-circle"/>
      </Button>
    </Popconfirm>

    )
    const operations = [moveProgramButton, loadButtons, tutorialButton]

    const batchAprovalAction = isAproval => {
      let ids = Object.keys(selectedById).filter((id) => selectedById[id] === true)
      ids = ids.map(idStr => Number.parseInt(idStr))
      openAprovalRequestModal(isAproval, ids)
    }

    const tourSteps = this.getTourSteps()
    const editWorkRequest = currentRequest !== undefined

    return (
      <Col
        id="program-tabs"
        xs={24} sm={24} md={24}>
        <Joyride
          run={this.state.isTourRunning}
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          steps={tourSteps}
          locale={{
            back: "Atrás",
            close: "Cerrar",
            last: "Finalizar",
            next: "Siguiente",
            skip: "Terminar tour"
          }}
          styles={{
            options: {
              primaryColor: "#3ca7ad"
            },
          }}
          callback={(tourState) => {
            if(tourState.action === "reset")
              this.setState({isTourRunning: false})
          }}
        />
        <Row>
          <Spin spinning={movingPrograms || fetchingPrograms}>
          <Tabs
            onChange={this.onChange}
            activeKey={selectedProgram}
            animated={false}
            tabBarExtraContent={operations}
            size='large'
            renderTabBar={(props, DefaultTabBar) => {
              return (
                <React.Fragment>
                  <DefaultTabBar {...props}/>
                  <div id="filter-container">
                    <FilterRequests key='filters' environment={programs}/>
                    <Row className="toggle-view" key='toggle-view-row'>
                      <Radio.Group
                        id="request-view-selector"
                        onChange={toggleCalendarView} 
                        value={calendarViewKey}
                      >
                        <Radio.Button value="list"><Icon type="unordered-list"/> Listado</Radio.Button>
                        <Radio.Button value="calendar"><Icon type="calendar" /> Vista Gantt</Radio.Button>
                      </Radio.Group>
                    </Row>
                  </div>
                  
                </React.Fragment>
              )
            }}
          >
            <TabPane tab='Elaboración Mantenimiento Preliminar' key='preliminary' closable={false}>
              {
                calendarView
                  ? <ProgramGantt 
                    workRequestsById={workRequestsById}
                    allWorkRequests={allWorkRequests}
                    entities={entities} />
                  : <WorkRequestList
                    loading={fetchingWorkRequests || isFiltering}
                    workRequestsById={workRequestsById} 
                    workRequestsIdOrder={workRequestsIdOrder} 
                    selectionCount={selectionCount}
                    batchAprovalAction={batchAprovalAction}
                    clearSelectionAction={clearWorkRequestSelection}
                    user={user}
                    currentHistoric={currentHistoric}
                    selectedProgramTab={selectedProgram}
                    />
              }
            </TabPane>
            <TabPane tab='Programa de Mantenimiento Vigente' key='current' closable={false}>
              {
                calendarView
                  ? <ProgramGantt 
                    workRequestsById={workRequestsById}
                    allWorkRequests={allWorkRequests} 
                    entities={entities} />
                  : <WorkRequestList
                    loading={fetchingWorkRequests || isFiltering}
                    workRequestsById={workRequestsById} 
                    workRequestsIdOrder={workRequestsIdOrder}
                    selectionCount={selectionCount}
                    batchAprovalAction={batchAprovalAction}
                    clearSelectionAction={clearWorkRequestSelection}
                    user={user}
                    currentHistoric={currentHistoric}
                    selectedProgramTab={selectedProgram}
                    />
              }
            </TabPane>
            <TabPane tab='Programas Históricos' key='historic' closable={false}>
              {
                calendarView
                  ? <ProgramGantt 
                    workRequestsById={workRequestsById}
                    allWorkRequests={allWorkRequests} 
                    entities={entities} />
                  : <WorkRequestList
                    loading={isFiltering}
                    workRequestsById={workRequestsById} 
                    workRequestsIdOrder={workRequestsIdOrder}
                    selectionCount={selectionCount}
                    batchAprovalAction={batchAprovalAction}
                    clearSelectionAction={clearWorkRequestSelection}
                    user={user}
                    currentHistoric={currentHistoric}
                    selectedProgramTab={selectedProgram}
                    />
              }
            </TabPane>
          </Tabs>
          </Spin>

          {/* We use two AddWorkRequest components, one for edition and another for creation. This way we avoid problems with the forms initial values. */}
          <AddWorkRequest isVisible={editWorkRequest && addRequestModalOpen} handleCancel={this.handleAddCancel} activeKeyProgram={programs[selectedProgram]}/>
          <AddWorkRequest isVisible={!editWorkRequest && addRequestModalOpen} handleCancel={this.handleAddCancel} activeKeyProgram={programs[selectedProgram]}/>

          <AddRequestMassive isVisible={addRequestMassiveModalOpen} handleCancel={this.handleAddMassiveCancel} activeKeyProgram={programs[selectedProgram]}/>
          <AprovalModal isVisible={aprovalRequestModalOpen} handleCancel={this.handleAprovalCancel} />
          <DeleteRequestModal isVisible={deleteRequestModalOpen} handleCancel={this.handleDeleteCancel} />
        </Row>
        
        { selectedProgram === 'preliminary'
          ? <ConfirmToCurrent 
              isVisible={this.state.moveProgramModalVisible}
              handleCancel={() => this.setState({
                moveProgramModalVisible: false
              })}
              movePrograms={movePrograms}
            />
          : null
        }
        
      </Col>
    )
  }
}

function countSelectedItems(selectedById){
  const reducer = (count, isSelected) => 
    isSelected === true? 1 + count : count
  return Object.values(selectedById).reduce(reducer, 0)
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    toggleCalendarView,
    selectProgram,
    getPrograms,
    movePrograms,
    getWorkRequests,
    fetchCentrals,
    fetchLines,
    fetchSubstations,
    fetchSubstationElements,
    fetchSections,
    //5941
    /*
    fetchBarras,
    fetchPanos,
    */
    //5941  
    //fetchElements,
    fetchSub_Barras,
    fetchSub_Panos, 
    ////////////////////////////////////////////////////////////////////
    fetchSub_transformadores2D,
    fetchSub_transformadores3D,
    fetchSub_bancoscondensadores,
    fetchSub_sistcomper,
    fetchSub_compensadoresactivos,
    fetchSub_condensadoresserie,
    fetchSub_condensadoressincronos,
    fetchSub_reactores,
    fetchSub_medfacturacion,
    ////////////////////////////////////////////////////////////////////
 
    fetchUnits,
    fetchRegions,
    fetchCompanies,
    fetchProyectos,
    fetchInfotecnicaCompanies,
    fetchStatusOptions,
    fetchRequestModification,
    openAddRequestModal,
    closeAddRequestModal,
    openDeleteRequestModal,
    closeDeleteRequestModal,
    openAprovalRequestModal,
    closeAprovalRequestModal,
    openMassiveRequestModal,
    closeMassiveRequestModal,
    clearWorkRequestSelection,
    fetchFileTemplates
  }, dispatch)

const mapStateToProps = (state) => ({
  calendarView: state.pmpm.calendarView,
  calendarViewKey: state.pmpm.calendarView ? 'calendar' : 'list',
  fetchingWorkRequests: state.pmpm.fetchingWorkRequests,
  isFiltering: state.filter.isFiltering,
  addRequestModalOpen: state.pmpm.addRequestModalOpen,
  addRequestMassiveModalOpen: state.pmpm.addRequestMassiveModalOpen,
  workRequestsById: state.entities.workRequests.byId,
  allWorkRequests: state.entities.workRequests.allIds,
  workRequestsIdOrder: state.entities.workRequests.idOrder,
  fetchingPrograms: state.pmpm.fetchingPrograms,
  currentProgram: state.entities.programs.current,
  preliminaryProgram: state.entities.programs.preliminary,
  historicPrograms: state.entities.programs.historic,
  deleteRequestModalOpen: state.pmpm.deleteRequestModalOpen,
  aprovalRequestModalOpen: state.pmpm.aprovalRequestModalOpen,
  selectionCount: countSelectedItems(state.entities.workRequests.selectedById),
  selectedById: state.entities.workRequests.selectedById,
  user: state.user,
  programs: state.entities.programs,
  movingPrograms: state.entities.programs.movingPrograms,
  currentHistoric: state.entities.programs.currentHistoric,
  selectedProgram: state.entities.programs.selectedProgram,
  currentRequest: state.pmpm.currentRequest,
  entities: {
    central: state.entities.central,
    unit: state.entities.unit,
    line: state.entities.line,
    section: state.entities.section,
    substation: state.entities.substation,
    sub_barra: state.entities.sub_barra,
    sub_pano: state.entities.sub_pano,
    sub_transformadores2D: state.entities.sub_transformadores2D,
    sub_transformadores3D: state.entities.sub_transformadores3D,
    sub_bancoscondensadores: state.entities.sub_bancoscondensadores,
    sub_sistcomper: state.entities.sub_sistcomper,
    sub_compensadoresactivos: state.entities.sub_compensadoresactivos,
    sub_condensadoresserie: state.entities.sub_condensadoresserie,
    sub_condensadoressincronos: state.entitiessub_condensadoressincronos,
    sub_reactores: state.entities.sub_reactores,
    sub_medfacturacion: state.entities.sub_medfacturacion
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProgramTabs)
