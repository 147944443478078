/* $global localStorage */

import axios from 'axios'
import { store } from '../../index'
import { loginUserSuccess } from '../actions/auth'
import { parseJSON } from '../../utils/misc'
import { showNotification } from '../../utils/notification'

export default () => {
  const config = {
    baseURL: process.env.REACT_APP_API_URL,
    crossDomain: true
  }
  const instance = axios.create(config)
  instance.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    console.log('Ha ocurrido un error', error)
    console.log(error.response)
    if (error.response && error.response.status === 401) {
      if ( error.response.data === 'error_user_empresa' ){
        // alert('El usuario Coordinado no tiene empresas para acceder a la aplicación.')
        // window.location = '/login'
        return Promise.reject(error.response.data) 
      }

      if ( error.response.data === 'error_user_inexistente' ){
        // alert('El usuario no está registrado para acceder a la aplicación.')
        // window.location = '/login'
        return Promise.reject(error.response.data)
      }

      const refreshToken = localStorage.getItem('refresh_token')
      if (!refreshToken) {
        window.location = '/login'
        return Promise.reject(error)
      }
      return axios.post('/auth/token-refresh/', {
        refreshToken: refreshToken
      }, config)
        .then(parseJSON)
        .then(data => {
          store.dispatch(loginUserSuccess(data))
          const newConfig = {
            ...error.config,
            headers: {
              Accept: 'application/json, text/plain, */*',
              Authorization: `Bearer ${data.id_token}`
            }
          }
          return axios(newConfig)
        })
        .catch(refreshError => {
          console.log('Incapas de Refrescar', refreshError)
          //window.location = '/login'
          //SSO
          return Promise.reject(refreshError)
        })
    }
    else if (error.response && error.response.status === 403) {
      showNotification(
        'Acción Denegada',
        'La acción que intentó realizar fue rechazada debido a que no tiene los permisos necesarios para realizar esta acción',
        'error'
      )
      console.log('Sin Permisos: 403')
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  })
  return instance
}
