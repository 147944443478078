import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Layout, Button, Menu, Icon, Col, Divider, Tag } from 'antd'
import { changeProfile } from '../../store/actions/user'
import LogoImg from '../../assets/img/logo.svg'
import './Navbar.css'
import { goToCognitoLogin } from '../../utils/misc'

const { SubMenu } = Menu
const { Header, Content, Footer } = Layout

class Navbar extends React.Component {
  goToCognitoLogout = () => {
    const {
      REACT_APP_COGNITO_AUTH_HOST: cognitoAuthHost,
      REACT_APP_COGNITO_CLIENT_ID: cognitoClientId
    } = process.env
    const domain = window.location.origin
    const cognitoLogoutLocation = `${cognitoAuthHost}logout?response_type=code&client_id=${cognitoClientId}&logout_uri=${domain}/callback/logout`
    window.location.replace(cognitoLogoutLocation)
  }

  goToAdmin = () => {
    window.location.replace('/admin')
  }

  selectProfile = (profile) => {
    this.props.changeProfile(Number(profile))
  }

  render () {
    const {
      username,
      isAuthenticated,
      profiles,
      currentProfile,
      location : {
        pathname,
      },
      userType
    } = this.props
    return (
      <Layout>
        <Header className='header'>
          <Col xs={20} sm={20} md={3} lg={3}>
            <Link to="/">
            <img src={LogoImg} className='logoCoordinador'
              alt='Coordinador Eléctrico Nacional' />
            </Link>
          </Col>
          <Col xs={20} sm={20} md={16} lg={16}>
            <h1>Programa Mantenimiento Preventivo Mayor</h1>
          </Col>
          <Col xs={20} sm={20} md={5} lg={5}>
            { isAuthenticated ? (
              <Menu
                selectedKeys={profiles.length > 0 ? [currentProfile] : []}
                className="user-menu"
                mode="horizontal">
                <SubMenu 
                  title={
                    <span>
                      <React.Fragment>
                        <Icon type='user' />
                        {username}
                      </React.Fragment>
                    </span>
                  }>
                  {
                    profiles.length > 0
                      ? <Menu.ItemGroup title="Perfiles">
                        {
                          profiles.map((p, i) => {
                            const active = currentProfile && currentProfile === p.id
                            const activeTag = active ? <Tag color="#87d068">Activo</Tag> : null
                            return (<Menu.Item key={p.id}
                              onClick={(event) => {
                                  this.selectProfile(event.key)
                                }
                              }
                              disabled={active}>
                              {p.companyName} - {p.companyRut} {activeTag}
                            </Menu.Item>)
                          })
                        }
                      </Menu.ItemGroup>
                      : null
                  }
                  {
                    profiles.length > 0 && <Divider />
                  }
                  {
                    userType !== 'superadmin' ? null 
                    : <Menu.Item
                        onClick={this.goToAdmin}
                        key='to_admin'>
                          Ir a Administrador
                      </Menu.Item>
                  }
                  {
                    username.length > 0 && (
                      <Menu.Item
                        onClick={this.goToCognitoLogout}
                        key='logout'>
                          Cerrar Sesión
                      </Menu.Item>
                    )
                  }
                </SubMenu>
              </Menu>
            ) : (
              // We show another login button for /login
              pathname === '/login' ? null : (
                <Button 
                  type="primary"
                  onClick={goToCognitoLogin}
                >
                  Ingresa aquí
                </Button>
              )
            )}
          </Col>
        </Header>
        <Content>
          {this.props.children}
        </Content>
        <Footer>
          <span>Coordinador Eléctrico Nacional ©2019</span>
          <span>Dirección. Teatinos 280. Piso 11. Santiago Centro.</span>
        </Footer>
      </Layout>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    changeProfile
  }, dispatch)
}

function mapStateToProps (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    username: state.user.username,
    profiles: state.user.profiles,
    currentProfile: state.user.currentProfile,
    userType: state.user.userType
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
