
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { logoutAndRedirect } from '../../store/actions/auth'

class CognitoCallback extends React.Component {
  componentDidMount () {
    this.props.logoutAndRedirect()
  }

  render () {
    return (
      <Row className='test-class-row'>
        <Col xs={24} sm={24} md={24} className='test-class-col'>
          
        </Col>
      </Row>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    logoutAndRedirect
  }, dispatch)

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, mapDispatchToProps)(CognitoCallback)
