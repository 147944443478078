import React from 'react'
import moment from 'moment'
import { Icon, notification } from 'antd'

export const successIcon = <Icon type='check-circle' style={{ color: '#52c41a' }} />
export const failureIcon = <Icon type='bug' style={{ color: '#eb2f96' }} />

export const statusChangeSuccess = () => {
  notification.open({
    message: 'Operación Completada',
    description:
      'Se cambio el estado de la solicitud correctamente.',
    icon: successIcon
  })
}

export const statusChangeFailure = () => {
  notification.open({
    message: 'Se ha producido un error',
    description:
      'El estado de la solicitud no pudo ser modificado.',
    icon: failureIcon
  })
}

export const inverseRisk = (risk) => {
  switch (risk.toLowerCase()) {
    case 'sin riesgo':
      return 'none'
    case 'ninguno':
      return 'none'
    case 'riesgo bajo':
      return 'low'
    case 'bajo':
      return 'low'
    case 'riesgo medio':
      return 'mid'
    case 'medio':
      return 'mid'
    case 'riesgo alto':
      return 'high'
    case 'alto':
      return 'high'
    default:
      return 'none'
  }
}

export const hasInitialValue = (field, workRequest, isEdit) => {
  if (!isEdit) {
    return {} // Won't add a initialValue property to Antd's Form Item
  }
  let fieldValue = null
  switch (field) {
    case 'company_id':
      fieldValue = workRequest.company.id
      break
    case 'installation_type':
      fieldValue = workRequest.installation_type
      break
   
    case 'installation_subtype':
      fieldValue = workRequest.installation_subtype
      break      
   
    case 'installation':
      fieldValue = workRequest.installation
      break

    case 'sub_installation':
      fieldValue = workRequest.sub_installation
      break
    case 'consumption_type':
      fieldValue = workRequest.consumption_type
      break
    case 'work_description':
      fieldValue = workRequest.work_description
      break
    case 'risk':
      fieldValue = inverseRisk(workRequest.risk)
      break
    case 'period':
      fieldValue = [
        moment(workRequest.programmed_start_date),
        moment(workRequest.programmed_end_date)
      ]
      break
    case 'postponable':
      fieldValue = workRequest.postponable
      break
    case 'alternative_period':
      if (!workRequest.alternative_start_date || !workRequest.alternative_end_date) {
        return {}
      }
      fieldValue = [
        moment(workRequest.alternative_start_date),
        moment(workRequest.alternative_end_date)
      ]
      break
    case 'affected_dealers':
      fieldValue = workRequest.affected_dealers
      break
    case 'optional_comments':
      console.log('pasando 11')
      fieldValue = workRequest.additional_comments
      break
    //5942
    case 'nup':
      fieldValue = workRequest.nup
      break    
    default:
      return {}
  }
  return {
    initialValue: fieldValue
  }
}

export const hasInitialValueMassive = (field, item, instList = [], sinstList = [], tsubList = []) => {
  console.log('------------------------------------------------------------------------------------------------------------>',item)
  let fieldValue = null
  const timeFormat = ['DD-MM-YY', 'DD-MM-YYYY', 'DD-MM-YY HH:mm', 'DD-MM-YYYY HH:mm']
  switch (field) {
    case 'tipo_instalacion':
      fieldValue = ['central', 'linea','subestacion'].includes(item.tipo_instalacion.toLowerCase().replace('í', 'i')) ? (item.tipo_instalacion.toLowerCase() === 'central' ? 'central' : item.tipo_instalacion.toLowerCase() === 'linea' ? 'line' : 'substation') : null
      break

    case 'instalacion':
      fieldValue = instList.includes(parseInt(item.instalacion)) ? parseInt(item.instalacion) : null
      break

    case 'sub_instalacion':
      fieldValue = item.sub_instalacion.split(',').map(item => parseInt(item)).filter(item => sinstList.includes(item))
      break

    case 'tipo_subestacion':
      console.log('tsubList->',tsubList)
      fieldValue = item.tipo_subestacion//.split(',').map(item => parseInt(item)).filter(item => tsubList.includes(item))
      break

    case 'descripcion_trabajo':
      fieldValue = item.descripcion_trabajo
      break
    case 'riesgo_trabajos':
      fieldValue = inverseRisk(item.riesgo_trabajos)
      break
    case 'postergable':
      fieldValue = item.postergable.toLowerCase().replace('í', 'i') === 'si'
      break
    case 'fecha_inicio':
      fieldValue = [
        moment(item.fecha_inicio, timeFormat),
        moment(item.fecha_termino, timeFormat)
      ]
      break
    case 'fecha_inicio_alternativa':
      if (!item.fecha_inicio_alternativa || !item.fecha_termino_alternativa) {
        return {}
      }
      fieldValue = [
        moment(item.fecha_inicio_alternativa, timeFormat),
        moment(item.fecha_termino_alternativa, timeFormat)
      ]
      break

      case 'comentarios_adicionales':
        fieldValue = item.comentarios_adicionales
        break

        case 'nup':
          fieldValue = item.nup
          break

      default:
        return {}
  }
  return {
    initialValue: fieldValue
  }
}

export const central = {
  mainId: 'central',
  mainText: 'Central',
  subId: 'unit',
  subText: 'Unidad',
  connectionId: 'central'
}

export const line = {
  mainId: 'line',
  mainText: 'Línea',
  subId: 'section',
  subText: 'Sección',
  connectionId: 'linea'
}

/*
export const substation = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_barra',
  subText: 'Elementos',
  connectionId: 'subestacion'
}
*/

export const substation_sub_barra = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_barra',
  subText: 'Barra',
  connectionId: 'subestacion'
}

export const substation_sub_pano = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_pano',
  subText: 'Paño',
  connectionId: 'subestacion'
}

export const substation_sub_transformadores2D = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_transformadores2D',
  subText: 'Transformadores 2D',
  connectionId: 'subestacion'
}

export const substation_sub_transformadores3D = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_transformadores3D',
  subText: 'Transformadores 3D',
  connectionId: 'subestacion'
}

export const substation_sub_bancoscondensadores = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_bancoscondensadores',
  subText: 'Bancos Condensadores',
  connectionId: 'subestacion'
}

export const substation_sub_sistcomper = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_sistcomper',
  subText: 'Sistemas de Compensación Estática de Reactivos',
  connectionId: 'subestacion'
}

export const substation_sub_compensadoresactivos = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_compensadoresactivos',
  subText: 'Compensadores Activos',
  connectionId: 'subestacion'
}

export const substation_sub_condensadoresserie = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_condensadoresserie',
  subText: 'Condensadores Serie',
  connectionId: 'subestacion'
}

export const substation_sub_condensadoressincronos = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_condensadoressincronos',
  subText: 'Condensadores Síncronos',
  connectionId: 'subestacion'
}

export const substation_sub_reactores = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_reactores',
  subText: 'Reactores',
  connectionId: 'subestacion'
}

export const substation_sub_medfacturacion = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_medfacturacion',
  subText: 'Medidores de facturación',
  connectionId: 'subestacion'
}

/*
export const substation_sub_scada = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_scada',
  subText: 'Scada',
  connectionId: 'subestacion'
}

export const substation_sub_grupoemergencia = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_grupoemergencia',
  subText: 'Grupo de Emergencia',
  connectionId: 'subestacion'
}

export const substation_sub_bateria = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_bateria',
  subText: 'Batería',
  connectionId: 'subestacion'
}

export const substation_sub_tendidocable = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_tendidocable',
  subText: 'Tendido de Cable',
  connectionId: 'subestacion'
}

export const substation_sub_cargadores = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_cargadores',
  subText: 'Cargadores',
  connectionId: 'subestacion'
}


export const substation_sub_telecomunicacion = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_telecomunicacion',
  subText: 'Telecomunicación',
  connectionId: 'subestacion'
}
*/

export const disabledDate = (current, isSearch) => {
  if (isSearch) {
    return false
  }
  return current && current < moment().endOf('day')
}

export const defaultTimeFormat = 'DD-MM-YYYY HH:mm'
