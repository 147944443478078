import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'

import createRootReducer from './reducers'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  const historyMiddleware = routerMiddleware(history)
  let middleware = [historyMiddleware, thunkMiddleware]
  let enhancers = null

  if (process.env.NODE_ENV !== 'production') {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    middleware = [...middleware]
    enhancers = composeEnhancer(applyMiddleware(...middleware))
  } else {
    enhancers = applyMiddleware(...middleware)
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__._renderers = {}
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = function () { }
    }
  }

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    enhancers
  )

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createRootReducer(history))
    })
  }

  return store
}
