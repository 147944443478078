import React from 'react'

import Joyride from 'react-joyride'
import moment from 'moment'
import {
  Row,
  Form,
  Modal,
  Divider,
  Button,
  Popconfirm,
  Icon,
  Spin
} from 'antd'
import { connect } from 'react-redux'
import FormItem from '../../components/FormItem'
import { showNotification } from '../../utils/notification'
import { newWorkRequest, editWorkRequest } from '../../store/actions/workRequests'
import Upload from '../../components/Upload'
import TextInput from '../../components/AddRequestItem/TextInput'
import RiskSelector from '../../components/AddRequestItem/RiskSelector'
import TypeSelector from '../../components/AddRequestItem/TypeSelector'
import CompanySelector from '../../components/AddRequestItem/CompanySelector'
import PostponableInput from '../../components/AddRequestItem/PostponableInput'
import DateRangeSelector from '../../components/AddRequestItem/DateRangeSelector'
import InstallationSelector from '../../components/AddRequestItem/InstallationSelector'
import ConsumptionTypeSelector from '../../components/AddRequestItem/ConsumptionTypeSelector'
import NupSelector from '../../components/AddRequestItem/NupSelector'
import AffectedConsumptionInput from '../../components/AddRequestItem/AffectedConsumptionInput'
import NumeroNUP from '../../components/AddRequestItem/TextInputSimple'
import ElementsSubFilter from '../../components/AddRequestItem/ElementsSubFilter'
//import NullSelector from '../../components/AddRequestItem/NullSelector'
//import fetchProyectos from '../store/actions/entities'

import { hasInitialValue, central, line, substation_sub_barra, substation_sub_pano
  ,substation_sub_transformadores2D, substation_sub_transformadores3D, substation_sub_bancoscondensadores, substation_sub_sistcomper, substation_sub_compensadoresactivos,  substation_sub_condensadoresserie, substation_sub_condensadoressincronos, substation_sub_reactores, substation_sub_medfacturacion 
} from '../../utils/workRequestUtils'

import { filterSubInstallations, filterProfileInstallations } from '../../utils/misc'
import './AddRequest.css'
import AccessControl from '../../components/AccessControl'
import { isUndefined } from 'lodash'

const initialState = {
  //5942
  nup_type: null,
  sub_elements: '',
  //WnronupId: null,
  companyId: null,
  //installation_subtype: 0,
  installation_type: 'central',
  consumption_type: 'none',
  postponable: false,
  installation: null, // Installation ID
  isTourRunning: false,
  //WeditedNronupId: false,
  editedCompanyId: false,
  editedInstallationType: false,
  editedInstallationId: false,
  selectedAllSubstation: false
}

const defaultTourSteps = [
  // See getTourSteps().
  {
    userTypes: ["superadmin"],
    title: "Empresa",
    target: "#company-form-item .ant-form-item",
    content: `Solo los usuarios del coordinador pueden escojer la empresa.`,
    disableBeacon: true
  },
  {
    title: "Tipo de instalación",
    target: "#installation-type-form-item .ant-form-item",
    content: `Debes seleccionar el tipo de instalación antes de seleccionar la instalación y la
              subinstalación.`,
    disableBeacon: true
  },
  {
    title: "Instalación",
    target: "#installation-form-item .ant-form-item",
    content: "Puedes seleccionar las instalaciones asociadas a tu perfil actual.",
    disableBeacon: true
  },
  {
    title: "Subinstalación",
    target: "#subinstallation-form-item .ant-form-item",
    content: `Debes seleccionar la instalación antes de escojer la subinstalación afectada. Se
              mostrarán las subinstalaciones asociadas a tu perfil actual.`,
    disableBeacon: true
  },
  {
    title: "Consumo afectado",
    target: "#consumption-type-form-item .ant-form-item",
    content: `Luego de seleccionar el tipo de consumo podrás agregar los distribuidores
              afectados.`,
    disableBeacon: true
  },
  {
    title: "Trabajos a realizar",
    target: "#work-description-section",
    content: `Debes proporcionar una descripción de los trabajos a realizar y el nivel de riesgo
              asociado a estos.`,
    disableBeacon: true,
    spotlightPadding: 0
  },
  {
    title: "Documentación asociada",
    edition: false,
    target: "#associated-documentation",
    content: `Debes subir la documentación asociada a la solicitud.`,
    disableBeacon: true,
    spotlightPadding: 0
  },
  {
    title: "Plazo",
    target: "#period-form-item .ant-form-item",
    content: `Debes especificar las fechas de inicio y término del mantenimiento.`,
    disableBeacon: true,
    placement: "top"
  },
  {
    title: "Plazo alternativo",
    target: "#alternative-period-form-item .ant-form-item",
    content: `En caso de ser postergable, debes especificar fechas alternativas para el inicio y
              término del mantenimiento.`,
    disableBeacon: true,
    placement: "top"
  }
]

const gutter = [32]

const mapStateToProps = (state) => {
  const {entities, user, pmpm} = state
  
  const {
    proyectos, companies, infotecnicaCompanies, region, central, line, 
    sub_barra, sub_pano, sub_transformadores2D, sub_transformadores3D, sub_bancoscondensadores, sub_sistcomper,sub_compensadoresactivos, sub_condensadoresserie,sub_condensadoressincronos, sub_reactores, sub_medfacturacion,
    substation_sub_barra, substation_sub_pano, substation, substation_sub_transformadores2D, substation_sub_transformadores3D, substation_sub_bancoscondensadores, substation_sub_sistcomper, substation_sub_compensadoresactivos, substation_sub_condensadoresserie, substation_sub_condensadoressincronos, substation_sub_reactores, substation_sub_medfacturacion,
    unit, section
    
  } = entities
  const {currentRequest, addingWorkRequest} = pmpm
  return {

    proyectos,
    companies,
    infotecnicaCompanies,
    region,
    central,
    line,
    sub_barra,
    sub_pano,
    sub_transformadores2D,
    sub_transformadores3D,
    sub_bancoscondensadores,
    sub_sistcomper,
    sub_compensadoresactivos,
    sub_condensadoresserie,
    sub_condensadoressincronos,
    sub_reactores,
    sub_medfacturacion,
    substation,
    substation_sub_barra,
    substation_sub_pano,
    substation_sub_transformadores2D,
    substation_sub_transformadores3D,
    substation_sub_bancoscondensadores,
    substation_sub_sistcomper,
    substation_sub_compensadoresactivos,
    substation_sub_condensadoresserie,
    substation_sub_condensadoressincronos,
    substation_sub_reactores,
    substation_sub_medfacturacion,
    unit,
    section,
    user,
    workRequest: currentRequest,
    addingWorkRequest
  }
} 

const installation_subtype = {
  valor: 0
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const program_id = ownProps.activeKeyProgram.id
  return {
    addNewWorkRequest: params => {
      dispatch(newWorkRequest({program_id, ...params}))
    },
    editOldWorkRequest: (isCoordinador, workRequest, queryParams) => {
      dispatch(editWorkRequest(isCoordinador, workRequest, queryParams))
    }
  }
}

class AddRequest extends React.Component {
  constructor(props){
    super(props)
    this.state = {...initialState}
  }

  handleCompany = (value) => {
    this.setState({
      companyId: value,
      installation: null,
      editedCompanyId: true
    })
    this.props.form.resetFields(['installation', 'sub_installation'])
    
  }

  handleProyecto = (value) => {
    this.setState({
      companyId: value,
      installation: null,
      editedCompanyId: true
    })
  }  

  /*
  //W
  handleNronup = (value) => {
    this.setState({
      nronupId: value,
      installation: null,
      editedNronupId: true
    })
    this.props.form.resetFields(['installation', 'sub_installation'])
    
  }  
  //W
  */

  handleInstallationType = (value) => {
    this.setState({
      installation_type: value,
      installation: null,
      editedInstallationType: true
    })
    this.props.form.resetFields(['installation', 'sub_installation'])
  }

  handleInstallation = (value) => {
    this.setState({
      installation: value,
      editedInstallationId: true
    })
    this.props.form.resetFields(['sub_installation'])
  }


  handleInstallationSUB = (value) => {
    /*AAA
    this.setState({
      installation_subtype: value
    })
    */
   installation_subtype.valor = value    
    this.props.form.resetFields(['sub_installation'])

  }  

  handlePostponable = (value) => {
    this.setState({
      postponable: value
    })
  }

  handleConsumptionType = (value) => {
    this.setState({
      consumption_type: value
    })
  }

  handleNupType = (value) => {
    if (value === 'No'){
      this.setState({
        nronupIdInitVal: null
      })      
    }
    this.setState({
      nup_type: value
    })
  }  
  //5942
  handleNup = (value) => {
    this.setState({
      nup: value
    })
  } 



  composeParams = (raw_params) => {
    const { user } = this.props
    let params = { ...raw_params}
   
    // Parse Dates
    params.programmed_start_date = params.period[0].toISOString()
    params.programmed_end_date = params.period[1].toISOString()
    delete params.period

    if (params.alternative_period){
      params.alternative_start_date = params.alternative_period[0].toISOString()
      params.alternative_end_date = params.alternative_period[1].toISOString()
      delete params.alternative_period
    }

    // Translate Risk
    switch(params.risk) {
      case 'none':
        params.risk = "Sin Riesgo"
        break
      case 'low':
        params.risk = "Riesgo Bajo"
        break
      case 'mid':
        params.risk = "Riesgo Medio"
        break
      case 'high':
        params.risk = "Riesgo Alto"
        break
      default:
        break
    }
    
    // Additional Comments
    
    if (params.optional_comments) {
      params.additional_comments = params.optional_comments
      delete params.optional_comments
    }
    
    params.user = user.id
    if(!params.company_id) {
      const currentProfile = user.profiles.find(profile => profile.id === user.currentProfile)
      // If current Profile were undefined the user wouldn't reach here, because profiles are
      // used to fill required form fields (e.g. installation).
      if (currentProfile === undefined)
        throw new Error('currentProfile is undefined, the user may have no profiles.')
      params.company = currentProfile.companyId
    } else {
      params.company = params.company_id
      delete params.company_id
    }
    return params
  }

  handleSubmit = (isEdit, workRequest) => { 
    const { form, user, editOldWorkRequest, addNewWorkRequest } = this.props
    form.validateFields((err, values) => {
      if(!err) {
        const queryParams = this.composeParams({...this.state, ...values })

        if (queryParams.nup_type === 'No'){
          queryParams.nup = null
        }

        let startDate
        let mensaje = ""
        let now = new Date().toISOString()

        if( !isEdit )
        {
          for (let [key, value] of Object.entries(queryParams))
          {

            if( key === 'programmed_start_date' )
            {
              startDate = value
              if( value < now )
              {
                mensaje = "La fecha de inicio deber ser igual o mayor que la fecha actual."
              }
            } else if( key === 'programmed_end_date' )
            {
              if( moment(value).diff(moment(startDate), 'days') < 1 )
              {
                mensaje = "La fecha de término debe ser mayor a 24 horas desde la fecha de inicio."
              }
            }
            else if( key === 'alternative_period' && !isUndefined(value))
            {
              let fechaInicio = value[0].toISOString();
              let fechaTermino = value[1].toISOString();
              if( fechaInicio < now )
              {
                mensaje = "La fecha de inicio alternativa deber ser igual o mayor que la fecha actual."
              } else if ( moment(fechaTermino).diff(moment(fechaInicio), 'days') < 1)
              {
                mensaje = "La fecha de término alternativa debe ser mayor a 24 horas desde la fecha de inicio alternativa."
              }
            }
          }
        } else 
        {
          for (let [key, value] of Object.entries(queryParams))
          {

            if( key === 'programmed_start_date' )
            {
              startDate = value
            } else if( key === 'programmed_end_date' )
            {
              if( moment(value).diff(moment(startDate), 'days') < 1 )
              {
                mensaje = "La fecha de término debe ser mayor a 24 horas desde la fecha de inicio."
              }
              else if( value < now )
              {
                mensaje = "La fecha de término deber ser igual o mayor que la fecha actual."
              }

            }
            else if( key === 'alternative_period' && !isUndefined(value))
            {
              let fechaInicio = value[0].toISOString();
              let fechaTermino = value[1].toISOString();
              if( fechaInicio < now )
              {
                mensaje = "La fecha de termino alternativa deber ser igual o mayor que la fecha actual."
              } else if ( moment(fechaTermino).diff(moment(fechaInicio), 'days') < 1)
              {
                mensaje = "La fecha de término alternativa debe ser mayor a 24 horas desde la fecha de inicio alternativa."
              }
            }
          }
        }

        if( mensaje !== "" )
        {
          showNotification(
            'Error de Validación',
            mensaje,
            'error'
          )
        }
        else
        {
          if ( isEdit )
          {
            const isCoordinador = ['superadmin'].includes(user.userType)
            editOldWorkRequest(isCoordinador, workRequest, queryParams)
          }
          else
          {
            addNewWorkRequest(queryParams)
          }
        }
        this.setState(initialState)
      } else {
        showNotification(
          'Error de Validación',
          'El Fomulario de Ingreso Presenta Errores',
          'error'
        )
      }
    })
    //this.setState(initialStateOK)
  }

  basicRule = (required, message) => ({
    rules: [{
      required, 
      message
    }]
  })
  
  workDescriptionRule = () => ({
    rules: [
      {required: true, whitespace: true, message: 'Describa los trabajos que contemplan el mantenimiento'},
      {max: 7000, message: 'La descripción no puede superar los 7000 caracteres'},
      {}
    ]
  })

  additionalCommentRule = () => ({
    rules: [
      {max: 7000, message: 'Comentarios adicionales no pueden superar los 7000 caracteres'},
    ]
  })

  //5942
  NupRule = () => ({
    rules: [
      {max: 5, message: 'NUP no pueden superar los 5 caracteres'},
    ]
  })

  handleCancel = () => {
    this.props.handleCancel()
    this.setState(initialState)
  }

  getTourSteps = () => {
    const { user: {userType}, workRequest } = this.props
    const isEdit = workRequest !== undefined
    return defaultTourSteps.filter(step => {
      const { userTypes, edition } = step
      return (userTypes === undefined || userTypes.includes(userType)) &&
        (isEdit? (edition === undefined || edition) : true)
    })
  }

  render(){
    const subElms = this.props.sub_elements
    const {
      isTourRunning,
      postponable: isPostponable,
      //WnronupId: nronup,
      companyId: company,
      installation_type,
      installation,
      //WeditedNronupId,      
      editedCompanyId,
      editedInstallationType,
      editedInstallationId
    } = this.state
    const {
      addingWorkRequest,
      workRequest,
      companies,
      infotecnicaCompanies,
      proyectos,
      user,
      form: { getFieldDecorator }
    } = this.props

 
    const isEdit = workRequest !== undefined
    const hasRealStartDate = (isEdit && workRequest.real_start_date)? true : false
    const installationTypeInitVal = isEdit? hasInitialValue('installation_type', workRequest, isEdit).initialValue : 'central'
    const installationType = editedInstallationType? installation_type : installationTypeInitVal

    
    if (installationType === 'substation' && isEdit){
      //INC
      const installation_subtype_ST = isEdit? hasInitialValue('installation_subtype', workRequest, isEdit).initialValue : null
      installation_subtype.valor = installation_subtype_ST
    }
    
    if (installationType !== 'substation'){
      installation_subtype.valor = 11
    }   
    const installationTypeObj = 
    installationType === 'central' ? central : 
    installationType === 'line' ? line : 

    installationType === 'substation' && installation_subtype.valor === 11 ? substation_sub_barra : 
    installationType === 'substation' && installation_subtype.valor === 13 ? substation_sub_pano : 
    installationType === 'substation' && installation_subtype.valor === 16 ? substation_sub_transformadores2D :
    installationType === 'substation' && installation_subtype.valor === 17 ? substation_sub_transformadores3D :
    installationType === 'substation' && installation_subtype.valor === 20 ? substation_sub_bancoscondensadores :
    installationType === 'substation' && installation_subtype.valor === 21 ? substation_sub_sistcomper :
    installationType === 'substation' && installation_subtype.valor === 25 ? substation_sub_compensadoresactivos :
    installationType === 'substation' && installation_subtype.valor === 22 ? substation_sub_condensadoresserie :
    installationType === 'substation' && installation_subtype.valor === 23 ? substation_sub_condensadoressincronos :
    installationType === 'substation' && installation_subtype.valor === 24 ? substation_sub_reactores :
    installationType === 'substation' && installation_subtype.valor === 33 ? substation_sub_medfacturacion :
    substation_sub_barra

    
    //const proy = this.props.fetchInfotecnicaCompanies()
    //const proy = this.props.proyectos
    const allInstallations = this.props[`${installationTypeObj.mainId}`]

    ////////////////////// NUP /////////////////////////////////////
    const nronupIdInitVal = isEdit? hasInitialValue('nup', workRequest, isEdit).initialValue : null

    const Tnup_type = nronupIdInitVal === null || nronupIdInitVal === "" ? 'No': 'Si'
    
    let statusVar = this.state.selectedAllSubstation

    if(installation_type === 'central'){
      statusVar = false
    }

    if(installation_subtype.valor === 13){
      statusVar = false
    }

    
    const companyIdInitVal = isEdit? hasInitialValue('company_id', workRequest, isEdit).initialValue : null
    const companyId = editedCompanyId? company : companyIdInitVal
    const installationChoices = filterProfileInstallations(allInstallations, user, companyId, companies, statusVar)
    const installationIdInitVal = isEdit? (editedCompanyId || editedInstallationType? undefined : hasInitialValue('installation', workRequest, isEdit).initialValue) : undefined
    
    const installationId = editedInstallationId? installation : installationIdInitVal
    
    const subInstallationChoices = filterSubInstallations(
      installationTypeObj, 
      installationId, 
      this.props[`${installationTypeObj.subId}`],
      companyId, 
      companies,
      user
    )
    const subInstallationInitVal = isEdit? (editedCompanyId || editedInstallationType || editedInstallationId? undefined : hasInitialValue('sub_installation', workRequest, isEdit).initialValue) : undefined
    const tourSteps = this.getTourSteps()
    const modalTitle = (
      <>
        <span
          style={{marginRight: '8px'}}
        >
          {`${isEdit ? 'Editar':'Ingresar'} Mantenimiento Programado Mayor`}
        </span>
        <Popconfirm
          trigger="hover"
          placement="bottom"
          icon={<Icon type="question-circle" theme="filled"/>}
          title={"¿Deseas comenzar un tour de ayuda en este formulario?"}
          onConfirm={() => this.setState({isTourRunning: true})}
          okText="Sí"
          cancelText="No">
          <Button
            className={"help-button"}
            type="secondary">
            <Icon type="question-circle"/>
          </Button>
        </Popconfirm>
      </>
    )
    return (
      <Modal
        title={modalTitle}
        visible={this.props.isVisible}
        onOk={() => {
          this.handleSubmit(isEdit, workRequest)
        }}
        onCancel={this.handleCancel}
        maskClosable={false}
        okText={isEdit ? 'Guardar Cambios' : 'Ingresar Mantenimiento Programado Mayor'}
        width='70%'
        destroyOnClose={true}
        className='add-modal add-single'
        cancelButtonProps={{ className:'ant-btn-secondary' }}
      >
        <Joyride
          run={isTourRunning}
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          steps={tourSteps}
          locale={{
            back: "Atrás",
            close: "Cerrar",
            last: "Finalizar",
            next: "Siguiente",
            skip: "Terminar tour"
          }}
          styles={{
            options: {
              zIndex: 1000,
              primaryColor: "#3ca7ad"
            },
          }}
          callback={(tourState) => {
            if(tourState.action === "reset")
              this.setState({isTourRunning: false})
          }}
        />
        <Spin spinning={addingWorkRequest}>
        <Form layout='horizontal'>
          <AccessControl
            userType={this.props.user.userType}
            allowedUserTypes={['superadmin']}>
            <Row gutter={gutter}>
              <FormItem
                id="company-form-item"
                span={20}
                label="Empresa"
                extra="Campo visible solo para usuarios del Coordinador."
                decorator={getFieldDecorator}
                formId="company_id"
                args={{
                  ...this.basicRule(true, 'Debe seleccionar la empresa a la que corresponde la solicitud.'),
                  initialValue: companyIdInitVal
                }}
              >
                <CompanySelector
                  companies={companies}
                  handler={this.handleCompany}
                />
              </FormItem>
            </Row>
          </AccessControl>
          {/*************************************************************************************************************************************************** */}
          <Row gutter={gutter}>
            <FormItem
              span={5}
              id="installation-type-form-item" 
              label='Tipo de Instalación ' 
              decorator={getFieldDecorator}
              formId='installation_type'
              args={{
                ...this.basicRule(true, 'Indique el tipo de instalación'),
                initialValue: installationTypeInitVal
              }}>
                <TypeSelector
                  handler={this.handleInstallationType}
                />
            </FormItem>
            {/*************************************************************************************************************************************************** */}
            <FormItem
              id="installation-form-item"
              span={8}
              label={`Seleccione ${installationTypeObj.mainText}`}
              extra="Antes debes seleccionar el tipo de instalación."
              decorator={getFieldDecorator}
              formId='installation'
              args={{
                ...this.basicRule(true, `Selecione ${installationTypeObj.mainText}`),
                initialValue: installationIdInitVal
              }}>
                <InstallationSelector
                  main={true}
                  choices={installationChoices}
                  handler={this.handleInstallation}
                />
            </FormItem>

            {
              installation_type === 'substation' ? (  
                <>
                  <FormItem 
                    id="trabajoSobre-form-item"
                    span={6}
                    label= 'Trabajo Sobre:'
                    decorator={getFieldDecorator}
                    formId='installation_subtype'
                    args={{
                      ...this.basicRule(false, ''),
                      initialValue: 11
                      }}>

                <ElementsSubFilter
                  main={true}
                  choices={subElms}
                  handler={this.handleInstallationSUB} 
                />                    

                  </FormItem>
                  
                </>
                ) : null
            } 

            {
              installation_type === 'substation' ? (
                <FormItem
                  id="otro-campo-form-item"
                  span={4}
                  label="Todas las subestaciones"
                  decorator={getFieldDecorator}
                  formId='otro_campo'
                  args={{
                    initialValue: subInstallationInitVal
                  }}>
                  <div>
                    <Button 
                      type={this.state.selectedAllSubstation === true ? 'primary' : 'default'}
                      onClick={() => this.setState({ selectedAllSubstation: true })}
                      style={{ marginRight: '10px' }}
                    >
                      Sí
                    </Button>
                    <Button 
                      type={this.state.selectedAllSubstation === false ? 'primary' : 'default'}
                      onClick={() => this.setState({ selectedAllSubstation: false })}
                    >
                      No
                    </Button>
                  </div>
                </FormItem>
              ) : null
            }

            {
              installation_type === 'line' ? (
                <FormItem
                id="otro-campo-form-item"
                span={4}
                label="Todas las lineas"
                decorator={getFieldDecorator}
                formId='otro_campo'>
                <div>
                <Button 
                  type={this.state.selectedAllSubstation === true ? 'primary' : 'default'}
                  onClick={() => this.setState({ selectedAllSubstation: true })}
                  style={{ marginRight: '10px' }}
                >
                  Sí
                </Button>
                <Button 
                  type={this.state.selectedAllSubstation === false ? 'primary' : 'default'}
                  onClick={() => this.setState({ selectedAllSubstation: false })}
                >
                  No
                </Button>
              </div>
              </FormItem>
                ) : null
            } 

            
            {
             //add
             this.state.installation !== ''  && installationType.valor !== '' &&
             installation_subtype.valor !== 99 && installation_subtype.valor !== 91 && installation_subtype.valor !== 92  && installation_subtype.valor !== 93  
             && installation_subtype.valor !== 94  && installation_subtype.valor !== 95 ? ( 

            <FormItem
              id="subinstallation-form-item"
              span={8}
              label={`Seleccione ${installationTypeObj.subText}`}
              extra={`Antes debes seleccionar ${installationTypeObj.mainText}.`}
              decorator={getFieldDecorator}
              formId='sub_installation'
              args={{
                ...this.basicRule(true, `Selecione ${installationTypeObj.subText}`),
                initialValue: subInstallationInitVal
                }}>
                <InstallationSelector
                  main={false}
                  choices={subInstallationChoices}
                />
            </FormItem>
          
            
            ) : null
            
            }

          </Row>
          <Row gutter={gutter}>
            <FormItem
              id="consumption-type-form-item" 
              span={24}
              label='Consumos Afectados (Pérdida de Suministro)' 
              formId='consumption_type'
              decorator={getFieldDecorator}
              args={{
                ...this.basicRule(true, 'Debe selecciona un tipo de consumo afectado'),
                ...hasInitialValue('consumption_type', workRequest, isEdit)
              }}>
              <ConsumptionTypeSelector
                handler={this.handleConsumptionType}
              />
            </FormItem>
          </Row>
          { !['none', 'No tiene Consumo Afectado'].includes(this.state.consumption_type) || isEdit? (
            <Row gutter={gutter}>
              <Divider orientation='left'>Añadir Distribuidores Afectados</Divider>
              <FormItem 
                label=''
                formId='affected_dealers'
                decorator={getFieldDecorator}
                args={{
                  ...this.basicRule(false, ''),
                  ...hasInitialValue('affected_dealers', workRequest, isEdit)
                }}>
                <AffectedConsumptionInput company={infotecnicaCompanies} region={this.props.region} handler={this.handleAffectedConsumption}/>
              </FormItem> 
            </Row>
          ): null }

          <Row
            id="work-description-section"
            gutter={gutter}>
            <Divider orientation='left'>Trabajos a Realizar</Divider>
              <FormItem 
                span={12}
                label='Descripción de los Trabajos' 
                decorator={getFieldDecorator}
                formId='work_description'
                args={{
                  ...this.workDescriptionRule(),
                  ...hasInitialValue('work_description', workRequest, isEdit)
                }}>
                <TextInput/>
              </FormItem>
              <FormItem 
                span={6}
                label='Nivel de Riesgo de los Trabajos' 
                decorator={getFieldDecorator}
                formId='risk'
                args={{
                  ...this.basicRule(true, 'Indique el Nivel de Riesgo Asociado a los Trabajos'),
                  ...hasInitialValue('risk', workRequest, isEdit)
                }}>
                <RiskSelector/>
              </FormItem>
          </Row>
          { 
            !isEdit ? (
            <Row
              id="associated-documentation"
              gutter={gutter}>
              <Divider orientation='left'>Documentación Asociada</Divider>
              <FormItem 
                span={5}
                label='PDF Carta de Solicitud'
                decorator={getFieldDecorator}
                formId='file_letter'
                args={{
                  ...this.basicRule(true, 'Seleccione un archivo')
                }}>
                <Upload accept=".pdf"/>
              </FormItem>
              <FormItem 
                span={5}
                label='Excel descripción del mantenimiento'
                decorator={getFieldDecorator}
                formId='file_description'
                args={{
                  ...this.basicRule(true, 'Seleccione un archivo')
                }}>
                <Upload accept=".xlsx,.xls,.xlsm"/>
              </FormItem>
            </Row>)
            : null 
          }
          <Row gutter={gutter}>
            <Divider orientation='left'>Plazos del Mantenimiento</Divider>
              <FormItem
                id="period-form-item"
                span={12}
                label='Fechas de Inicio y Término' 
                decorator={getFieldDecorator}
                formId='period'
                args={{
                  ...this.basicRule(true, 'Seleccione Fechas de Inicio y Término del Mantenimiento'),
                  ...hasInitialValue('period', workRequest, isEdit)
                }}>
                  <DateRangeSelector
                    hasRealStartDate={hasRealStartDate}
                    programmed_dates={isEdit? [workRequest.programmed_start_date, workRequest.programmed_end_date] : null}
                  />
              </FormItem>
              <FormItem 
                span={12}
                label='¿Es Postergable?' 
                decorator={getFieldDecorator}
                formId='postponable'
                args={{
                  ...this.basicRule(true, 'Indique si el mantenimiento puede ser pospuesto'),
                  ...hasInitialValue('postponable', workRequest, isEdit)
                }}>
                <PostponableInput
                  handler={this.handlePostponable}
                  disabled={hasRealStartDate}
                />
              </FormItem>
          </Row>
          <Row gutter={gutter}>
            <FormItem
                id="alternative-period-form-item"
                span={12}
                label='Fechas Alternativas de Inicio y Término' 
                decorator={getFieldDecorator}
                formId='alternative_period'
                args={{
                  ...this.basicRule(isPostponable, 'Indique fechas alternativas para la realización de los trabajos'),
                  ...hasInitialValue('alternative_period', workRequest, isEdit)
                }}>
                <DateRangeSelector disabled={!isPostponable || hasRealStartDate}/>
              </FormItem>
              <FormItem 
                span={12}
                label='Comentarios Adicionales' 
                decorator={getFieldDecorator}
                formId='optional_comments'
                args={{
                  ...this.additionalCommentRule(),
                  ...hasInitialValue('optional_comments', workRequest, isEdit)
                }}>
                <TextInput/>
              </FormItem>
          </Row>
          
          
          <Row gutter={gutter}>
            <FormItem
              id="consumption-type-form-item" 
              span={7}
              label='Mantenimiento Está Relacionado a NUP' 
              formId='nup_type'
              decorator={getFieldDecorator}
              args={{
                ...this.basicRule(true, 'Debe selecciona si mantenimiento está relacionado con NUP'),
                //...hasInitialValue(Tnup_type,workRequest,isEdit)
                //...hasInitialValue('nup_type', workRequest, isEdit)
                //...this.basicRule(true, 'Debe selecciona si mantenimiento está relacionado con NUP'),
                initialValue: Tnup_type                
              }}>
              <NupSelector
                handler={this.handleNupType}
              />
            </FormItem>

          {!['null', 'No'].includes(this.state.nup_type) ? ( 
              //this.state.nup_type === 'Si' ? (
              Tnup_type === 'Si' || this.state.nup_type === 'Si' ? (
              
              <FormItem 
                span={5}
                label='Ingrese NUP'
                formId='nup'
                decorator={getFieldDecorator}
                args={{
                  ...this.basicRule(true, 'Debe seleccionar NUP'),
                  ...hasInitialValue('nup', workRequest, isEdit)
                }}>
               <NumeroNUP
                  proyectos={proyectos}
               />
              </FormItem> 
            ): null
          ): null          
          }

         
          </Row>

           </Form>
        </Spin>
      </Modal>
    )
  }
}

const wrappedComponent = Form.create({ name: 'addWorkRequest' })(AddRequest);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent)