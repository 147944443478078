import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Spin,
  Modal,
  Button,
  Icon,
  Popconfirm,
  Row,
  Col,
} from 'antd'
import Joyride from 'react-joyride'
import Papa from 'papaparse'
import CSVReader from '../../components/AddRequestMassiveItem/CSVReader'
import GridView from '../../components/AddRequestMassiveItem/GridView'
import {
  startReading,
  doneReading,
  readingError,
  startParsing,
  doneParsing,
  parsingError,
  startValidating,
  doneValidating,
  validationError,
  fillDataset
} from '../../store/actions/massive'
import { 
  newWorkRequestMassive
} from '../../store/actions/workRequests'
import { handleDownload } from '../../utils/misc'
import CompanySelector from '../../components/AddRequestItem/CompanySelector'
import './AddRequestMassive.css'


const tourSteps = [
  {
    title: "Documentos requeridos",
    target: "#massive-form-required-documents",
    content: "Debes subir los documentos que aplican para todas las solicitudes de la carga masiva.",
    disableBeacon: true
  },
  {
    title: "Formulario de solicitud",
    target: ".massive-form-item",
    content: `Se mostrará un formulario para cada una de las solicitudes ingresadas. El formulario
              es el mismo que para la carga individual de solicitudes.`,
    disableBeacon: true
  },
  {
    title: "Subir el formulario",
    target: "#submit-massive-form-button",
    content: `Al hacer click en este botón se validará cada una de las solicitudes y se enviarán
              los datos si no hay errores.`,
    disableBeacon: true
  },
]


class Component extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      isTourRunning: false,
      companyId: null,
    }
  }

  onRead = (fileContent) => {
    this.props.doneReading()
    this.parse(fileContent)
  }

  parse = (fileContent) => {
    this.props.startParsing()
    const contentParsed = Papa.parse(
      fileContent, {
        header: true,
        skipEmptyLines: 'greedy'
      }
    )
    this.props.doneParsing(contentParsed)
  }

  fillDataset = () => {
    fillDataset(this.props.fileContent)
  }

  handleCompany = (companyId) => {
    this.setState({companyId})
  }

  closeModal = () => {
    this.setState({
      companyId: null
    })
    this.props.handleCancel()
  }

  render() {
    const {
      isVisible,
      shouldShowReader,
      shouldShowGrid,
      isParsing,
      isReading,
      isCreating,
      isValidating,
      dataset,
      installations,
      newWorkRequestMassive,
      activeKeyProgram,
      user,
      companies,
      massiveLoadTemplate
    } = this.props
    
    const askForCompany = user.userType === 'superadmin' && !shouldShowGrid
    const disableReader = user.userType === 'superadmin' && this.state.companyId === null
    
    const modalTitle = (
      <>
        <span
          style={{marginRight: '8px'}}
        >
          Ingreso Masivo de Mantenimientos Programados Mayores
        </span>
        { shouldShowGrid?
          <Popconfirm
            trigger="hover"
            placement="bottom"
            icon={<Icon type="question-circle" theme="filled"/>}
            title={"¿Deseas comenzar un tour de ayuda en este formulario?"}
            onConfirm={() => this.setState({isTourRunning: true})}
            okText="Sí"
            cancelText="No">
            <Button
              className={"help-button"}
              type="secondary">
              <Icon type="question-circle"/>
            </Button>
          </Popconfirm> : null
        }
      </>
    )

    return (
      <Modal
        title={modalTitle}
        visible={isVisible}
        onCancel={this.closeModal}
        width='98%'
        destroyOnClose={true}
        maskClosable={false}
        className='add-modal-massive'
        okButtonProps={{ className: 'btn-hidden'}}
        cancelButtonProps={{ className:'ant-btn-secondary' }}
      >
        <Joyride
          run={this.state.isTourRunning}
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          steps={tourSteps}
          locale={{
            back: "Atrás",
            close: "Cerrar",
            last: "Finalizar",
            next: "Siguiente",
            skip: "Terminar tour"
          }}
          styles={{
            options: {
              zIndex: 1000,
              primaryColor: "#3ca7ad"
            },
          }}
          callback={(tourState) => {
            if(tourState.action === "reset")
              this.setState({isTourRunning: false})
          }}
        />
        <Spin spinning={isReading || isValidating || isParsing || isCreating}>
          {
            massiveLoadTemplate
              ? <Row style={{maxWidth:'400px', float:'right', zIndex:'100'}}>
              <Col>
                <Button
                  onClick={() => handleDownload(massiveLoadTemplate.uid, massiveLoadTemplate.type)}
                  type='primary'
                  size='small'
                  style={{margin:'10px'}}
                >
                  <a href='#top'><Icon type='download' /> Descargar Ejemplo CSV</a>
                </Button>
              </Col>
            </Row>
            : null
          }
          {
            askForCompany && (
              <div style={{padding: '20px'}}>
                <label style={{marginRight: '8px'}}>Empresa:</label>
                <CompanySelector
                width='400px'
                value={this.state.companyId}
                companies={companies}
                onChange={this.handleCompany}
              />
              </div>
              
            )
          }
          { 
            shouldShowReader 
            ? <Row style={{clear:'both'}}>
                <CSVReader 
                  disabled={disableReader}
                  onRead={this.onRead} 
                  reading={this.props.startReading}
                /> 
              </Row>
            : null 
          }
          {
            shouldShowGrid
            ? <GridView
                companyId={this.state.companyId}
                userType={user.userType}
                dataset={dataset}
                installations={installations}
                activeKeyProgram={activeKeyProgram}
                requestCreator={newWorkRequestMassive}
                companies={companies}
                user={user}
              />
            : null
          }
        </Spin>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  shouldShowReader: state.massive.shouldShowReader, 
  shouldShowGrid: state.massive.shouldShowGrid,  
  isParsing: state.massive.isParsing,  
  isReading: state.massive.isReading,  
  isCreating: state.massive.isCreating,
  isValidating: state.massive.isValidating,  
  fileContent: state.massive.fileContent,  
  dataset: state.massive.dataset,
  installations: {
    central: state.entities.central,
    unit: state.entities.unit,
    line: state.entities.line,
    section: state.entities.section,
    substation : state.entities.substation,
    sub_barra: state.entities.sub_barra,
    sub_pano: state.entities.sub_pano,
    sub_transformadores2D: state.entities.sub_transformadores2D,
    sub_transformadores3D: state.entities.sub_transformadores3D,
    sub_bancoscondensadores: state.entities.sub_bancoscondensadores,
    sub_sistcomper: state.entities.sub_sistcomper,
    sub_compensadoresactivos: state.entities.sub_compensadoresactivos,
    sub_condensadoresserie: state.entities.sub_condensadoresserie,
    sub_condensadoressincronos: state.entitiessub_condensadoressincronos,
    sub_reactores: state.entities.sub_reactores,
    sub_medfacturacion: state.entities.sub_medfacturacion
  },
  user: state.user,
  companies: state.entities.companies,
  massiveLoadTemplate: state.entities.templates.massiveLoad,
})
const mapDispatchToProps = (dispatch) => bindActionCreators({
  startReading,
  doneReading,
  readingError,
  startParsing,
  doneParsing,
  parsingError,
  startValidating,
  doneValidating,
  validationError,
  newWorkRequestMassive
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)
