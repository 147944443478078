import React from 'react'
import {Select} from 'antd'
import { selectSearch } from '../../../utils/misc'

const {Option} = Select
const Component = (props) => {
  const {choices, main, handler, value, onChange} = props
  const {byId, allIds} = choices

  return (
    
    <Select
      value={value}
      onChange={value => {
        onChange(value)
        if ( main ) {
          handler(value)
        } 
      }} 
      showSearch
      style={{width: props.width || '100%'}}
      mode={main ? 'default' : 'multiple'}
      filterOption={selectSearch}
    >
      {
        allIds.map(installationId => (
          <Option
            key={`${main ? 'main-inst' : 'sub-inst'}-${installationId}`}
            value={parseInt(installationId)}
          >
            {byId[installationId].nombre}
          </Option>
        ))
      }
    </Select>
  )
}

export default Component
