import React from 'react'
import { Select } from 'antd'
import { selectSearch } from '../../../utils/misc'


const { Option } = Select

const SelectCompany = (props) => {
  const { value, onChange, mode, companies } = props
  return (
    <Select
      mode={mode}
      value={value}
      onChange={onChange}
      showSearch
      filterOption={selectSearch}
      >
      {
        companies.allIds.map(id => {
        const company = companies.byId[id]
        const text = `${company.id}  (${company.nombre})`
        return <Option value={id}>{text}</Option> 
        })
      }
    </Select>
  )
}

export default SelectCompany