import {
  authConstants
} from '../constants/auth'
import {
  reqTokenValid,
  reqCognitoCodeLogin
} from '../api/auth'
import history from '../../utils/history'
import decoded from 'jwt-decode'
import { parseJSON } from '../../utils/misc'

export function cognitoCodeLogin(cognitoCode) {
  return (dispatch) => {
    dispatch({
      type: authConstants.AUTH_USER
    })
    reqCognitoCodeLogin(cognitoCode)
      .then(parseJSON)
      .then((response) => {
        dispatch(loginUserSuccess(response))
      })
      .catch(err => {

        if ( err === 'error_user_empresa' ){
          console.log('error_user_empresa')
          dispatch({
            type: authConstants.AUTH_USER_ERROR,
            payload: 'Usuario Coordinado no tiene empresas registradas para acceder a la aplicación. Favor contactar al administrador.'
          })
        } else if ( err === 'error_user_inexistente' ){
          console.log('error_user_inexistente')
          dispatch({
            type: authConstants.AUTH_USER_ERROR,
            payload: 'El usuario no está registrado para acceder a la aplicación. Favor contactar al administrador.'
          })
        } else {
          dispatch({
            type: authConstants.AUTH_USER_ERROR,
            payload: 'Error al autenticar. Favor contactar al administrador.'
          })
        }

      })
  }
}

export function loginUserSuccess(params) {
  return (dispatch) => {
    const { refresh_token, id_token, user_type, profiles, id} = params
    const decodedToken = decoded(id_token)
    localStorage.setItem('token', id_token)
    localStorage.setItem('refresh_token', refresh_token)
    
    // Se cambia const y username para SSO
    //const splittedUsername = decodedToken['cognito:username'].split("\\")   
    const splittedUsername = decodedToken['name'] ? decodedToken['name'] : decodedToken['middle_name']
    dispatch({
      type: authConstants.AUTH_USER_SUCCESS,
      payload: {
        id: id,
        refreshToken: refresh_token,
        token: id_token,
        // Se cambia username para SSO
        //username: splittedUsername[splittedUsername.length - 1],
        username: splittedUsername,   
        email: decodedToken.email,
        userType: user_type,
        profiles: profiles
        // role: decodedToken.role,
        // permissions: decodedToken.permissions,
      }
    })
    // SUCCESSFUL LOGIN
    // dispatch(getUser(token))
  }
}

export function isTokenValid (token) {
  return dispatch => {
    dispatch({
      type: authConstants.AUTH_USER
    })
    reqTokenValid(token)
      .then(parseJSON)
      .then((data) => {
        if (data.is_valid) {
          const refreshToken = localStorage.getItem('refresh_token')
          dispatch(loginUserSuccess({
            id: data.id,
            id_token: token,
            refresh_token: refreshToken,
            user_type: data.user_type,
            profiles: data.profiles
          }))
        } else {
          dispatch(logout())
        }
      })
      .catch(e => {
        console.log(e)
        dispatch(logout())
      })
  }
}

export function logout() {
  localStorage.removeItem('token')
  localStorage.removeItem('refresh_token')
  return {
    type: authConstants.UNAUTH_USER
  }
}

export function logoutAndRedirect() {

  return (dispatch) => {
    dispatch(logout())
    history.push('/login')
  }

}

export function checkAuthUser() {
  return (dispatch, getState) => {
    const { auth } = getState()
    if (!auth.isAuthenticated) {
      const token = localStorage.getItem('token')
      if (!token) {
        return dispatch(logoutAndRedirect())
        //console.log('token-------->',token)
      }
      return dispatch(isTokenValid(token))
    }
  }
}
