import React from 'react'
import { Select } from 'antd'
import { selectSearch } from '../../../utils/misc'

const { Option } = Select

const ProgramFilter = (props) => {
  const { value, onChange, width, programs } = props
  return (
    <Select
      showSearch
      filterOption={selectSearch}
      value={value}
      onChange={(value) => {
        onChange(value)
      }}
      style={{width: width || '100%'}}
      placeholder={'Selecciona un programa'}
    >
      {Object.values(programs).map(program =>
        <Option
          key={program.id}
          value={program.id}
          >
          {program.correlative}
        </Option> 
      )}
    </Select>
  )
}

export default ProgramFilter