import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Row,
  Spin,
  Form,
  Input,
  Modal,
  Typography
} from 'antd'
import { showNotification } from '../../utils/notification'
import { addDeletionRequest } from '../../store/actions/workRequests'

const { Item } = Form
const { TextArea } = Input
const { Text } = Typography

const mapStateToProps = (state) => ({
  user: state.user,
  isVisible: state.pmpm.deleteRequestModalOpen,
  request: state.pmpm.currentRequest,
  shouldSpin: state.pmpm.deletingWorkRequest
})

const mapDispatchToProps = (dispatch) => 
  bindActionCreators({
    addDeletionRequest
  }, dispatch)

const initialState = {
  reason: ''
}

class Component extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      ...initialState
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { request, user } = this.props
        const isCoordinador = ['superadmin'].includes(user.userType)
        this.props.addDeletionRequest(
          request.id,
          isCoordinador,
          user.id,
          values
        )
        this.setState({
          ...initialState
        })
      } else {
        showNotification(
          'Se ha producido un error',
          'Debe indicar un motivo para eliminar la solicitud',
          'error'
        )
      }
    })
  } 

  updateTextArea = ({ target }) => {
    const { value } = target
    this.setState({
      rejection: value
    })
  }

  render() {
    const { handleCancel, request, isVisible, form, shouldSpin} = this.props
    const { getFieldDecorator } = form
    return (
      request
      ? <Modal
        title="Solicitar Eliminación de Mantenimiento Programado Mayor" 
        visible={isVisible}
        destroyOnClose={true}
        onOk={this.handleSubmit}
        maskClosable={false}
        okText='Si, Estoy Seguro. Enviar Solicitud'
        onCancel={handleCancel}
        width='600px'
      >
        <Row gutter={[16, 16]}>
          <Text strong>¿Está seguro que desea eliminar la solicitud {request.correlative}?</Text>
        </Row><br/>
        <Row gutter={[16, 26]}>
          <Text>En caso de querer proceder con la eliminación, debe indicar el motivo de esta decisión.</Text>
        </Row><br/>
        <Spin spinning={shouldSpin}>
          <Form
            onSubmit={this.handleStatusChange}
          >
            <Row gutter={[16, 16]}>
              <Item label="Razón de la Eliminación">
                {getFieldDecorator('reason', {
                  rules: [
                    {
                      required: true,
                      message: "Debe indicar el motivo de rechazo"
                    }
                  ]
                })(
                  <TextArea
                    rows={4}
                    maxLength={7000}
                    style={{width: this.props.width || '100%'}}
                    onChange={this.updateTextArea}/>
                )}
              </Item>
            </Row>
          </Form>
        </Spin>
      </Modal>
      : ''
    )
  }
}

const wrappedComponent = Form.create({ name: 'deletion' })(Component);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent)
