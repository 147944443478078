export const programsConstants = {
  FETCH_PROGRAMS: 'FETCH_PROGRAMS',
  FETCH_PROGRAMS_SUCCESS: 'FETCH_PROGRAMS_SUCCESS',
  FETCH_PROGRAMS_ERROR: 'FETCH_PROGRAMS_ERROR',
  ADD_PROGRAM: 'ADD_PROGRAM',
  ADD_PROGRAM_SUCCESS: 'ADD_PROGRAM_SUCCESS',
  ADD_PROGRAM_ERROR: 'ADD_PROGRAM_ERROR',
  SELECT_PROGRAM: 'SELECT_PROGRAM',
  SELECT_HISTORIC_PROGRAM: 'SELECT_HISTORIC_PROGRAM',
  MOVE_PROGRAMS: 'MOVE_PROGRAMS',
  MOVE_PROGRAMS_SUCCESS: 'MOVE_PROGRAMS_SUCCESS',
  MOVE_PROGRAMS_ERROR: 'MOVE_PROGRAMS_ERROR',
  UPDATE_PRELIMINARY_EDITION: 'UPDATE_PRELIMINARY_EDITION',
  UPDATE_PRELIMINARY_EDITION_SUCCESS: 'UPDATE_PRELIMINARY_EDITION_SUCCESS',
  UPDATE_PRELIMINARY_EDITION_ERROR: 'UPDATE_PRELIMINARY_EDITION_ERROR' 
}
