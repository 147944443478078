import React from 'react'
import { Radio } from 'antd'

const { Group, Button } = Radio

const Component = (props) => {
  const { handler, value, onChange, reduced, disabled } = props
  return (
    <Group
      value={value}
      onChange={({ target }) => {
        const { value } = target
        onChange(value)
        handler(value)
      }} 
      style={{width: props.width || '100%'}}
    >
      <Button value={true} disabled={disabled}>{reduced ? 'Sí' : 'Postergable'}</Button>
      <Button value={false} disabled={disabled}>{reduced ? 'No' : 'Impostergable'}</Button>
    </Group>
  )
}

export default Component
