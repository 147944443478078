import React from 'react'
import {Select} from 'antd'
import { selectSearch } from '../../../utils/misc'

const {Option} = Select

const choices = [
  { value: 1, text: 'Todos' },
  { value: 11, text: 'Secciones de barra' },
  { value: 13, text: 'Paños' },
  { value: 16, text: 'Transformadores 2D' },
  { value: 17, text: 'Transformadores 3D' },
  { value: 20, text: 'Bancos Condensadores' },       
  { value: 21, text: 'Sistemas de Compensación Estática de Reactivos' }, 
  { value: 25, text: 'Compensadores Activos' }, 
  { value: 22, text: 'Condensadores Serie' }, 
  { value: 23, text: 'Condensadores Síncronos' }, 
  { value: 24, text: 'Reactores' }, 
  { value: 99, text: 'Scada' },
  { value: 33, text: 'Medidores de facturación' },
  { value: 91, text: 'Grupo de Emergencia' },
  { value: 92, text: 'Batería' },
  { value: 93, text: 'Tendido de Cable' },
  { value: 94, text: 'Cargadores' },
  { value: 95, text: 'Telecomunicación' }
]

const Component = (props) => {
  const {main, handler,value, onChange} = props
  return (

    <Select
      value={value}
      onChange={(value) => {
        onChange(value)
        if ( main ) {
            handler(value)
          }         
      }} 
      style={{width: props.width || '100%'}}
      filterOption={selectSearch}
    >
      {
        choices.map(choice => (
          <Option
            key={`subelm-${choice.value}`}
            value={choice.value}
          >
            {choice.text}
          </Option>
        ))
      }
    </Select>
  )
}

export default Component
