import {
  massiveConstants
} from '../constants/massive'
// import { showNotification } from '../../utils/notification'

export function startReading () {
  return {
    type: massiveConstants.START_READING
  }
}

export function doneReading (content) {
  return {
    type: massiveConstants.DONE_READING,
    payload: content
  }
}

export function readingError (err) {
  return {
    type: massiveConstants.READING_ERROR,
    payload: err
  }
}

export function startParsing () {
  return {
    type: massiveConstants.START_PARSING
  }
}

export function doneParsing (content) {
  return {
    type: massiveConstants.DONE_PARSING,
    payload: content
  }
}

export function parsingError (err) {
  return {
    type: massiveConstants.PARSING_ERORR,
    payload: err
  }
}

export function startValidating () {
  return {
    type: massiveConstants.START_VALIDATING
  }
}

export function doneValidating () {
  return {
    type: massiveConstants.DONE_VALIDATING
  }
}

export function validationError (err) {
  return {
    type: massiveConstants.VALIDATION_ERROR,
    payload: err
  }
}

export function fillDataset (dataset) {
  return {
    type: massiveConstants.FILL_DATASET,
    payload: dataset
  }
}

export function updateDataset (changes) {
  return {
    type: massiveConstants.UPDATE_DATASET,
    payload: changes
  }
}
