import React from 'react'

const AccessControl = ({userType, allowedUserTypes, children}) => (
  <React.Fragment>
    {
      allowedUserTypes.includes(userType)
        ? children
        : null
    }
  </React.Fragment>
)

export default AccessControl
