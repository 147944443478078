import axios from 'axios'
import client from '../api/apiClient'
import { tokenConfig } from './'

const API_INFOTECNICA = 'https://api-infotecnica.coordinador.cl/v1'

export function getInfotecnicaCompanies () {
  return axios.get(`${API_INFOTECNICA}/empresas/`)
}

export function getCentrals () {
  return axios.get(`${API_INFOTECNICA}/centrales/extended/`)
}

export function getUnits () {
  return axios.get(`${API_INFOTECNICA}/unidades-generadoras/`)
}

export function getLines () {
  return axios.get(`${API_INFOTECNICA}/lineas/`)
}

export function getSections () {
  return axios.get(`${API_INFOTECNICA}/secciones-tramos/`)
}

export function getSubstations () {
  return axios.get(`${API_INFOTECNICA}/subestaciones/extended/`)
}

export function getSubstationElements () {
  return axios.get(`${API_INFOTECNICA}/subestaciones/elementos-totales/`)
}

//5941.i
export function getBarras () {
  return axios.get(`${API_INFOTECNICA}/barras/`)
}


export function getSub_Barras () {
  return axios.get(`${API_INFOTECNICA}/barras/`)
}


export function getSub_Panos () {
  return axios.get(`${API_INFOTECNICA}/panos/`)
}

/////////////////////////////////////////////////////////////////

export function getSub_transformadores2D () {
  return axios.get(`${API_INFOTECNICA}/transformadores-2d/`)
}

export function getSub_transformadores3D () {
  return axios.get(`${API_INFOTECNICA}/transformadores-3d/`) 
}

export function getSub_bancoscondensadores () {
  return axios.get(`${API_INFOTECNICA}/bancos-condensadores/`)
}

export function getSub_sistcomper () {
  return axios.get(`${API_INFOTECNICA}/compensadores-estaticos-reactivos/`)
}

export function getSub_compensadoresactivos () {
  return axios.get(`${API_INFOTECNICA}/compensadores-activos/`)
}

export function getSub_condensadoresserie () {
  return axios.get(`${API_INFOTECNICA}/condensadores-series/`)
}

export function getSub_condensadoressincronos () {
  return axios.get(`${API_INFOTECNICA}/condensadores-sincronos/`)
}

export function getSub_reactores () {
  return axios.get(`${API_INFOTECNICA}/reactores/`)
}


export function getSub_medfacturacion () {
  return axios.get(`${API_INFOTECNICA}/medidores/`)
}

/////////////////////////////////////////////////////////////////
//5941.f
export function getProyectos() {
  return axios.get(`${API_INFOTECNICA}/proyectos/`)
  //return axios.get(`${API_INFOTECNICA}/comunas/`)
}



export function getRegions() {
  return axios.get(`${API_INFOTECNICA}/regiones/`)
}

export function getStatusOptions (token) {
  //console.log('token SSO -------------->', token)
  return client().get('/request_status/', tokenConfig(token))
}

export function getRequestModification (token) {
  return client().get('/request_modification', tokenConfig(token))
}

export function getCompanies (token) {
  return client().get('/company', tokenConfig(token))
}

export function updateCompany (company, fieldUpdates, token) {
  return client().put(`/company/${company.id}/`, {...company, ...fieldUpdates}, tokenConfig(token))
}

export function updateUserCompanyRoles (userId, roleChanges, token) {
  const data= {role_changes: roleChanges}
  return client().put(`user/${userId}/change_roles/`, data, tokenConfig(token))
}

export function updateUserType (userId, newType, token) {
  const data = { new_type: newType }
  return client().put(`user/${userId}/change_type/`, data, tokenConfig(token))
}

export function getFileTemplates (token) {
  return client().get('/file_template/', tokenConfig(token))
}
