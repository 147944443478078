import React from 'react'
import {Select} from 'antd'

const {Option} = Select

const choices = [
  { value: 'none', text: 'Sin Riesgo' },
  { value: 'low', text: 'Bajo Riesgo' },
  { value: 'mid', text: 'Riesgo Medio' },
  { value: 'high', text: 'Alto Riesgo' }
]

const Component = (props) => {
  const {value, onChange} = props
  return (
    <Select
      value={value}
      onChange={(value) => {
        onChange(value)
      }} 
      style={{width: props.width || '100%'}}
    >
      {
        choices.map(choice => (
          <Option
            key={`risk-${choice.value}`}
            value={choice.value}
          >
            {choice.text}
          </Option>
        ))
      }
    </Select>
  )
}

export default Component
