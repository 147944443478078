import _ from 'lodash'
import React from 'react'
import Joyride from 'react-joyride'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { List, Button, Card, Row, Col, Checkbox, Collapse, Icon, Typography, Badge, Popconfirm, Popover} from 'antd'
import './WorkRequestListItem.css'
import { handleDownload, capitalize, formatDate } from '../../utils/misc'
import PopConfirmation from '..//PopConfirmation'
import ModificationConfirmModal from '../ModificationConfirmModal'
import AccessControl from '../../components/AccessControl'
import { 
  openAddRequestModal, 
  openDeleteRequestModal, 
  openAprovalRequestModal
} from '../../store/actions/pmpm'
import { changeWorkRequestSelection } from '../../store/actions/workRequests'

const { Panel } = Collapse
const { Paragraph } = Typography;
const defaultTourSteps = [
  // The targetSuffix of each step should be prependend with the id corresponding to
  // the card the tour is running on, to generate the target field.
  // See getTourSteps().
  {
    userTypes: ["superadmin"],
    statuses: ["Pendiente"],
    tabs: ["current", "preliminary"],
    title: "Seleccionar elementos",
    targetSuffix: "check-box",
    content: "Puedes seleccionar y aprobar o rechazar multiples elementos a la vez.",
    disableBeacon: true
  },
  {
    title: "Detalles de la solicitud",
    targetSuffix: "detail",
    content: `Haz click aquí para ver más detalles de la solicitud. Podrás descargar los archivos
              adjuntos.`,
    disableBeacon: true
  },
  {
    userTypes: ["superadmin"],
    statuses: ["Pendiente"],
    tabs: ["current", "preliminary"],
    title: "Aprobar solicitud",
    targetSuffix: "approve-button",
    content: "Aprueba la solicitud presionando este botón.",
    disableBeacon: true
  },
  {
    userTypes: ["superadmin"],
    statuses: ["Pendiente"],
    tabs: ["current", "preliminary"],
    title: "Rechazar solicitud",
    targetSuffix: "reject-button",
    content: "Rechaza la solicitud indicando la razón de rechazo.",
    disableBeacon: true
  },
  {
    userTypes: ["coordinado"],
    statuses: ["Aprobado", "Pendiente"],
    tabs: ["current", "preliminary"],
    title: "Solicitar modificación",
    targetSuffix: "ask-modify",
    content: "Puedes pedir la modificación de una solicitud, indicando los cambios.",
    disableBeacon: true
  },
  {
    userTypes: ["coordinado"],
    statuses: ["Aprobado", "Pendiente"],
    tabs: ["current", "preliminary"],
    title: "Solicitar eliminación",
    targetSuffix: "ask-remove",
    content: "Pide la eliminación de una solicitud, indicando la razón.",
    disableBeacon: true
  },
  {
    userTypes: ["superadmin"],
    statuses: ["Aprobado", "Pendiente"],
    tabs: ["current", "preliminary"],
    title: "Modificar solicitud",
    targetSuffix: "modify-button",
    content: "Aquí puedes modificar las especificaciones de la solicitud.",
    disableBeacon: true
  },
  {
    userTypes: ["superadmin"],
    statuses: ["Aprobado", "Pendiente"],
    tabs: ["current", "preliminary"],
    title: "Eliminar solicitud",
    targetSuffix: "remove-button",
    content: "Elimina la solicitud indicando la razón de la acción.",
    disableBeacon: true
  },
]

class WorkRequestListItem extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      aprovalAction: true,
      requestModification: false,
      isTourRunning: false,
      tourSteps: [],
      expanded: false,
    }
  }

  aprovalAction = (action, request) => {
    this.props.openAprovalRequestModal(action, [request.id])
  }

  deleteAction = (item) => {
    this.props.openDeleteRequestModal(item)
  }

  handleExpandPanel = () => {
    this.setState({expanded: !this.state.expanded})
  }

  handleCancelModal = () => {
    this.setState({
      showModal: false
    })
  }

  handleModificationModal = () => {
    this.setState({
      showModal: true
    })
  }

  handleEditRequestModal = (workRequest) => {
    this.props.openAddRequestModal(workRequest)
  }
  
  getURL = (item) => {
    //console.log('itemmmmmmmmmmmmmmmmmmmmmmmmmmmm',item)
    if (!item.id_neomante) {
      return '/#'
    }
    const itemType = item.installation_type
    //const itemSubtype = item.iteminstallation_subtype
    const neomanteId = item.id_neomante
    const baseUrl = process.env.REACT_APP_NEOMANTE_HOST
    let uri = ''
    switch(itemType){
      case 'central':
        uri = '/desconexion_intervencion/show/central_generadora/'
      break
      case 'line':
        uri = '/desconexion_intervencion/show/linea/'
      break
      case 'substation':
        uri = '/desconexion_intervencion/show/subestacion/'
      break
      default:
        break
    }
    return `${baseUrl}${uri}${neomanteId}`
  }

  canConfirmWorks = ({confirmed_coordinator, confirmed_coordinate}, status) => {
    const isAproved = status === 'Aprobado'
    if (isAproved) {
      const isConfirmed = confirmed_coordinator || confirmed_coordinate
      const canConfirm = ['coordinado', 'superadmin'].includes(this.props.user.userType)
      if ( canConfirm && !isConfirmed) {
        return true
      }
    }
    return false
  }

  elementId = (suffix) => {
    return `request-item-${this.props.item.id}-${suffix}`
  }

  getTourSteps = () => {
    return defaultTourSteps.filter(step => {
      
      const { user: { userType }, item: { current_status }, selectedProgramTab } = this.props
      const { userTypes, statuses, tabs, targetSuffix } = step
      step.target = '#' + this.elementId(targetSuffix)
      return (userTypes === undefined || userTypes.includes(userType)) &&
        (statuses === undefined || statuses.includes(current_status.name)) &&
        (tabs === undefined || tabs.includes(selectedProgramTab))
    })
  }

  showHelpTour = () => {
    const tourSteps = this.getTourSteps()
    this.setState({tourSteps, isTourRunning: true})
  }

  render() {
    const {item, modifications, checked, changeWorkRequestSelection, programs, selectedProgram, selectedProgramTab, user} = this.props
    if(item === undefined || item.is_deleted){
      return ''
    }
    //const {central, line, substation, unit, section, sub_barra, sub_pano} = this.props 
    const {central, line, substation, unit, section, sub_barra, sub_pano, sub_transformadores2D,
    sub_transformadores3D,sub_bancoscondensadores,sub_sistcomper,sub_compensadoresactivos,sub_condensadoresserie,sub_condensadoressincronos,sub_reactores
    } = this.props
    //const {central, line, unit, section } = this.props 
    if(_.isEmpty(unit.allIds) || _.isEmpty(section.allIds) )
    {
      return ''
    }

    const currentStatus = item.current_status.name 
    const currentNeomanteStatus = item.current_neomante_status
    const programmed_start_date = formatDate(item.programmed_start_date)
    const programmed_end_date = formatDate(item.programmed_end_date)
    const alternative_start_date = item.alternative_start_date ? formatDate(item.alternative_start_date) : '-'
    const alternative_end_date = item.alternative_start_date ? formatDate(item.alternative_end_date) : '-'
    const real_start_date = item.real_start_date ? formatDate(item.real_start_date) : '-'
    const real_end_date = item.real_end_date ? formatDate(item.real_end_date) : '-'
    /*
    const installation_string = item.installation != null ? (
        item.installation_type === 'central' ? 
        (central.byId[item.installation] ? central.byId[item.installation].nombre : `Central id ${item.installation}`) :
        (line.byId[item.installation] ? line.byId[item.installation].nombre : `Línea id ${item.installation}`)
      ) : 'No Ingresada'
    */
    
    const installation_string = item.installation != null ? (
        item.installation_type === 'central' ? 
        (central.byId[item.installation] ? central.byId[item.installation].nombre : `Central id ${item.installation}`) :
        item.installation_type === 'line' ? 
        (line.byId[item.installation] ? line.byId[item.installation].nombre : `Línea id ${item.installation}`) :
        (substation.byId[item.installation] ? substation.byId[item.installation].nombre : `Subestación id ${item.installation}`)
      ) : 'No Ingresada'
    
    const neomanteUrl = this.getURL(item)
    const confirmation = {
      title: 'Estado de Confirmación de Trabajos',
      content: (
        <>
          <b>Coordinador</b>: {item.confirmed_coordinator ? 'Confirmados' : 'Sin Confirmación'}<br/>
          <b>Coordinado</b>: {item.confirmed_coordinate ? 'Confirmados' : ' Sin Confirmación'}
        </>
      )
    }
    let rqModifications = []

    if (!_.isEmpty(modifications.allIds)) {
      rqModifications = modifications.allIds.map(
        modId => modifications.byId[modId]


        
      ).filter(modification => {
        const isMine = [modification.work_request.id, modification.work_request_id].includes(item.id)
        const hasBeenAccepted = modification.applied
        const hasBeenRejected = modification.is_rejected
        return isMine && !(hasBeenAccepted || hasBeenRejected)
      })
    }

    const shouldShowModifications = rqModifications.length > 0 && currentStatus !== 'Rechazado' && user.userType !== 'coordinador'
    
    if (shouldShowModifications){
      //5944.i
      if (rqModifications[0].modified_values !== null){
        const new_programmed_start_date = new Date(rqModifications[0].modified_values.programmed_start_date).getTime()
        const new_programmed_end_date = new Date(rqModifications[0].modified_values.programmed_end_date).getTime()
        var postponable_new = rqModifications[0].modified_values.postponable
        const dif_new = new_programmed_end_date - new_programmed_start_date
        var duracion_new = Math.round(dif_new/(1000*60*60*24))
      }
    }
      //5944.f
  
    const sub_installation_string = item.sub_installation.map(subInstallationId => {
      
       return (
          item.installation_type === 'central' && unit.byId[subInstallationId] !== undefined ?
          `${unit.byId[subInstallationId].nombre}` : 
          item.installation_type === 'line' && section.byId[subInstallationId] !== undefined ?
          `${section.byId[subInstallationId].nombre}` :
          /*
          item.installation_type === 'substation' && item.installation_subtype.toString() === "11" && sub_barra.byId[subInstallationId] !== undefined ?
          `${sub_barra.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype.toString() === "13" && sub_pano.byId[subInstallationId] !== undefined ?
          `${sub_pano.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype.toString() === "16" && sub_transformadores2D.byId[subInstallationId] !== undefined ?
          `${sub_transformadores2D.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype.toString() === "17" && sub_transformadores3D.byId[subInstallationId] !== undefined ?
          `${sub_transformadores3D.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype.toString() === "20" && sub_bancoscondensadores.byId[subInstallationId] !== undefined ?
          `${sub_bancoscondensadores.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype.toString() === "21" && sub_sistcomper.byId[subInstallationId] !== undefined ?
          `${sub_sistcomper.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype.toString() === "25" && sub_compensadoresactivos.byId[subInstallationId] !== undefined ?
          `${sub_compensadoresactivos.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype.toString() === "22" && sub_condensadoresserie.byId[subInstallationId] !== undefined ?
          `${sub_condensadoresserie.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype.toString() === "23" && sub_condensadoressincronos.byId[subInstallationId] !== undefined ?
          `${sub_condensadoressincronos.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype.toString() === "24" && sub_reactores.byId[subInstallationId] !== undefined ?
          `${sub_reactores.byId[subInstallationId].nombre}` :                        
          ""
          */
          item.installation_type === 'substation' && item.installation_subtype === 11 && sub_barra.byId[subInstallationId] !== undefined ?
          `${sub_barra.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype === 13 && sub_pano.byId[subInstallationId] !== undefined ?
          `${sub_pano.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype === 16 && sub_transformadores2D.byId[subInstallationId] !== undefined ?
          `${sub_transformadores2D.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype === 17 && sub_transformadores3D.byId[subInstallationId] !== undefined ?
          `${sub_transformadores3D.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype === 20 && sub_bancoscondensadores.byId[subInstallationId] !== undefined ?
          `${sub_bancoscondensadores.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype === 21 && sub_sistcomper.byId[subInstallationId] !== undefined ?
          `${sub_sistcomper.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype === 25 && sub_compensadoresactivos.byId[subInstallationId] !== undefined ?
          `${sub_compensadoresactivos.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype === 22 && sub_condensadoresserie.byId[subInstallationId] !== undefined ?
          `${sub_condensadoresserie.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype === 23 && sub_condensadoressincronos.byId[subInstallationId] !== undefined ?
          `${sub_condensadoressincronos.byId[subInstallationId].nombre}` :
          item.installation_type === 'substation' && item.installation_subtype === 24 && sub_reactores.byId[subInstallationId] !== undefined ?
          `${sub_reactores.byId[subInstallationId].nombre}` :                        
          0
         
         
         
         

        ) 
    }).join(', ')

    const { file_letter = {} } = item
    const { file_description = {} } = item

    const text = (
      <div>
        <Row style={{display:'flex', flexFlow:'row wrap'}}>
          <Col span={6}>
            <p className='content-padding work-description'>
              <b>Descripción de los trabajos</b><br/>
              {item.work_description}
            </p>
          </Col>
          <Col span={3}>
            <p className='content-padding'>
              <b>Nivel de Riesgo</b><br/>
              {item.risk}
            </p>
          </Col>
          
          <Col span={3}>
            <p className='content-padding'>
              <b>NUP</b><br/>
              {item.nup}
            </p>
          </Col>          
          {
            item.maintenance_information && item.maintenance_information.consumption_type ? (
              <>
                <Col span={3}>
                  <p className='content-padding'>
                    <b>Consumos Afectados</b><br/>
                    {
                      item.maintenance_information.consumption_type
                    }
                  </p>
                </Col>
                <Col span={6}>
                  <p className='content-padding affected-dealers'>
                    <b>Distribuidores Afectados</b><br/>
                    { 
                      item.maintenance_information.affected_dealers.map(element => {
                        return `${element.company.name} - Pérdida ${element.power} MW - Región de ${element.region.name}\n`
                      })
                    }
                  </p>
                </Col>
              </>
            ) : null
          }
          {
            item.additional_comments ? 
              <Col span={5}>
                <p className='content-padding additional-comments'>
                  <b>Comentarios Adicionales</b><br/>
                  {item.additional_comments}
                </p>
              </Col> : ''
          } 
          <Col span={6} style={{minWidth: 'auto', flexGrow:'1'}} className='content-padding'> 
           {
              ['Aprobado'].includes(currentStatus) && (
                <AccessControl
                userType={user.userType}
                allowedUserTypes={['superadmin', 'coordinado']}>
                    <p><b style={{color:'#4caf50'}}>Motivo Aprobación</b>: {item.rejection_reason}</p>
                </AccessControl>
              )
            } 
            {
              ['Rechazado'].includes(currentStatus) && (
                <AccessControl
                userType={user.userType}
                allowedUserTypes={['superadmin', 'coordinado']}>
                    <p><b style={{color:'#d32f2f'}}>Motivo Rechazo</b>: {item.rejection_reason}</p>
                </AccessControl>
              )
            }
          </Col> 
        </Row>
        <Row>
          <Col span={12}>
            {file_letter && (
              <Button
                onClick={() => handleDownload(file_letter.uid, file_letter.type)}
                type="link"
                size="small"
              >
                <a href='#top'><Icon type='download' />Descargar Carta Adjunta</a>
              </Button>
            )}
            {file_description && (
              <Button
                onClick={() => handleDownload(file_description.uid, file_description.type)}
                type="link"
                size="small"
              >
                <a href='#top'><Icon type='download' />Descargar Excel Adjunto</a>
              </Button>
            )}
          </Col>
        </Row>
      </div>
    )
    const neomanteLink = item.neomante_correlative ? `Ver SD ${item.neomante_correlative}` : "Ver en Neomante"

    const ButtonWithPopOver = ({children, helpText, ...props}) => {
      const visible = props.disabled? {} : {visible: false} 
      return (
        <Popover
          {...visible}
          placement="topRight"
          content={helpText}>
          <Button {...props}>
            {children}
          </Button>
        </Popover>
      )
    }

    const isItemFromProfile = function(){
      switch (user.userType) {
        case 'coordinador':
          return false
        case 'superadmin':
          return true
        case 'coordinado':
          const currentProfile = user.profiles.find(p => p.id === user.currentProfile)
          if (currentProfile === undefined)
            return false
          return item.company.id === currentProfile.companyId
        default:
          return false
      }  
    } ()
    let disableModifyButton = false
    let modifyHelpText = ''
    const itemNotFromProfileText = (
      <>
        La solicitud no corresponde a la empresa del perfil actual. <br/>
        Cambia de perfil para habilitar la acción.
      </>
    )
    if(!isItemFromProfile){
      disableModifyButton = true
      modifyHelpText = itemNotFromProfileText
    }
    if(!programs[selectedProgram].edition_enabled){
      disableModifyButton = true
      modifyHelpText = 'El administrador ha deshabilitado la modificación de solicitudes.'
    }

    return ( 
      <List.Item
        key={item.id}
        className={currentStatus}>
        <Joyride
          run={this.state.isTourRunning}
          continuous={true}
          showSkipButton={true}
          showProgress={true}
          steps={this.state.tourSteps}
          locale={{
            back: "Atrás",
            close: "Cerrar",
            last: "Finalizar",
            next: "Siguiente",
            skip: "Terminar tour"
          }}
          styles={{
            options: {
              primaryColor: "#3ca7ad"
            },
          }}
          callback={(tourState) => {
            if(tourState.action === "reset")
              this.setState({isTourRunning: false})
          }}
        />
        <Card className='work-request-card'>
          <Row className='work-request-card-header'>
            { selectedProgramTab === 'historic' ? null :
              <AccessControl
                userType={user.userType}
                allowedUserTypes={['superadmin']}>
              { 
                currentStatus === 'Pendiente' && (
                  <Col
                    id={this.elementId('check-box')}
                    span={1}>
                    <Checkbox
                      checked={checked}
                      onChange={() => changeWorkRequestSelection(item.id)}
                      style={{padding:'10px 0px'}}/>
                  </Col>
                )
              }
            </AccessControl>
            }
            <Col span={2} className='status-section'>
              <span className={currentStatus}>
                { currentStatus }
              </span>
              {
                currentNeomanteStatus 
                ? <span className={currentStatus}>
                   | { `Neomante: ${capitalize(currentNeomanteStatus.name)}` }
                  </span>
                : null
              }        
            </Col>
            <Col span={2} className='ID-section'>
              <p className='content-padding'><b>Id</b> { item.correlative || 'Sin Correlativo' } </p>
            </Col>
            <Col span={2} className='D-I'>
            {
              item.id_neomante ? (
              <p className='content-padding'><a href={neomanteUrl} target="_blank" rel="noopener noreferrer">{neomanteLink}</a></p>
              ) : ''
             }
            </Col>
            <Col span={6} className='empresa'>
             <p className='content-padding'><b>Empresa</b> {item.company ? item.company.name : 'Sin Información'} </p>
            </Col>
            <Col span={5}>
              <p className='content-padding'><b>{ item.installation_type === 'central' ? 'Central ' : item.installation_type === 'line' ? 'Línea ' : 'Subestación ' }</b>{ installation_string }</p>
            </Col>
            <Col span={2}>
              <p className='content-padding'>
                <b>{item.duration} día(s) - </b>
                <b>{item.postponable ? 'Postergable' : 'Impostergable'}</b>
              </p>
            </Col>
            {/*5944*/}
            {
              shouldShowModifications
              ?(
                <Col span={2}>
                  <p className='content-padding'>
                    <b>{duracion_new} día(s) nueva duración día(s) - </b>
                    <b>{postponable_new ? 'Postergable' : 'Impostergable'}</b>
                  </p>
                </Col>      
              ):null
            }      
            <Popconfirm
              trigger="hover"
              placement="bottomLeft"
              icon={<Icon type="question-circle" theme="filled"/>}
              title={"¿Deseas comenzar un tour de ayuda en este elemento?"}
              onConfirm={this.showHelpTour}
              okText="Sí"
              cancelText="No">
              <Button
                className={"help-button"}
                type="secondary"
                style={{
                  position: 'absolute',
                  top: '2px',
                  right: 0,
                  verticalAlign: 'middle'
                }}>
                <Icon type="question-circle"/>
              </Button>
            </Popconfirm>
          </Row>
          <Row className='work-request-card-content'>
            <Col span={3}>
              <p className='content-padding'>
                <b>Inicio Programado </b><br></br>
                { programmed_start_date || ''}
              </p>
            </Col>
            <Col span={3}>
              <p className='content-padding'>
                <b>Fin Programado </b><br></br>
                { programmed_end_date || '' }
              </p>
            </Col>
            { 
              selectedProgram === 'preliminary' && (
                <>
                  <Col span={3}>
                    <p className='content-padding'>
                      <b>Inicio Alternativo </b><br/>
                      { alternative_start_date }
                    </p>
                  </Col>
                  <Col span={3}>
                    <p className='content-padding'>
                      <b>Fin Alternativo </b><br/>
                      { alternative_end_date }
                    </p>
                  </Col>
                </>
              )
            }
            { 
              selectedProgram !== 'preliminary' && (
                <>
                  <Col span={3}>
                    <p className='content-padding'>
                      <b>Inicio Efectivo </b><br/>
                      { real_start_date }
                    </p>
                  </Col>
                  <Col span={3}>
                    <p className='content-padding'>
                      <b>Fin Efectivo </b><br/>
                      { real_end_date }
                    </p>
                  </Col>
                </>
              )
            }
            <Col span={3}>
              <p className='content-padding'><b>{  item.installation_type === 'central' ? 'Central' : item.installation_type === 'line' ? 'Línea ' : 'Subestación ' }</b>
                <Paragraph ellipsis={{ rows: 2, expandable: true }}>{ installation_string }</Paragraph>
              </p>
            </Col>

            <Col span={3}>
              <p className='content-padding'><b>{  item.installation_type === 'central' ? 'Unidad/es ' : item.installation_type === 'line' ? 'Tramo/s ' : 
              /*
              item.installation_type === 'substation' && item.installation_subtype.toString() === "11" ? 'Secciones de barra' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "13" ? 'Paños' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "16" ? 'Transformadores 2D' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "17" ? 'Transformadores 3D' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "20" ? 'Bancos Condensadores' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "21" ? 'Sistemas de Compensación Estática de Reactivos' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "25" ? 'Compensadores Activos' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "22" ? 'Condensadores Serie' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "23" ? 'Condensadores Síncronos' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "24" ? 'Reactores' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "99" ? 'Scada' :              
              item.installation_type === 'substation' && item.installation_subtype.toString() === "33" ? 'Medidores de facturación' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "91" ? 'Grupo de Emergencia' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "92" ? 'Batería' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "93" ? 'Tendido de Cable' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "94" ? 'Cargadores' :
              item.installation_type === 'substation' && item.installation_subtype.toString() === "95" ? 'Telecomunicación' :              
              */
              item.installation_type === 'substation' && item.installation_subtype === 11 ? 'Secciones de barra' :
              item.installation_type === 'substation' && item.installation_subtype === 13 ? 'Paños' :
              item.installation_type === 'substation' && item.installation_subtype === 16 ? 'Transformadores 2D' :
              item.installation_type === 'substation' && item.installation_subtype === 17 ? 'Transformadores 3D' :
              item.installation_type === 'substation' && item.installation_subtype === 20 ? 'Bancos Condensadores' :
              item.installation_type === 'substation' && item.installation_subtype === 21 ? 'Sistemas de Compensación Estática de Reactivos' :
              item.installation_type === 'substation' && item.installation_subtype === 25 ? 'Compensadores Activos' :
              item.installation_type === 'substation' && item.installation_subtype === 22 ? 'Condensadores Serie' :
              item.installation_type === 'substation' && item.installation_subtype === 23 ? 'Condensadores Síncronos' :
              item.installation_type === 'substation' && item.installation_subtype === 24 ? 'Reactores' :
              item.installation_type === 'substation' && item.installation_subtype === 99 ? 'Scada' :              
              item.installation_type === 'substation' && item.installation_subtype === 33 ? 'Medidores de facturación' :
              item.installation_type === 'substation' && item.installation_subtype === 91 ? 'Grupo de Emergencia' :
              item.installation_type === 'substation' && item.installation_subtype === 92 ? 'Batería' :
              item.installation_type === 'substation' && item.installation_subtype === 93 ? 'Tendido de Cable' :
              item.installation_type === 'substation' && item.installation_subtype === 94 ? 'Cargadores' :
              item.installation_type === 'substation' && item.installation_subtype === 95 ? 'Telecomunicación' :              

              'Otro' }</b>
             
                <Paragraph ellipsis={{ rows: 2, expandable: true }}>{ sub_installation_string }</Paragraph>
              </p>
            </Col>

            {
              shouldShowModifications
              ?
              (
                <Col span={3} className='modifications'>
                  <Badge count={rqModifications.length}>
                    <ButtonWithPopOver
                      disabled={!isItemFromProfile}
                      helpText={itemNotFromProfileText}
                      type="secondary"
                      onClick={this.handleModificationModal}>
                      <Icon type="info-circle"/> Modificaciones
                    </ButtonWithPopOver>
                  </Badge>
                </Col>
              )
              : null             
            }    
          </Row>
          <Row className='work-request-card-buttons'>
            <Col span={24} className='view-details'>
            <Col span={12} className='buttons'>
              {
                this.canConfirmWorks(item, currentStatus) && selectedProgramTab === 'current'
                ? (
                  <PopConfirmation item={item} confirmation={confirmation}/>
                ) : null
              }
              {
                currentStatus === 'Pendiente' && selectedProgramTab !== 'historic' && (
                  <AccessControl
                  userType={user.userType}
                  allowedUserTypes={['superadmin']}>
                      <Button
                        id={this.elementId('approve-button')}
                        type='primary'
                        className='green-button'
                        onClick={() => {this.aprovalAction(true, item)}}
                      >
                        Aprobar
                      </Button>
                      <Button
                        id={this.elementId('reject-button')}
                        type='primary'
                        className='red-button'
                        onClick={() => {this.aprovalAction(false, item)}}
                      >
                        Rechazar
                      </Button>
                  </AccessControl>
                )
              } 
              {
                currentStatus !== 'Rechazado' && selectedProgramTab !== 'historic' && (
                  <>
                    <AccessControl
                      userType={user.userType}
                      allowedUserTypes={['coordinado']}>
                      <ButtonWithPopOver
                        helpText={modifyHelpText}
                        disabled={disableModifyButton}
                        id={this.elementId('ask-modify')}
                        type='secondary'
                        onClick={() => {this.handleEditRequestModal(item)}}
                      >
                        Solicitar Modificación
                      </ButtonWithPopOver>
                      <ButtonWithPopOver
                        disabled={!isItemFromProfile}
                        helpText={itemNotFromProfileText}
                        id={this.elementId('ask-remove')} 
                        type='secondary' 
                        className='red-button'
                        onClick={() => {this.deleteAction(item)}}>
                        Solicitar Eliminación
                      </ButtonWithPopOver>
                    </AccessControl>
                    <AccessControl
                      userType={user.userType}
                      allowedUserTypes={['superadmin']}>
                      <ButtonWithPopOver
                        helpText={modifyHelpText}
                        disabled={disableModifyButton}
                        id={this.elementId('modify-button')}
                        type='secondary'
                        onClick={() => {this.handleEditRequestModal(item)}}
                      >
                        Modificar
                      </ButtonWithPopOver>
                    </AccessControl> 
                  </>
                )
              }
              {
                selectedProgramTab !== 'historic' && (
                  <AccessControl
                    userType={user.userType}
                    allowedUserTypes={['superadmin']}>
                      <Button
                        id={this.elementId('remove-button')}
                        type='secondary' 
                        className='red-button'
                        onClick={() => {this.deleteAction(item)}}
                      >
                        Eliminar
                      </Button>
                    </AccessControl>
                )
              }
            </Col>
              <Collapse bordered={false} defaultActiveKey={['1']} onChange={this.handleExpandPanel}>
                <Panel
                  header={
                    <span id={this.elementId('detail')}>
                      Ver {this.state.expanded ? 'menos' : 'más'} detalles
                    </span>}
                  key='0'>
                  {text}
                </Panel>
              </Collapse>
            </Col>

          </Row>
          { 
          shouldShowModifications 
          ? <ModificationConfirmModal
            visible={this.state.showModal}
            handleCancel={this.handleCancelModal}
            work_request={item}
            modifications={rqModifications}
          />
          : ''
          }
        </Card>
      </List.Item>
    )
  }
}


const mapStateToProps = (state, ownProps) => {
  //const {central, unit, section, line, substation, sub_barra, sub_pano, workRequests} = state.entities
  const {central, unit, section, line, substation, sub_barra, sub_pano, sub_transformadores2D, sub_transformadores3D,sub_bancoscondensadores,sub_sistcomper,sub_compensadoresactivos,sub_condensadoresserie,sub_condensadoressincronos,sub_reactores, workRequests} = state.entities
  return {
    central,
    unit,
    line,
    section,
    substation,
    sub_barra,
    sub_pano,
    sub_transformadores2D, 
    sub_transformadores3D,
    sub_bancoscondensadores,
    sub_sistcomper,
    sub_compensadoresactivos,
    sub_condensadoresserie,
    sub_condensadoressincronos,
    sub_reactores,  
    user: state.user,
    modifications: state.entities.requestModification,
    checked: workRequests.selectedById[ownProps.item.id],
    selectedProgram: state.entities.programs.selectedProgram,
    programs: state.entities.programs

  }
}
/*
const mapStateToProps = (state, ownProps) => {
  const {central, unit, section, line, workRequests} = state.entities
  return {
    central,
    unit,
    line,
    section,
    user: state.user,
    modifications: state.entities.requestModification,
    checked: workRequests.selectedById[ownProps.item.id],
    selectedProgram: state.entities.programs.selectedProgram,
    programs: state.entities.programs
  }
}
*/

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openAddRequestModal,
  openDeleteRequestModal,
  openAprovalRequestModal,
  changeWorkRequestSelection,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WorkRequestListItem)
