import React from 'react'
import moment from 'moment'
import { Popconfirm, Button, Popover, Typography } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reqConfirmWorks } from '../../store/actions/workRequests'
import { showNotification } from '../../utils/notification'

const { Paragraph } = Typography

class PopConfirmation extends React.Component{
  
  handleConfirm = ({id, confirmed_coordinate, confirmed_coordinator}) => {
    const user = this.props.user
    const isCoordinate = user.userType === 'coordinado' || user.userType === 'superadmin'
    const isCoordinator = user.userType === 'superadmin'
    if ( (isCoordinate && !confirmed_coordinate ) || (isCoordinator && !confirmed_coordinator) ) {
      this.props.reqConfirmWorks(id, {
        coordinate: isCoordinate,
        coordinator: isCoordinator
      })
    } else {
      showNotification(
        'Ha Ocurrido un Error',
        'No tiene permisos para realizar esta operación',
        'error'
      )
    }
  }

  canConfirm = ({correlative, programmed_start_date, confirmed_coordinate}) => {
    const user = this.props.user
    const today = moment()
    const workStartDate = moment(programmed_start_date)
    /* El usuario es coordinado y no ha confirmado y el plazo de inicio es de más allá de un horizonte de 3 días */
    return !(user.userType === 'coordinado' && !confirmed_coordinate && workStartDate.diff(today, 'days') + 1 < 3 )
  }

  isFromRightCompany = (item) => {
    const {user} = this.props
    /* 
      Si es superd, puede confirmar cualquier solicitud 
      Si es coordinado SOLO puede ver.
    */
    if (user.userType === 'superadmin') {
      return true
    }
    if (user.userType === 'coordinador') {
      return false
    }
    const currentProfile = user.profiles.find(element => element.id === user.currentProfile)

    if (!currentProfile || currentProfile === undefined || !currentProfile.companyId ) {
      return false
    }

    const companyId = [currentProfile.companyId, ...currentProfile.representedCompanies]
    return companyId.includes(item.company.id)
  }

  renderButton = (isDisabled) => (
    <Button
      type='primary'
      className='green-button'
      disabled={isDisabled}
    >
      Confirmar trabajos
    </Button>
  )

  render() {
    const { item } = this.props
    const { title, content } = this.props.confirmation
    const canConfirm = this.canConfirm(item)
    const isFromRightCompany = this.isFromRightCompany(item)
    const titleCantConfirm = 'No es posible realizar la confirmación'
    let contentCantConfirm = ''
    if(canConfirm) {
      contentCantConfirm = (<Paragraph>
        Se encuentra <b>fuera de plazo</b> (3 días previo al inicio de los trabajos), <br/>
        para realizar la confirmación. Comuníquese con el Coordinador<br/>
        para confirmar.
      </Paragraph>)
    }
    if(!isFromRightCompany) {
      contentCantConfirm = (<Paragraph>
        El perfil activo no corresponde a <b>{item.company.name}</b><br/>
        Modifique su perfil por uno habilitado para actuar en representación de la empresa.
      </Paragraph>)
    }

    
    return (
      <React.Fragment>
      {
        canConfirm && isFromRightCompany
        ? (
          <Popconfirm
            title='¿Está aseguro de confirmar los trabajos?'
            okText='Sí, Estoy Seguro'
            cancelText='No, Cancelar'
            onConfirm={() => {
              this.handleConfirm(item)
            }}
          >
            <Popover placement="top" title={title} content={content} trigger='hover' children={this.renderButton(false)} />
          </Popconfirm>
        )
        : (
         <Popover placement="top" title={titleCantConfirm} content={contentCantConfirm} children={this.renderButton(true)} />
        )
      }
      </React.Fragment>
    )
  }

}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    reqConfirmWorks,
  }, dispatch)

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps, mapDispatchToProps)(PopConfirmation)
