import React from 'react'
import { Select } from 'antd'
import { selectSearch } from '../../../utils/misc'

const { Option } = Select

const Component = (props) => {
  const { value, onChange, companies, width, disabled, handler } = props
  return (
    <Select
      value={value}
      onChange={(value) => {
        if (handler) {
          handler(value)
        }
        onChange(value)
      }}
      disabled={disabled || false}
      style={{ width: width || '100%' }}
      showSearch
      filterOption={selectSearch}
    >
      {
        companies.allIds.map(choiceId => (
          <Option
            key={`company-${choiceId}`}
            value={choiceId}
          >
            {
              `ID Infotécnica: ${companies.byId[choiceId].id_infotecnica || 'SIN ID INFOTECNICA'}: ${companies.byId[choiceId].name} (RUT: ${companies.byId[choiceId].rut})`
            }
          </Option>
        ))
      }
    </Select>
  )
}

export default Component
