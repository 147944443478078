import React from 'react'
import {
  Col,
  Row,
  Icon,
  Table,
  Select,
  Button,
  InputNumber
} from 'antd'
import './AffectedConsumptionInput.css' 

const { Option } = Select

const initialState = {
  affected_dealers: [],
  company: null,
  region: null,
  power: 0,
  key: 0
}

class Component extends React.Component  {
  constructor(props){
    super(props)
    this.state = { ...initialState}
  }

  componentDidMount = () => {
    if (this.props.value && this.props.value.length > 0) {
      this.setState({
        affected_dealers: this.props.value
      })
    }
  }

  handleAdd = () => {
    const { company, power, region, key } = this.state
    if (company == null || region == null) { return }
    const next_key = key + 1
    const newEntry = {
      company: {
        id: this.props.company.byId[company].id,
        name: this.props.company.byId[company].nombre
      },
      region: {
        id: this.props.region.byId[region].id,
        name: this.props.region.byId[region].name
      },
      power,
      key
    }
    let currentPowerLost = [...this.state.affected_dealers]
    currentPowerLost.push(newEntry)
    this.setState({
      key: next_key,
      affected_dealers: currentPowerLost
    })
    this.props.onChange(currentPowerLost)
  }

  handleRemove = (record) => {
    const dataSource = [...this.state.affected_dealers]
    const newDataSource = dataSource.filter(
      item => item.key !== record.key
    )
    this.setState({
      affected_dealers: newDataSource
    })
    this.props.onChange(newDataSource)
  }

  handleCompany = (value) => {
    this.setState({
      company: value
    })
  }

  handlePower = (value) => {
    this.setState({
      power: value
    })
  }

  handleRegion = (value) => {
    this.setState({
      region: value
    })
  }

  render() {
    const {company, region} = this.props
    const dataSource = [
      ...this.state.affected_dealers
    ]
    const columns = [
      {
        title: 'Empresa',
        dataIndex: 'company.name',
        key: 'company',
      },
      {
        title: 'Pérdida Estimada (MW)',
        dataIndex: 'power',
        key: 'power',
      },
      {
        title: 'Región',
        dataIndex: 'region.name',
        key: 'region',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
          dataSource.length >= 1 ? (
            <Button 
              onClick={() => this.handleRemove(record)}
              className='ant-btn-secondary'
            >
              <Icon type="delete"/>
              Eliminar
            </Button>
          ) : null
        )
      }
    ]
    const companyChoices = company.allIds.map(companyId => {
      return <Option key={`${companyId}-affected-company`} value={companyId}>{company.byId[companyId].nombre}</Option>
    })
    const regionChoices = region.allIds.map(regionId => {
      return <Option key={`${regionId}-affected-region`} value={regionId}>{region.byId[regionId].name}</Option>
    }) 
    return (
      <>
        <Row gutter={[32, 8]} className='affected-consumption'>
          <Col span={7}>
            <label>Empresa Afectada:</label>
            <Select 
              // value={this.state.company}
              onChange={this.handleCompany}
            >
              { companyChoices }
            </Select>
          </Col>
          <Col span={7}>
            <label>Pérdida Estimada de Potencia (MW):</label>
            <InputNumber
              min={0}
              defaultValue={0}
              style={{width:'100%'}}
              // value={this.state.power}
              onChange={this.handlePower}
            >
            </InputNumber>
          </Col>
          <Col span={7}>
            <label>Región:</label> 
            <Select 
              // value={this.state.region}
              onChange={this.handleRegion}
            >
              { regionChoices }
            </Select>
          </Col>
          <Col span={3} className='add-company'>
            <Button 
              type="primary" 
              onClick={() => this.handleAdd()}
            >
              <Icon type="plus"/>
              Añadir
            </Button> 
          </Col>
        </Row>
        {
          this.state.affected_dealers.length > 0 ? (
            <Row>
              <Table className='affected-column' columns={columns} dataSource={this.props.value}/>
            </Row>
          ) : null
        }
      </>
    )
  }
}

export default Component
