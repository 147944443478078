import React from 'react'
import {Form, Col} from 'antd'

const { Item } = Form



const FormItem = (props) => {
 const {id, span, label, extra, decorator, formId, args} = props
 return (
  <Col id={id} span={span}>
    <Item label={label} extra={extra}>
      {decorator(formId, {...args})(props.children)}
    </Item>
  </Col>
 )
}

export default FormItem
