import React from 'react'
import { Select } from 'antd'
import { selectSearch } from '../../../utils/misc'

const { Option } = Select

const Component = (props) => {
  const { value, onChange, statuses, width } = props
  return (
    <Select
      mode='multiple'
      showSearch
      value={value}
      onChange={
        (value) => onChange(value)
      }
      filterOption={selectSearch}
      style={{ width: width || '100%' }}
    >
      {
        statuses.allIds.map(choiceId => (
          <Option
            key={`risk-${choiceId}`}
            value={choiceId}
          >
            {statuses.byId[choiceId].name}
          </Option>
        ))
      }
    </Select>
  )
}

export default Component
