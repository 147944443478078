import axios from 'axios'
import React from 'react'
import { Upload, Button, Icon } from 'antd'
import { showNotification } from '../../utils/notification'
import client from '../../store/api/apiClient'
import { tokenConfig } from '../../store/api'
import i18n from '../../utils/i18n'


const defaultProps = {
  title: 'Upload File',
  listType: 'picture',
  multiple: false,
  method: 'PUT',
  action: async ({ uid, type }) => {
    const token = localStorage.getItem('token')
    const params = {
      name: uid,
      type,
      method: 'PUT'
    }
    const res = await client().post(
      '/upload-file/signed-url/',
      params,
      tokenConfig(token)
    )

    if (res.data && res.data.url) {
      return res.data.url
    }

    showNotification(
      i18n.t('error.uploadFile.createSignedUrl.title'),
      i18n.t('error.uploadFile.createSignedUrl.description'),
      'error'
    )
  },
  customRequest: ({ file, onSuccess, onProgress, onError, action, method }) => {
    const { type } = file
    const config = {
      headers: {
        "Content-Type": type
      },
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        onProgress({percent: percentCompleted}, file)
      }
    }
    axios.put(action,
      file,
      config
    )
    .then(res => onSuccess('done', file))
    .catch(err => onError(err))
  },
  onDownload: async ({ uid, type }) => {
    const token = localStorage.getItem('token')
    const params = {
      name: uid,
      type,
      method: 'GET'
    }
    const res = await client().post(
      '/download-file/signed-url/',
      params,
      tokenConfig(token)
    )

    if (res.data && res.data.url) {
      window.open(res.data.url, 'Download')
      return
    }

    showNotification(
      i18n.t('error.uploadFile.createSignedUrl.title'),
      i18n.t('error.uploadFile.createSignedUrl.description'),
      'error'
    )
  }
}

class Component extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      ...defaultProps,
      signedUrl: null
    }
  }

  handleUpload = () => {
    console.log('lala')
    this.setState({
      uploading: false,
    });
  }

  handleOnChange = (multiple, onChange) => ({ file, fileList }) => {
    let res = null
    if (multiple) {
      res = fileList.map(({ originFileObj = {} }) => {
        const { uid, name, size, type } = originFileObj
        return { uid, name, size, type }
      })
    } else {
      const { originFileObj: { uid, name, size, type } } = file
      res = { uid, name, size, type }
    }
    onChange(res)
  }

  render () {
    const { uploaderName, width, onChange } = this.props
    const uprops = { ...defaultProps, ...this.props }
    const { multiple } = uprops
    uprops.onChange = this.handleOnChange(multiple, onChange)
    return (
      <Upload { ...uprops }>
        <Button style={{width: width || '100%'}}>
          <Icon type="upload"/> {uploaderName || 'Click para subir'}
        </Button>
      </Upload>
    )
  }
}

export default Component
