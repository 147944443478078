import React from 'react'
import {
  Row,
  Form
} from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormItem from '../../components/FormItem'
import TextInput from '../../components/AddRequestItem/TextInput'
import RiskSelector from '../../components/AddRequestItem/RiskSelector'
import TypeSelector from '../../components/AddRequestItem/TypeSelector'
import PostponableInput from '../../components/AddRequestItem/PostponableInput'
import DateRangeSelector from '../../components/AddRequestItem/DateRangeSelector'
import InstallationSelector from '../../components/AddRequestItem/InstallationSelector'
//import { hasInitialValueMassive as hasInitialValue, central, line, substation } from '../../utils/workRequestUtils'
import { hasInitialValueMassive as hasInitialValue, central, line, substation_sub_barra, substation_sub_pano, substation_sub_transformadores2D, substation_sub_transformadores3D, substation_sub_bancoscondensadores, substation_sub_sistcomper, substation_sub_compensadoresactivos, substation_sub_condensadoresserie, substation_sub_condensadoressincronos, substation_sub_reactores, substation_sub_medfacturacion
} from '../../utils/workRequestUtils'
import { filterSubInstallations, filterProfileInstallations } from '../../utils/misc'
import ElementsSubFilter from '../../components/AddRequestMassiveItem/ElementsSubFilter'
import NumeroNUP from '../../components/AddRequestMassiveItem/TextInputSimple'
import './AddRequestMassiveForm.css'

const initialState = {

  installation_type: 'central',
  consumption_type: 'none',
  postponable: false,
  installation: null, // Installation ID
  installation_subtype: '',
  initial: true,
  editedInstallationType: false,
  editedInstallationId: false,
  parentCompanyId: null // company from parent component
}

const gutter = [32]

class AddRequestMassiveForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {...initialState}
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if(prevState.initial){
      const { item } = nextProps
      //const typeInst = ['central', 'linea'].includes(item.tipo_instalacion.toLowerCase().replace('í', 'i')) ? (item.tipo_instalacion.toLowerCase() === 'central' ? 'central' : 'line') : null
      const typeInst = ['central', 'linea', 'subestacion'].includes(item.tipo_instalacion.toLowerCase().replace('í', 'i')) ? (item.tipo_instalacion.toLowerCase() === 'central' ? 'central' : item.tipo_instalacion.toLowerCase() === 'linea' ? 'line' : 'substation') : null

      console.log('************tipoA************')
      console.log(typeInst)

      const installation = parseInt(item.instalacion) || null
      let postponable = false
      if (item.postergable) {
        postponable = item.postergable.toLowerCase().replace('í', 'i') === "si"
      }

      return {
        parentCompanyId: nextProps.company,
        installation_type: typeInst,
        installation: installation,
        postponable,
        initial: false
      }
    }
    if (prevState.parentCompanyId !== nextProps.company){
      // Another company was selected, reset installation
      return {
        parentCompanyId: nextProps.company,
        installation: null
      }
    }
    return null
  }

  handleInstallationType = (value) => {
    const { index } = this.props
    this.setState({
      installation_type: value,
      installation: null, // Reset installation
      editedInstallationType: true
    })
    this.props.form.resetFields([`${index}_installation`, `${index}_sub_installation`])
  }

  handleInstallation = (value) => {
    const { index } = this.props
    this.setState({
      installation: value,
      editedInstallationId: true
    })
    this.props.form.resetFields([`${index}_sub_installation`])
  }

  handleInstallationSUB = (value) => {
    const { index } = this.props
    this.setState({
     installation_subtype: value
   })    
   this.props.form.resetFields([`${index}_sub_installation`])
 }  




  handlePostponable = (value) => {
    this.setState({
      postponable: value
    })
  }

  handleNup = (value) => {
    this.setState({
      nup: value
    })
  }

  handleConsumptionType = (value) => {
    this.setState({
      consumption_type: value
    })
  }

  basicRule = (required, message) => ({
    rules: [{
      required, 
      message
    }]
  })
  
  workDescriptionRule = () => ({
    rules: [
      {required: true, whitespace: true, message: 'Describa los trabajos que contemplan el mantenimiento'},
      {max: 7000, message: 'La descripción no puede superar los 7000 caracteres'},
      {}
    ]
  })

  additionalCommentRule = () => ({
    rules: [
      {max: 7000, message: 'Comentarios adicionales no pueden superar los 7000 caracteres'},
    ]
  })

  //5942
  NupRule = () => ({
    rules: [
      {max: 5, message: 'NUP no pueden superar los 5 caracteres'},
    ]
  })

  render(){

    const { item, index, user, company, companies, editedCompanyId} = this.props
    const { installation_type, postponable: isPostponable, installation, editedInstallationId, editedInstallationType} = this.state
    const { getFieldDecorator } = this.props.form
    const installationTypeInitVal = hasInitialValue('tipo_instalacion', item).initialValue
    const installationType = editedInstallationType? installation_type : installationTypeInitVal

    
    
    const installation_subtype = hasInitialValue('tipo_subestacion', item).initialValue

     //subtype
     const subElms = this.props.sub_elements
     const subtypeinstallationInitVal = editedCompanyId || editedInstallationType? undefined : hasInitialValue('tipo_subestacion', item, subElms, []).initialValue
     //console.log('subtypeinstallationInitVal->',subtypeinstallationInitVal)    

    console.log('installation_subtype ------------------>',installation_subtype)
    const installationTypeObj = 
    installationType === 'central' ? central : 
    installationType === 'line' ? line : 
   
    installationType === 'substation' && installation_subtype === '11' ? substation_sub_barra:
    installationType === 'substation' && installation_subtype === '13' ? substation_sub_pano : 
    installationType === 'substation' && installation_subtype === '16' ? substation_sub_transformadores2D :
    installationType === 'substation' && installation_subtype === '17' ? substation_sub_transformadores3D :
    installationType === 'substation' && installation_subtype === '20' ? substation_sub_bancoscondensadores :
    installationType === 'substation' && installation_subtype === '21' ? substation_sub_sistcomper :
    installationType === 'substation' && installation_subtype === '25' ? substation_sub_compensadoresactivos :
    installationType === 'substation' && installation_subtype === '22' ? substation_sub_condensadoresserie :
    installationType === 'substation' && installation_subtype === '23' ? substation_sub_condensadoressincronos :
    installationType === 'substation' && installation_subtype === '24' ? substation_sub_reactores :
    installationType === 'substation' && installation_subtype === '33' ? substation_sub_medfacturacion :
    substation_sub_barra
    
    /*
    installationType === 'substation' && installation_subtype === 'Barras' ? substation_sub_barra:
    installationType === 'substation' && installation_subtype === 'Panos' ? substation_sub_pano : 
    installationType === 'substation' && installation_subtype === 'Transformadores 2D' ? substation_sub_transformadores2D :
    installationType === 'substation' && installation_subtype === 'Transformadores 3D' ? substation_sub_transformadores3D :
    installationType === 'substation' && installation_subtype === 'Bancos Condensadores' ? substation_sub_bancoscondensadores :
    installationType === 'substation' && installation_subtype === 'Sistemas de Compensación Estática de Reactivos' ? substation_sub_sistcomper :
    installationType === 'substation' && installation_subtype === 'Compensadores Activos' ? substation_sub_compensadoresactivos :
    installationType === 'substation' && installation_subtype === 'Condensadores Serie' ? substation_sub_condensadoresserie :
    installationType === 'substation' && installation_subtype === 'Condensadores Síncronos' ? substation_sub_condensadoressincronos :
    installationType === 'substation' && installation_subtype === 'Reactores' ? substation_sub_reactores :
    installationType === 'substation' && installation_subtype === 'Medidores de facturacion' ? substation_sub_medfacturacion :
    substation_sub_barra
    */
    
    


    const allInstallations = this.props[`${installationTypeObj.mainId}`]
    const installationChoices = filterProfileInstallations(allInstallations, user, company, companies)
    const installationInitVal = editedCompanyId || editedInstallationType? undefined : hasInitialValue('instalacion', item, installationChoices.allIds, []).initialValue
    const installationId = editedInstallationId? installation : installationInitVal

    const subInstallationChoices = filterSubInstallations(
      installationTypeObj, 
      installationId, 
      this.props[`${installationTypeObj.subId}`],
      company,
      companies,
      user
    )
    const subInstallationInitVal = editedCompanyId || editedInstallationType || editedInstallationId? undefined : hasInitialValue('sub_instalacion', item, [], subInstallationChoices.allIds).initialValue

    return (
      <Form layout='horizontal'>
        <Row gutter={gutter}>
          <FormItem 
            span={3} 
            label='Tipo de Instalación' 
            decorator={getFieldDecorator}
            formId={`${index}_installation_type`}
            args={{
              ...this.basicRule(true, 'Indique el tipo de instalación'),
              initialValue: installationTypeInitVal
            }}>
              <TypeSelector
                handler={this.handleInstallationType}
              />
          </FormItem>
          <FormItem 
            span={3}
            label={`Seleccione ${installationTypeObj.mainText}`}
            extra="Antes selecciona tipo de instalación."
            decorator={getFieldDecorator}
            formId={`${index}_installation`}
            args={{
              ...this.basicRule(true, `Selecione ${installationTypeObj.mainText}`),
              initialValue: installationInitVal
            }}>
              <InstallationSelector
                main={true}
                choices={installationChoices}
                handler={this.handleInstallation}
              />
          </FormItem>

          {

              installation_type === 'substation' ? (  
                  <FormItem 
                    id="trabajoSobre-form-item"
                    span={8}
                    label= 'Trabajo Sobre:'
                    decorator={getFieldDecorator}
                    formId={`${index}_installation_subtype`}
                    args={{
                      ...this.basicRule(false, ''),
                      initialValue: subtypeinstallationInitVal
                      }}>
                <ElementsSubFilter
                  main={true}
                  choices={subElms}
                  handler={this.handleInstallationSUB} 
                />                    
                  </FormItem>
                ) : null
            } 

          {
   
          this.state.installation !== '' && installation_subtype !== '99' && installation_subtype !== '91' && installation_subtype !== '92'  && installation_subtype !== '93'  && installation_subtype !== '94'  && installation_subtype !== '95' ? ( 
             
          <FormItem 
            span={4}
            label={`Seleccione ${installationTypeObj.subText}`}
            extra={`Antes selecciona ${installationTypeObj.mainText}.`}
            decorator={getFieldDecorator}
            formId={`${index}_sub_installation`}
            args={{
              ...this.basicRule(true, `Selecione ${installationTypeObj.subText}`),
              initialValue: subInstallationInitVal
              }}>
              <InstallationSelector
                main={false}
                choices={subInstallationChoices}
              />
          </FormItem>
          ):null
          }
          <FormItem 
            span={3}
            label='Nivel de Riesgo' 
            decorator={getFieldDecorator}
            formId={`${index}_risk`}
            args={{
              ...this.basicRule(true, 'Indique el Nivel de Riesgo Asociado a los Trabajos'),
              ...hasInitialValue('riesgo_trabajos', item)
            }}>
            <RiskSelector/>
          </FormItem>
          <FormItem 
            span={6}
            label='Fechas de Inicio y Término' 
            decorator={getFieldDecorator}
            formId={`${index}_period`}
            args={{
              ...this.basicRule(true, 'Seleccione Fechas de Inicio y Término del Mantenimiento'),
              ...hasInitialValue('fecha_inicio', item)
            }}>
              <DateRangeSelector/>
            </FormItem>
            <FormItem 
              span={3}
              label='¿Es Postergable?' 
              decorator={getFieldDecorator}
              formId={`${index}_postponable`}
              args={{
                ...this.basicRule(true, 'Indique si el mantenimiento puede ser pospuesto'),
                ...hasInitialValue('postergable', item)
              }}>
              <PostponableInput
                handler={this.handlePostponable} reduced={true}
              />
            </FormItem>
        </Row>
        <Row gutter={gutter}>
            <FormItem 
              span={6}
              label='Fechas Alternativas de Inicio y Término' 
              decorator={getFieldDecorator}
              formId={`${index}_alternative_period`}
              args={{
                ...this.basicRule(isPostponable, 'Indique fechas alternativas para la realización de los trabajos'),
                ...hasInitialValue('fecha_inicio_alternativa', item)
              }}>
              <DateRangeSelector disabled={!isPostponable}/>
            </FormItem>
            <FormItem 
              span={8}
              label='Descripción de los Trabajos' 
              decorator={getFieldDecorator}
              formId={`${index}_work_description`}
              args={{
                ...this.workDescriptionRule(),
                ...hasInitialValue('descripcion_trabajo', item)
              }}>
              <TextInput/>
                            
            </FormItem>
            <FormItem 
              span={8}
              label='Comentarios Adicionales' 
              decorator={getFieldDecorator}
              formId={`${index}_optional_comments`}
              args={{
                ...this.additionalCommentRule(),
                ...hasInitialValue('comentarios_adicionales', item)
              }}>
              <TextInput/>
            </FormItem>


        </Row>

        <Row gutter={gutter}>
           
            <FormItem 
              span={5}
              label='NUP' 
              decorator={getFieldDecorator}
              formId={`${index}_nup`}
              args={{
                ...this.additionalCommentRule(),
                ...hasInitialValue('nup', item)
              }}>
              <NumeroNUP/>
            </FormItem>          
        </Row>      
      </Form>
    )
  }
}

const mapStateToProps = (state) => {
  const {entities, user} = state
  const {
    companies,
    line,
    unit,
    status,
    infotecnicaCompanies,
    central,
    section,
    sub_barra,
    sub_pano,
    substation,
    sub_transformadores2D,
    sub_transformadores3D,
    sub_bancoscondensadores,
    sub_sistcomper,
    sub_compensadoresactivos,
    sub_condensadoresserie,
    sub_condensadoressincronos,
    sub_reactores,
    sub_medfacturacion,
    programs    
    
  } = entities
 
  return {
    companies,
    line,
    unit,
    status,
    infotecnicaCompanies,
    programs,
    section,
    substation,
    sub_barra,
    sub_pano,
    sub_transformadores2D,
    sub_transformadores3D,
    sub_bancoscondensadores,
    sub_sistcomper,
    sub_compensadoresactivos,
    sub_condensadoresserie,
    sub_condensadoressincronos,
    sub_reactores,
    sub_medfacturacion,
    central,
    user
  }
  
} 

const mapDispatchToProps = (dispatch) => (
  bindActionCreators({
  }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(AddRequestMassiveForm)
