import {
  getCompanies,
  getInfotecnicaCompanies,
  getProyectos,
  updateCompany,
  getStatusOptions,
  getCentrals,
  getUnits,
  getLines,
  getSections,
  //getElements,
  getSubstationElements,
  getRegions,
  updateUserCompanyRoles,
  updateUserType,
  getRequestModification,
  getFileTemplates,
  getSubstations,
  //5941
  //getBarras,
  //getPanos,
  //
  getSub_Barras,
  getSub_Panos,
  /////////////////////////////////////////////////////////////////////////////////////////
  getSub_transformadores2D,
  getSub_transformadores3D,
  getSub_bancoscondensadores,
  getSub_sistcomper,
  getSub_compensadoresactivos,
  getSub_condensadoresserie,
  getSub_condensadoressincronos,
  getSub_reactores,
  getSub_medfacturacion,
//////////////////////////////////////////////////////////////////////////////////////////////
} from '../api/entities'
import { parseJSON } from '../../utils/misc'
import { entitiesConstants } from '../constants/entities'
import { showNotification } from '../../utils/notification'


export function fetchInfotecnicaCompanies() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_INFOTECNICA_COMPANIES
    })
    return getInfotecnicaCompanies()
      .then(response => {
        dispatch({
          type: entitiesConstants.FETCH_INFOTECNICA_COMPANIES_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        console.log('FETCH_INFOTECNICA_COMPANIES_FAILURE', error)
        dispatch({
          type: entitiesConstants.FETCH_INFOTECNICA_COMPANIES_FAILURE
        })
      })
  }
}

export function fetchProyectos() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_PROYECTO
    })
    return getProyectos()
      .then(response => {
        dispatch({
          type: entitiesConstants.FETCH_PROYECTO_SUCCESS,
          //payload: response.data
          payload: response.data.results
          
        })
      })
      .catch(error => {
        console.log('FETCH_PROYECTO_FAILURE', error)
        dispatch({
          type: entitiesConstants.FETCH_PROYECTO_FAILURE
        })
      })
  }
}

export function fetchStatusOptions() {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: entitiesConstants.FETCH_STATUS_OPTIONS
    })
    return getStatusOptions(auth.token)
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_STATUS_OPTIONS_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_STATUS_OPTIONS_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_STATUS_OPTIONS_FAILURE
      })
    })
  }
}

export function fetchCentrals() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_CENTRAL
    })
    return getCentrals()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_CENTRAL_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_CENTRAL_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_CENTRAL_FAILURE
      })
    })
  }
}

export function fetchUnits() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_UNIT
    })
    return getUnits()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_UNIT_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_UNIT_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_UNIT_FAILURE
      })
    })
  }
}

export function fetchLines() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_LINE
    })
    return getLines()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_LINE_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_LINE_OPTIONS_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_LINE_FAILURE
      })
    })
  }
}

export function fetchSections() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SECTION
    })
    return getSections()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SECTION_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SECTION_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SECTION_FAILURE
      })
    })
  }
}

//5941.i
/*
export function fetchBarras() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_BARRA
    })
    return getBarras()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_BARRA_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_BARRA_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_BARRA_FAILURE
      })
    })
  }
}
*/
/*
export function fetchPanos() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_PANO
    })
    return getPanos()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_PANO_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_PANO_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_PANO_FAILURE
      })
    })
  }
}
*/
//5941.f
export function fetchSub_Barras() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_BARRA
    })
    return getSub_Barras()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_BARRA_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_BARRAS_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_BARRA_FAILURE
      })
    })
  }
}

export function fetchSub_Panos() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_PANO
    })
    return getSub_Panos()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_PANO_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_PANO_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_PANO_FAILURE
      })
    })
  }
}

export function fetchSub_transformadores2D() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_TRANSFORMADORES2D
    })
    return getSub_transformadores2D()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_TRANSFORMADORES2D_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_TRANSFORMADORES2D_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_TRANSFORMADORES2D_FAILURE
      })
    })
  }
}

export function fetchSub_transformadores3D() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_TRANSFORMADORES3D
    })
    return getSub_transformadores3D()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_TRANSFORMADORES3D_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_TRANSFORMADORES3D_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_TRANSFORMADORES3D_FAILURE
      })
    })
  }
}

export function fetchSub_bancoscondensadores() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_BANCOSCONDENSADORES
    })
    return getSub_bancoscondensadores()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_BANCOSCONDENSADORES_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_BANCOSCONDENSADORES_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_BANCOSCONDENSADORES_FAILURE
      })
    })
  }
}

export function fetchSub_sistcomper() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_SISTCOMPER
    })
    return getSub_sistcomper()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_SISTCOMPER_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_SISTCOMPER_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_SISTCOMPER_FAILURE
      })
    })
  }
}
export function fetchSub_compensadoresactivos() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_COMPENSADORESACTIVOS
    })
    return getSub_compensadoresactivos()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_COMPENSADORESACTIVOS_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_COMPENSADORESACTIVOS_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_COMPENSADORESACTIVOS_FAILURE
      })
    })
  }
}

export function fetchSub_condensadoresserie() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_COMPENSADORESSERIE
    })
    return getSub_condensadoresserie()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_COMPENSADORESSERIE_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_COMPENSADORESSERIE_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_COMPENSADORESSERIE_FAILURE
      })
    })
  }
}

export function fetchSub_condensadoressincronos() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_COMPENSADORESSINCRONOS
    })
    return getSub_condensadoressincronos()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_COMPENSADORESSINCRONOS_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_COMPENSADORESSINCRONOS_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_COMPENSADORESSINCRONOS_FAILURE
      })
    })
  }
}

export function fetchSub_reactores() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_REACTORES
    })
    return getSub_reactores()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_REACTORES_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_REACTORES_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_REACTORES_FAILURE
      })
    })
  }
}

export function fetchSub_medfacturacion() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUB_MEDFACTURACION
    })
    return getSub_medfacturacion()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUB_MEDFACTURACION_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUB_MEDFACTURACION_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUB_MEDFACTURACION_FAILURE
      })
    })
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////



/*
export function fetchElements() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_ELEMENT
    })
    return getElements()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_ELEMENT_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_ELEMENT_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_ELEMENT_FAILURE
      })
    })
  }
}
*/
export function fetchSubstations() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_SUBSTATION
    })
    return getSubstations()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_SUBSTATION_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_SUBSTATION_OPTIONS_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_SUBSTATION_FAILURE
      })
    })
  }
}

export function fetchSubstationElements() {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({type: entitiesConstants.FETCH_SUBSTATIONELEMENTS})
    return getSubstationElements(auth.token)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: entitiesConstants.FETCH_SUBSTATIONELEMENTS_SUCCESS,
          payload: response
        })
      })
      .catch(error => {
        console.log('FETCH_SUBSTATIONELEMENTS_ERROR', error)
        dispatch({type: entitiesConstants.FETCH_SUBSTATIONELEMENTS_ERROR})
        showNotification(
          'Ha ocurrido un error',
          'No fue posible cargar el listado de elementos.',
          'error'
        )
      })
  }
}


export function fetchRegions() {
  return (dispatch) => {
    dispatch({
      type: entitiesConstants.FETCH_REGION
    })
    return getRegions()
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_REGION_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_REGION_FAILURE', error)
      dispatch({
        type: entitiesConstants.FETCH_REGION_FAILURE
      })
    })
  }
}

export function fetchRequestModification() {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: entitiesConstants.FETCH_WORK_REQUEST_MODIFICATIONS
    })
    return getRequestModification(auth.token)
    .then(parseJSON)
    .then(response => {
      dispatch({
        type: entitiesConstants.FETCH_WORK_REQUEST_MODIFICATIONS_SUCCESS,
        payload: response
      })
    })
    .catch(error => {
      console.log('FETCH_WORK_REQUEST_MODIFICATIONS_ERROR', error)
      dispatch({
        type: entitiesConstants.FETCH_WORK_REQUEST_MODIFICATIONS_ERROR
      })
    })
  }
}

export function fetchCompanies() {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({type: entitiesConstants.FETCH_COMPANIES})
    return getCompanies(auth.token)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: entitiesConstants.FETCH_COMPANIES_SUCCESS,
          payload: response
        })
      })
      .catch(error => {
        console.log('FETCH_COMPANIES_ERROR', error)
        dispatch({type: entitiesConstants.FETCH_COMPANIES_ERROR})
        showNotification(
          'Ha ocurrido un error',
          'No fue posible cargar el listado de empresas.',
          'error'
        )
      })
  }
}

export function fetchFileTemplates () {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({type: entitiesConstants.FETCH_FILE_TEMPLATES})
    return getFileTemplates(auth.token)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: entitiesConstants.FETCH_FILE_TEMPLATES_SUCCESS,
          payload: response
        })
      })
      .catch(error => {
        console.log('FETCH_FILE_TEMPLATES_ERROR', error)
        dispatch({type: entitiesConstants.FETCH_FILE_TEMPLATES_ERROR})
        showNotification(
          'Ha ocurrido un error',
          'No fue posible cargar el listado de archivos de muestra.',
          'error'
        )
      })
  }
}

export function changeCompany(company, fieldUpdates) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({type: entitiesConstants.UPDATE_COMPANY})
    return updateCompany(company, fieldUpdates, auth.token)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: entitiesConstants.UPDATE_COMPANY_SUCCESS,
          payload: response
        })
        showNotification(
          'Actualización exitosa',
          'La empresa se ha actualizado correctamente.',
          'success'
        )
      })
      .catch(error => {
        console.log('UPDATE_COMPANY_ERROR', error)
        dispatch({type: entitiesConstants.UPDATE_COMPANY_ERROR})
        showNotification(
          'Error al actualizar',
          'La empresa no ha podido ser actualizada.',
          'error'
        )
      })
  }
}

export function changeUserCompanyRoles(userId, roleChanges) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({type: entitiesConstants.CHANGE_USER_COMPANY_ROLES})
    return updateUserCompanyRoles(userId, roleChanges, auth.token)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: entitiesConstants.CHANGE_USER_COMPANY_ROLES_SUCCESS,
          payload: response 
        })
        showNotification(
          'Actualización exitosa',
          'El rol del usuario se cambiado exitosamente.',
          'success'
        )
      })
      .catch(error => {
        console.log('CHANGE_USER_COMPANY_ROLES_ERROR', error)
        dispatch({type: entitiesConstants.CHANGE_USER_COMAPNY_ROLES_ERROR})
        showNotification(
          'Error al actualizar',
          'El rol del usuario no ha podido ser actualizado.',
          'error'
        )
      })
  }
}

export function changeUserType (userId, newType) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({ type: entitiesConstants.CHANGE_USER_TYPE })
    return updateUserType(userId, newType, auth.token)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: entitiesConstants.CHANGE_USER_TYPE_SUCCESS,
          payload: response
        })
        showNotification(
          'Actualización exitosa',
          'El tipo de usuario ha sido cambiado.',
          'success'
        )
      })
      .catch(error => {
        console.log('CHANGE_USER_TYPE_ERROR', error)
        dispatch({ type: entitiesConstants.CHANGE_USER_TYPE_ERROR })
        showNotification(
          'Error al actualizar',
          'No pudo actualizarse el tipo de usuario',
          'error'
        )
      })
  }
}
