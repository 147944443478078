export const massiveConstants = {
  START_READING: 'START_READING',
  DONE_READING: 'DONE_READING',
  START_PARSING: 'START_PARSING',
  DONE_PARSING: 'DONE_PARSING',
  START_VALIDATING: 'START_VALIDATING',
  DONE_VALIDATING: 'DONE_VALIDATING',
  READING_ERROR: 'READING_ERROR',
  PARSING_ERORR: 'PARSING_ERORR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  FILL_DATASET: 'FILL_DATASET',
  UPDATE_DATASET: 'UPDATE_DATASET',
}
