import {
  pmpmConstants
} from '../constants/pmpm'

import {
  workRequestsConstants
} from '../constants/workRequests'

import {
  reqDownloadReport,
  reqStatusDownloadReport,
  reqOpenDownloadReport
} from '../api/workRequests'

import i18n from '../../utils/i18n'
import moment from 'moment'
// TODO: move the component
import { showNotification } from '../../utils/notification'

export function toggleCalendarView () {
  return {
    type: pmpmConstants.TOGGLE_CALENDAR_VIEW
  }
}

export function openAddRequestModal (workRequest) {
  return {
    type: pmpmConstants.OPEN_ADD_REQUEST_MODAL,
    payload: workRequest
  }
}

export function closeAddRequestModal () {
  return {
    type: pmpmConstants.CLOSE_ADD_REQUEST_MODAL
  }
}

export function openMassiveRequestModal (workRequest) {
  return {
    type: pmpmConstants.OPEN_MASSIVE_ADD_REQUEST_MODAL,
    payload: workRequest
  }
}

export function closeMassiveRequestModal () {
  return {
    type: pmpmConstants.CLOSE_MASSIVE_ADD_REQUEST_MOODAL
  }
}

export function openDeleteRequestModal (workRequest) {
  return {
    type: pmpmConstants.OPEN_DELETE_REQUEST_MODAL,
    payload: workRequest
  }
}

export function closeDeleteRequestModal () {
  return {
    type: pmpmConstants.CLOSE_DELETE_REQUEST_MODAL
  }
}

export function openAprovalRequestModal (aprovalAction, workRequestIdList) {
  return {
    type: pmpmConstants.OPEN_APROVAL_REQUEST_MODAL,
    payload: {
      currentRequestIdBatch: workRequestIdList,
      aprovalAction, 
    }
  }
}

export function closeAprovalRequestModal () {
  return {
    type: pmpmConstants.CLOSE_APROVAL_REQUEST_MODAL
  }
}

export function downloadFile (params) {
  let checkStatus
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: workRequestsConstants.BUTTON_DOWNLOAD_REPORT_PENDING
    })
    showNotification(
      i18n.t('pending.downloadReport.title'),
      i18n.t('pending.downloadReport.description'),
      'warning'
    )
    return reqDownloadReport(auth.token, params)
      .then(async res => {
        const { data: { file_name: fileName } } = res

        checkStatus = setInterval(async () => {
          const res = await reqStatusDownloadReport(auth.token, fileName)
          if (res.status === 200) {
            const { data: { status, url } } = res
            if (url){ console.log('') }
            if (status === 'done') {
              clearInterval(checkStatus)
              reqOpenDownloadReport(
                auth.token,
                fileName
              ).then(res => {
                const datetime = moment().format('l LT')
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `Reporte-${datetime}.xlsx`)
                document.body.appendChild(link)
                link.click()
                dispatch({ type: workRequestsConstants.BUTTON_DOWNLOAD_REPORT_DONE })
                showNotification(
                  i18n.t('success.downloadReport.title'),
                  i18n.t('success.downloadReport.description')
                )
              }).catch(err => {
                dispatch({ type: workRequestsConstants.BUTTON_DOWNLOAD_REPORT_DONE })
                showNotification(
                  i18n.t('error.downloadReport.title'),
                  i18n.t('error.downloadReport.description'),
                  'error'
                )
              })
            }
          } else {
            clearInterval(checkStatus)
            dispatch({ type: workRequestsConstants.BUTTON_DOWNLOAD_REPORT_DONE })
            showNotification(
              i18n.t('error.downloadReport.title'),
              i18n.t('error.downloadReport.description'),
              'error'
            )
          }
        }, 4 * 1000)
      })
      .catch(err => {
        console.log({ err })
      })
  }
}
