import { createReducer } from '../../utils/misc'

import {
  pmpmConstants
} from '../constants/pmpm'
import {
  programsConstants
} from '../constants/programs'
import {
  workRequestsConstants
} from '../constants/workRequests'

const initialState = {
  calendarView: false,
  fetchingPrograms: false,
  updatingPreliminaryEdition: false,
  fetchingWorkRequests: false,
  buttonDownloadReportIsLoading: false,
  addRequestModalOpen: false,
  addingWorkRequest: false,
  aprovingWorkRequest: false,
  deletingWorkRequest: false,
  deleteRequestModalOpen: false,
  aprovalRequestModalOpen: false,
  currentRequest: undefined,
  aprovalAction: false,
  addRequestMassiveModalOpen: false,
  currentRequestIdBatch: [],
}

export default createReducer(initialState, {
  [pmpmConstants.TOGGLE_CALENDAR_VIEW]: (state) =>
    ({
      ...state,
      calendarView: !state.calendarView
    }),
  [pmpmConstants.OPEN_ADD_REQUEST_MODAL]: (state, payload) =>
    ({...state,
      addRequestModalOpen: true,
      currentRequest: payload
    }),
  [pmpmConstants.CLOSE_ADD_REQUEST_MODAL]: (state) =>
    ({
      ...state,
      addRequestModalOpen: false,
      currentRequest: undefined
    }),
  [pmpmConstants.OPEN_MASSIVE_ADD_REQUEST_MODAL]: (state) =>
    ({
      ...state,
      addRequestMassiveModalOpen: true
    }),
  [pmpmConstants.CLOSE_MASSIVE_ADD_REQUEST_MOODAL]: (state, payload) =>
    ({...state,
      addRequestMassiveModalOpen: false
    }),
  [pmpmConstants.OPEN_DELETE_REQUEST_MODAL]: (state, payload) =>
    ({...state,
      deleteRequestModalOpen: true,
      currentRequest: payload
    }),
  [pmpmConstants.CLOSE_DELETE_REQUEST_MODAL]: (state) =>
    ({
      ...state,
      deleteRequestModalOpen: false,
      currentRequest: undefined
    }),
  [pmpmConstants.OPEN_APROVAL_REQUEST_MODAL]: (state, payload) => 
    ({...state,
      aprovalRequestModalOpen: true,
      currentRequestIdBatch: payload.currentRequestIdBatch,
      aprovalAction: payload.aprovalAction
    }), 
  [pmpmConstants.CLOSE_APROVAL_REQUEST_MODAL]: (state) =>
    ({
      ...state,
      aprovalRequestModalOpen: false,
      currentRequestIdBatch: [],
      currentRequest: undefined
    }),
  [programsConstants.FETCH_PROGRAMS]: (state) =>
    ({
      ...state,
      fetchingPrograms: true
    }),
  [programsConstants.FETCH_PROGRAMS_SUCCESS]: (state) =>
    ({
      ...state,
      fetchingPrograms: false
    }),
  [programsConstants.FETCH_PROGRAMS_ERROR]: (state) =>
    ({
      ...state,
      fetchingPrograms: false
    }),
  [programsConstants.UPDATE_PRELIMINARY_EDITION]: state =>
    ({
      ...state,
      updatingPreliminaryEdition: true
    }),
  [programsConstants.UPDATE_PRELIMINARY_EDITION_SUCCESS]: state =>
    ({
      ...state,
      updatingPreliminaryEdition: false
    }),
  [programsConstants.UPDATE_PRELIMINARY_EDITION]: state =>
    ({
      ...state,
      updatingPreliminaryEdition: false
    }),
  [workRequestsConstants.FETCH_WORK_REQUESTS]: (state) =>
    ({
      ...state,
      fetchingWorkRequests: true
    }),
  [workRequestsConstants.FETCH_WORK_REQUESTS_SUCCESS]: (state) =>
    ({
      ...state,
      fetchingWorkRequests: false
    }),
  [workRequestsConstants.FETCH_WORK_REQUESTS_ERROR]: (state) =>
    ({
      ...state,
      fetchingWorkRequests: false
    }),
  [workRequestsConstants.ADD_WORK_REQUEST]: (state) =>
    ({
      ...state,
      addingWorkRequest: true
    }),
  [workRequestsConstants.EDIT_WORK_REQUEST]: (state) =>
    ({
      ...state,
      addingWorkRequest: true
    }),
  [workRequestsConstants.ADD_WORK_REQUEST_SUCCESS]: (state) =>
    ({
      ...state,
      addingWorkRequest: false,
    }),
  [workRequestsConstants.EDIT_WORK_REQUEST_SUCCESS]: (state) =>
    ({
      ...state,
      addingWorkRequest: false,
      currentRequest: undefined
    }),
  [workRequestsConstants.ADD_DELETION_REQUEST]: (state) =>
    ({
      ...state,
      addingWorkRequest: true
    }),
  [workRequestsConstants.ADD_WORK_REQUEST_ERROR]: (state) =>
    ({
      ...state,
      addingWorkRequest: false
    }),
  [workRequestsConstants.EDIT_WORK_REQUEST_ERROR]: (state) =>
    ({
      ...state,
      addingWorkRequest: false,
      currentRequest: undefined
    }),
  [workRequestsConstants.ADD_DELETION_REQUEST_ERROR]: (state) =>
    ({
      ...state,
      addingWorkRequest: false,
      currentRequest: undefined
    }),
  [workRequestsConstants.CHANGE_WORK_REQUEST_STATUS]: (state) =>
    ({
      ...state,
      aprovingWorkRequest: true
    }),
  [workRequestsConstants.CHANGE_WORK_REQUEST_STATUS_SUCCESS]: (state) =>
    ({
      ...state,
      aprovingWorkRequest: false
    }),
  [workRequestsConstants.CHANGE_WORK_REQUEST_STATUS_ERROR]: (state) =>
    ({
      ...state,
      aprovingWorkRequest: false
    }),
  [workRequestsConstants.ADD_DELETION_REQUEST]: (state) =>
    ({
      ...state,
      deletingWorkRequest: true
    }),
  [workRequestsConstants.ADD_MODIFICATION_REQUEST]: (state) =>
    ({
      ...state,
      deletingWorkRequest: false
    }),
  [workRequestsConstants.ADD_DELETION_REQUEST_ERROR]: (state) =>
    ({
      ...state,
      deletingWorkRequest: false
    }),
  [workRequestsConstants.BUTTON_DOWNLOAD_REPORT_PENDING]: (state) =>
    ({
      ...state,
      buttonDownloadReportIsLoading: true
    }),
  [workRequestsConstants.BUTTON_DOWNLOAD_REPORT_DONE]: (state) =>
    ({
      ...state,
      buttonDownloadReportIsLoading: false
    }),
})
