import client from '../api/apiClient'
import { tokenConfig } from './'


export function reqGetPrograms (token) {
  return client().get('/programmed_maintenance/', tokenConfig(token))
}

export function reqGetProgramDetails (token, programId) {
  return client().get(`/programmed_maintenance/${programId}`, tokenConfig(token))
}

export function updatePreliminaryEditon (token, preliminaryId, editionEnabled) {
  return client().put(`/programmed_maintenance/${preliminaryId}/change_preliminary_edition/`, {edition_enabled: editionEnabled}, tokenConfig(token))
}

export function reqMoveProgramToHistoric (token, currentProgramId, limitDate) {
  return client().post(`/programmed_maintenance/${currentProgramId}/move_current_to_historic/`, { limit: limitDate }, tokenConfig(token))
}
