import React from 'react'
import {Input} from 'antd'

const {TextArea} = Input

const Component = (props) => {
  const {rows, value, onChange} = props
  return (
    <TextArea
      value={value}
      rows={ rows ? rows : 2 }
      onChange={(value) => onChange(value)} 
      style={{width: props.width || '100%'}}
    />
  )
}

export default Component
