import { createReducer } from '../../utils/misc'
import moment from 'moment'

import {
  authConstants
} from '../constants/auth'

const initialState = {
  isAuthenticated: false,
  authenticating: false,
  token: '',
  refreshToken: '',
  loginTimestamp: null,
  errorMessage: '',
}

export default createReducer(initialState, {
  [authConstants.AUTH_USER_SUCCESS]: (state, payload) =>
    Object.assign({}, state, {
      isAuthenticated: true,
      token: payload.token,
      refreshToken: payload.refreshToken,
      loginTimestamp: moment(),
      error: null,
      authenticating: false
    }),
  [authConstants.AUTH_USER]: (state) =>
    Object.assign({}, state, {
      authenticating: true
    }),
  [authConstants.AUTH_USER_ERROR]: (state, payload) =>
    Object.assign({}, state, {
      errorMessage: payload,
      authenticating: false
    }),
  [authConstants.UNAUTH_USER]: (state) =>
    Object.assign({}, state, {
      isAuthenticated: false,
      token: '',
      refreshToken: '',
      error: null,
      loginTimestamp: null
    })
})
