import {
  profileRolesConstants
} from '../constants/profileRoles'
import {
  reqGetProfileRoles
} from '../api/profileRoles'
import { parseJSON } from '../../utils/misc'

export function getProfileRoles () {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({ type: profileRolesConstants.FETCH_PROFILE_ROLES_REQUEST })
    reqGetProfileRoles (auth.token)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: profileRolesConstants.FETCH_PROFILE_ROLES_SUCCESS,
          payload: response
        })
      })
      .catch(error => {
        console.error(error)
      })
  }
}
