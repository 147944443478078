import { createReducer } from '../../utils/misc'

import {
  plexosConstants
} from '../constants/plexos'

const initialState = {
  isUpdatingConfig: false,
  isLoadingConfig: false,
  plexos: [],
  plp: []
}

export default createReducer(initialState, {
  [plexosConstants.UPLOAD_PLEXOS_CONFIG]: (state) =>
    ({
      ...state,
      isUpdatingConfig: true
    }),
  [plexosConstants.UPLOAD_PLEXOS_CONFIG_SUCCESS]: (state, payload) =>
    ({
      ...state,
      isUpdatingConfig: false,
      plexos: payload.plexos,
      plp: payload.plp
    }),
  [plexosConstants.UPLOAD_PLEXOS_CONFIG_ERROR]: (state) =>
    ({
      ...state,
      isUpdatingConfig: false
    }),
  [plexosConstants.FETCH_CURRENT_CONFIG]: (state) =>
    ({
      ...state,
      isLoadingConfig: true
    }),
  [plexosConstants.FETCH_CURRENT_CONFIG_SUCCESS]: (state, payload) =>
    ({
      ...state,
      isLoadingConfig: false,
      plexos: payload.filter(item => item.is_plexos === true && !item.is_deleted),
      plp: payload.filter(item => item.is_plp === true && !item.is_deleted)
    }),
  [plexosConstants.FETCH_CURRENT_CONFIG_ERROR]: (state) =>
    ({
      ...state,
      isLoadingConfig: false
    })
  
})
