import React from 'react'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import { defaultTimeFormat as timeFormat, disabledDate } from '../../../utils/workRequestUtils'
import moment from 'moment'

const { RangePicker } = DatePicker

const Component = (props) => {
  const {
    width,
    value,
    onChange,
    disabled,
    programmed_dates,
    hasRealStartDate,
    isSearch = false,
  } = props

  if (hasRealStartDate && !disabled) {
    const start_date = value[0]
    return (
      <>
        <DatePicker
          disabled={true}
          value={start_date}
          style={{ width: '50%' }}
          locale={locale}
          showTime={{
            format: 'HH:mm',
            defaultValue: moment('00:00:00', 'HH:mm:ss'),
            hideDisabledOptions: true,
          }}
          format={timeFormat}
        />
        <DatePicker
          disabled={disabled}
          value={value[1]}
          style={{ width: '50%' }}
          onChange={(value) => {
            onChange([start_date, value])
          }}
          locale={locale}
          showTime={{
            format: 'HH:mm',
            defaultValue: moment('23:59:59', 'HH:mm:ss'),
            hideDisabledOptions: true,
          }}
          format={timeFormat}
          disabledDate={(endValue) => {
            if (!endValue || !start_date) {
              return false
            }
            return endValue.valueOf() <= start_date.valueOf()
          }}
          dateRender={(current) => {
            const style = {}
            if (programmed_dates) {
              const start_day = moment(programmed_dates[0]).startOf('day')
              const end_day = moment(programmed_dates[1]).startOf('day')
              const current_day = moment(current).startOf('day')
              if (
                current_day.isSame(start_day) ||
                current_day.isSame(end_day)
              ) {
                style.border = '1px solid #1890ff'
                style.borderRadius = '50%'
              }
            }
            return (
              <div className='ant-calendar-date' style={style}>
                {current.date()}
              </div>
            )
          }}
        />
      </>
    )
  }

  return (
    <RangePicker
      disabled={disabled}
      value={value}
      style={{ width: width || '100%' }}
      onChange={(value) => {
        onChange(value)
      }}
      locale={locale}
      showTime={{
        format: 'HH:mm',
        defaultValue: [
          moment('00:00:00', 'HH:mm:ss'),
          moment('23:59:59', 'HH:mm:ss'),
        ],
        hideDisabledOptions: true,
      }}
      format={timeFormat}
      disabledDate={(current) => disabledDate(current, isSearch)}
      dateRender={(current) => {
        const style = {}
        if (programmed_dates) {
          const start_day = moment(programmed_dates[0]).startOf('day');
          const end_day = moment(programmed_dates[1]).startOf('day');
          const current_day = moment(current).startOf('day')
          if (
            current_day.isSame(start_day) ||
            current_day.isSame(end_day)
          ) {
            style.border = '1px solid #1890ff'
            style.borderRadius = '50%'
          }
        }
        return (
          <div className='ant-calendar-date' style={style}>
            {current.date()}
          </div>
        )
      }}
    />
  )
}

export default Component
