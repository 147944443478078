import React from 'react'
import {Radio} from 'antd'

const {Group, Button} = Radio

const Component = (props) => {
  const { value, onChange, handler } = props
  return (
    <Group
      value={value}
      onChange={({target}) => {
        const { value } = target
        onChange(value)
        handler(value)
      }} 
      style={{width: props.width || '100%'}}
    >
      <Button value='No'>No</Button>
      <Button value='Si'>Si</Button>      

    </Group>
  )
}

export default Component