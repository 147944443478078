import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row } from 'antd'
import ProgramTabs from '../ProgramTabs'
import './pmpm.css'

class PMPM extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Row className='home-cnt'>
          <ProgramTabs />
        </Row>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch)

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PMPM)
