import React from 'react'
import { Select } from 'antd'
import { selectSearch } from '../../../utils/misc'

const { Option } = Select

const Component = (props) => {
  const { value, onChange, companies, width, disabled } = props
  return (
    <Select
      value={value}
      onChange={(value) => {
        onChange(value)
      }}
      mode='multiple'
      showSearch
      disabled={disabled || false}
      style={{ width: width || '100%' }}
      filterOption={selectSearch}
    >
      {
        companies.allIds.map(choiceId => (
          <Option
            key={`risk-${choiceId}`}
            value={choiceId}
          >
            {companies.byId[choiceId].nombre}
          </Option>
        ))
      }
    </Select>
  )
}

export default Component
