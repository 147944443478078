import client from '../api/apiClient'
import { tokenConfig } from './'

export function reqGetUser (token, userId) {
  return client().get(`/user/${userId}`, tokenConfig(token))
}

export function reqGetUsers (token) {
  return client().get(`/user`, tokenConfig(token))
}

export function reqEditUserRoles (token, userId) {
  return client().put(`/user/${userId}`, {
    
  }, tokenConfig(token))
}

export function reqToggleDisableUser (token, userId) {
  return client().delete(`/user/${userId}`, tokenConfig(token))
}
