export const workRequestsConstants = {
  FETCH_WORK_REQUESTS: 'FETCH_WORK_REQUESTS',
  FETCH_WORK_REQUESTS_SUCCESS: 'FETCH_WORK_REQUESTS_SUCCESS',
  FETCH_WORK_REQUESTS_ERROR: 'FETCH_WORK_REQUESTS_ERROR',
  ADD_WORK_REQUEST: 'ADD_WORK_REQUEST',
  ADD_WORK_REQUEST_SUCCESS: 'ADD_WORK_REQUEST_SUCCESS',
  ADD_WORK_REQUEST_ERROR: 'ADD_WORK_REQUEST_ERROR',
  CHANGE_WORK_REQUEST_STATUS: 'CHANGE_WORK_REQUEST_STATUS',
  CHANGE_WORK_REQUEST_STATUS_SUCCESS: 'CHANGE_WORK_REQUEST_STATUS_SUCCESS',
  CHANGE_WORK_REQUEST_STATUS_ERROR: 'CHANGE_WORK_REQUEST_STATUS_ERROR',
  RELOAD_WORK_REQUEST: 'RELOAD_WORK_REQUEST',
  RELOAD_WORK_REQUEST_SUCCESS: 'RELOAD_WORK_REQUEST_SUCCESS',
  RELOAD_WORK_REQUEST_ERROR: 'RELOAD_WORK_REQUEST_ERROR',
  ADD_DELETION_REQUEST: 'ADD_DELETION_REQUEST',
  ADD_MODIFICATION_REQUEST: 'ADD_MODIFICATION_REQUEST',
  ADD_DELETION_REQUEST_ERROR: 'ADD_DELETION_REQUEST_ERROR',
  // DOWNLOAD REPORT 
  BUTTON_DOWNLOAD_REPORT_PENDING: 'BUTTON_DOWNLOAD_REPORT_PENDING',
  BUTTON_DOWNLOAD_REPORT_DONE: 'BUTTON_DOWNLOAD_REPORT_DONE',
  // CONFIRMATION
  CONFIRM_REQUEST_WORKS: 'CONFIRM_REQUEST_WORKS',
  CONFIRM_REQUEST_WORKS_SUCCESS: 'CONFIRM_REQUEST_WORKS_SUCCESS',
  CONFIRM_REQUEST_WORKS_ERROR: 'CONFIRM_REQUEST_WORKS_ERROR',
  // Rq Modification
  APPLY_REQUEST_MODIFICATION: 'APPLY_REQUEST_MODIFICATION',
  APPLY_REQUEST_MODIFICATION_SUCCESS: 'APPLY_REQUEST_MODIFICATION_SUCCESS',
  APPLY_REQUEST_MODIFICATION_ERROR: 'APPLY_REQUEST_MODIFICATION_ERROR',
  // EDIT
  EDIT_WORK_REQUEST: 'EDIT_WORK_REQUEST',
  EDIT_WORK_REQUEST_SUCCESS: 'EDIT_WORK_REQUEST_SUCCESS',
  EDIT_WORK_REQUEST_ERROR: 'EDIT_WORK_REQUEST_ERROR',
  // SELECTION
  CHANGE_WORK_REQUEST_SELECTION: 'CHANGE_WORK_REQUEST_SELECTION',
  CLEAR_WORK_REQUEST_SELECTION: 'CLEAR_WORK_REQUEST_SELECTION',
  // MASSIVE
  ADD_WORK_REQUEST_MASSIVE: 'ADD_WORK_REQUEST_MASSIVE',
  ADD_WORK_REQUEST_MASSIVE_SUCCESS: 'ADD_WORK_REQUEST_MASSIVE_SUCCESS',
  ADD_WORK_REQUEST_MASSIVE_ERROR: 'ADD_WORK_REQUEST_MASSIVE_ERROR'
}
