import { createReducer } from '../../utils/misc'
import {
  massiveConstants
} from '../constants/massive'
import {
  pmpmConstants
} from '../constants/pmpm'
import {
  workRequestsConstants
} from '../constants/workRequests'
import { showNotification } from '../../utils/notification'

const initialState = {
  shouldShowReader: true,
  shouldShowGrid: false,
  isParsing: false,
  isReading: false,
  isValidating: false,
  isCreating: false,
  dataset: []
}

export default createReducer(initialState, {
  [massiveConstants.START_READING]: (state) =>
    ({
      ...state,
      isReading: true
    }),
  [massiveConstants.DONE_READING]: (state) =>
    ({
      ...state,
      isReading: false
    }),
  [massiveConstants.READING_ERROR]: (state, payload) => {
    showNotification('Error de Lectura', '', 'error')
    return { ...initialState }
  },
  [massiveConstants.START_PARSING]: (state) =>
    ({
      ...state,
      isParsing: true
    }),
  [massiveConstants.DONE_PARSING]: (state, { data }) =>
    ({
      ...state,
      isParsing: false,
      shouldShowReader: false,
      shouldShowGrid: true,
      dataset: data
    }),
  [massiveConstants.PARSING_ERORR]: (state, payload) => {
    showNotification('Error de Traducción', '', 'error')
    return { ...initialState }
  },
  [massiveConstants.START_VALIDATING]: (state) =>
    ({
      ...state,
      isValidating: true
    }),
  [massiveConstants.DONE_VALIDATING]: (state) =>
    ({
      ...state,
      isValidating: false
    }),
  [massiveConstants.VALIDATION_ERROR]: (state, payload) =>
    ({
      ...state,
      validationError: payload
    }),
  [pmpmConstants.OPEN_MASSIVE_ADD_REQUEST_MODAL]: (state) =>
    ({
      ...initialState
    }),
  [massiveConstants.UPDATE_DATASET]: (state, { fromRow, toRow, updated }) => {
    const dataset = [...state.dataset].slice()
    for (let i = fromRow; i <= toRow; i++) {
      dataset[i] = { ...dataset[i], ...updated }
    }
    return {
      ...state,
      dataset: dataset
    }
  },
  [workRequestsConstants.ADD_WORK_REQUEST_MASSIVE]: (state) =>
    ({
      ...state,
      isCreating: true
    }),
  [workRequestsConstants.ADD_WORK_REQUEST_MASSIVE_SUCCESS]: (state) =>
    ({
      ...state,
      isCreating: false
    }),
  [workRequestsConstants.ADD_WORK_REQUEST_MASSIVE_ERROR]: (state) =>
    ({
      ...state,
      isCreating: false
    })
})
