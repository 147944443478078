import React from 'react'
import { List, Button, Popover } from 'antd'
import WorkRequestListItem from '../../containers/WorkRequestListItem'
import './WorkRequestList.css'
import AccessControl from '../AccessControl'

const WorkRequestList = (props) => {
  const {
    workRequestsById,
    loading,
    workRequestsIdOrder,
    selectionCount,
    batchAprovalAction,
    clearSelectionAction,
    selectedProgramTab,
    currentHistoric = null,
    user
  } = props
  const ButtonWithPopOver = ({ children, ...props }) => {
    const helpText = 'Selecciona elementos para habilitar la acción.'
    const visible = props.disabled ? {} : { visible: false }
    return (
      <Popover
        // Visible when the button is disabled and the user hovers.
        {...visible}
        content={helpText}
      >
        <Button {...props}>
          {children}
        </Button>
      </Popover>
    )
  }
  const selectionText = (function () {
    const finalS = selectionCount === 1 ? '' : 's'
    const text = `${selectionCount} elemento${finalS} seleccionado${finalS}`
    return text
  })()
  const disableButtons = selectionCount <= 0

  let dataSource = workRequestsIdOrder
  let locale = null
  if (selectedProgramTab === 'historic' && currentHistoric === null) {
    dataSource = []
    locale = { emptyText: 'Selecciona un programa en el filtro' }
  }

  return (
    <>
      {selectedProgramTab === 'historic'
        ? null
        : (
          <AccessControl
            userType={user.userType}
            allowedUserTypes={['superadmin']}
          >
            <div className='batch-aproval-buttons'>
              <ButtonWithPopOver
                disabled={disableButtons}
                type='primary'
                className='green-button'
                onClick={() => batchAprovalAction(true)}
              >
                Aprobar selección
              </ButtonWithPopOver>
              <ButtonWithPopOver
                disabled={disableButtons}
                type='primary'
                className='red-button'
                onClick={() => batchAprovalAction(false)}
              >
                Rechazar selección
              </ButtonWithPopOver>
              <ButtonWithPopOver
                disabled={disableButtons}
                type='secondary'
                onClick={clearSelectionAction}
              >
                Limpiar
              </ButtonWithPopOver>
              <span>{selectionText}</span>
            </div>
          </AccessControl>
        )}
      <List
        id='work-request-list'
        loading={loading}
        grid={{ gutter: 16, column: 1 }}
        dataSource={dataSource}
        renderItem={wr => (
          <WorkRequestListItem
            item={workRequestsById[wr]}
            selectedProgramTab={selectedProgramTab}
          />
        )}
        pagination={{
          defaultPageSize: 25,
          total: workRequestsIdOrder.length,
          pageSizeOptions: ['10', '25', '50', '100', '200'],
          showSizeChanger: true,
          position: 'both',
          showQuickJumper: true,
          showTotal: (total, range) => `${range[0]} a ${range[1]} de ${total} elementos`
        }}
        locale={locale}
      />
    </>
  )
}

export default WorkRequestList
