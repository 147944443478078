import React from 'react'
import {Input} from 'antd'

const NumeroNUP = (props) => {
  const { value, onChange, onPressEnter, width } = props
  return (
    <Input
      value={value}
      onPressEnter={onPressEnter}
      onChange={(value) => onChange(value)} 
      style={{width: width || '100%'}}
      placeholder='No está asociado a un NUP'
      disabled
    />
  )
}

export default NumeroNUP