
import { plexosConstants } from '../constants/plexos'
import { showNotification } from '../../utils/notification'
import { uploadPlexosConfig, fetchPlexosConfig } from '../api/plexos'
import { parseJSON } from '../../utils/misc'

export function uploadNewPlexosConfiguration (configs) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({ type: plexosConstants.UPLOAD_PLEXOS_CONFIG })
    uploadPlexosConfig(auth.token, configs)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: plexosConstants.UPLOAD_PLEXOS_CONFIG_SUCCESS,
          payload: response
        })
        return showNotification(
          'Configuración Cargada',
          'La configuración fue cargada correctamente y está en operación',
          'success'
        )
      })
      .catch(error => {
        console.error(error)
        dispatch({ type: plexosConstants.UPLOAD_PLEXOS_CONFIG_ERROR })
        return showNotification(
          'Ha ocurrido un error',
          'No fue posible cargar la configuración',
          'error'
        )
      })
  }
}

export function fetchPlexosConfiguration (configs) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({ type: plexosConstants.FETCH_CURRENT_CONFIG })
    fetchPlexosConfig(auth.token, configs)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: plexosConstants.FETCH_CURRENT_CONFIG_SUCCESS,
          payload: response
        })
      })
      .catch(error => {
        console.error(error)
        dispatch({ type: plexosConstants.FETCH_CURRENT_CONFIG_ERROR })
        return showNotification(
          'Ha ocurrido un error',
          'No fue posible cargar la configuración actual',
          'error'
        )
      })
  }
}
