import React from 'react'
import { Route } from 'react-router-dom'

export default ({ layout: Layout, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      }
    />
  )
}
