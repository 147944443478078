export const entitiesConstants = {
  // FETCH INFOTECNICA COMPANIES RELATED CONSTANTS
  ADD_INFOTECNICA_COMPANY: 'ADD_COMPANY',
  FETCH_INFOTECNICA_COMPANIES: 'FETCH_INFOTECNICA_COMPANIES',
  FETCH_INFOTECNICA_COMPANIES_SUCCESS: 'FETCH_INFOTECNICA_COMPANIES_SUCCESS',
  FETCH_INFOTECNICA_COMPANIES_FAILURE: 'FETCH_INFOTECNICA_COMPANIES_FAILURE',
  // FETCH OWN DB COMPANIES RELEATED CONSTANTS
  FETCH_COMPANIES: 'FETCH_COMPANIES',
  FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
  FETCH_COMPANIES_ERROR: 'FETCH_COMPANIES_ERROR',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_ERROR: 'UPDATE_COMPANY_ERROR', 
  // STATUS OPTIONS RELATED CONSTANTS
  ADD_OPTION: 'ADD_OPTION',
  FETCH_STATUS_OPTIONS: 'FETCH_STATUS_OPTIONS',
  FETCH_STATUS_OPTIONS_SUCCESS: 'FETCH_STATUS_OPTIONS_SUCCESS',
  FETCH_STATUS_OPTIONS_FAILURE: 'FETCH_STATUS_OPTIONS_FAILURE',
  // CENTRAL
  ADD_CENTRAL: 'ADD_OPTION',
  FETCH_CENTRAL: 'FETCH_CENTRAL',
  FETCH_CENTRAL_SUCCESS: 'FETCH_CENTRAL_SUCCESS',
  FETCH_CENTRAL_FAILURE: 'FETCH_CENTRAL_FAILURE',
  // UNIT
  ADD_UNIT: 'ADD_CENTRAL',
  FETCH_UNIT: 'FETCH_UNIT',
  FETCH_UNIT_SUCCESS: 'FETCH_UNIT_SUCCESS',
  FETCH_UNIT_FAILURE: 'FETCH_UNIT_FAILURE',
  // LINE
  ADD_LINE: 'ADD_LINE',
  FETCH_LINE: 'FETCH_LINE',
  FETCH_LINE_SUCCESS: 'FETCH_LINE_SUCCESS',
  FETCH_LINE_FAILURE: 'FETCH_LINE_FAILURE',
  // SECTION
  ADD_SECTION: 'ADD_SECTION',
  FETCH_SECTION: 'FETCH_SECTION',
  FETCH_SECTION_SUCCESS: 'FETCH_SECTION_SUCCESS',
  FETCH_SECTION_FAILURE: 'FETCH_SECTION_FAILURE',
  //5941.I
  // BARRA
  ADD_BARRA: 'ADD_BARRA',
  FETCH_BARRA: 'FETCH_BARRA',
  FETCH_BARRA_SUCCESS: 'FETCH_BARRA_SUCCESS',
  FETCH_BARRA_FAILURE: 'FETCH_BARRA_FAILURE',
  // PANO
  ADD_PANO: 'ADD_PANO',
  FETCH_PANO: 'FETCH_PANO',
  FETCH_PANO_SUCCESS: 'FETCH_PANO_SUCCESS',
  FETCH_PANO_FAILURE: 'FETCH_PANO_FAILURE',
  //5941.F  
  // SUB_BARRA
  ADD_SUB_BARRA: 'ADD_SUB_BARRA',
  FETCH_SUB_BARRA: 'FETCH_SUB_BARRA',
  FETCH_SUB_BARRA_SUCCESS: 'FETCH_SUB_BARRA_SUCCESS',
  FETCH_SUB_BARRA_FAILURE: 'FETCH_SUB_BARRA_FAILURE',  
  // SUB_PANO
  ADD_SUB_PANO: 'ADD_SUB_PANO',
  FETCH_SUB_PANO: 'FETCH_SUB_PANO',
  FETCH_SUB_PANO_SUCCESS: 'FETCH_SUB_PANO_SUCCESS',
  FETCH_SUB_PANO_FAILURE: 'FETCH_SUB_PANO_FAILURE',   

  ////////////////////////////////////////////////////////////////////////////////////////////////
  FETCH_PROYECTO: 'FETCH_PROYECTO',
  FETCH_PROYECTO_SUCCESS: 'FETCH_PROYECTO_SUCCESS',
  FETCH_PROYECTO_FAILURE: 'FETCH_PROYECTO_FAILURE',

  // SUB_TRANSFORMADORES2D
  ADD_SUB_TRANSFORMADORES2D: 'ADD_SUB_TRANSFORMADORES2D',
  FETCH_SUB_TRANSFORMADORES2D: 'FETCH_SUB_TRANSFORMADORES2D',
  FETCH_SUB_TRANSFORMADORES2D_SUCCESS: 'FETCH_SUB_TRANSFORMADORES2D_SUCCESS',
  FETCH_SUB_TRANSFORMADORES2D_FAILURE: 'FETCH_SUB_TRANSFORMADORES2D_FAILURE',
  
  // SUB_TRANSFORMADORES3D
  ADD_SUB_TRANSFORMADORES3D: 'ADD_SUB_TRANSFORMADORES3D',
  FETCH_SUB_TRANSFORMADORES3D: 'FETCH_SUB_TRANSFORMADORES3D',
  FETCH_SUB_TRANSFORMADORES3D_SUCCESS: 'FETCH_SUB_TRANSFORMADORES3D_SUCCESS',
  FETCH_SUB_TRANSFORMADORES3D_FAILURE: 'FETCH_SUB_TRANSFORMADORES3D_FAILURE',
  
  // SUB_BANCOSCONDENSADORES
  ADD_SUB_BANCOSCONDENSADORES: 'ADD_SUB_BANCOSCONDENSADORES',
  FETCH_SUB_BANCOSCONDENSADORES: 'FETCH_SUB_BANCOSCONDENSADORES',
  FETCH_SUB_BANCOSCONDENSADORES_SUCCESS: 'FETCH_SUB_BANCOSCONDENSADORES_SUCCESS',
  FETCH_SUB_BANCOSCONDENSADORES_FAILURE: 'FETCH_SUB_BANCOSCONDENSADORES_FAILURE',
  
  // SUB_SISTCOMPER
  ADD_SUB_SISTCOMPER: 'ADD_SUB_SISTCOMPER',
  FETCH_SUB_SISTCOMPER: 'FETCH_SUB_SISTCOMPER',
  FETCH_SUB_SISTCOMPER_SUCCESS: 'FETCH_SUB_SISTCOMPER_SUCCESS',
  FETCH_SUB_SISTCOMPER_FAILURE: 'FETCH_SUB_SISTCOMPER_FAILURE',
  
  // SUB_COMPENSADORESACTIVOS
  ADD_SUB_COMPENSADORESACTIVOS: 'ADD_SUB_COMPENSADORESACTIVOS',
  FETCH_SUB_COMPENSADORESACTIVOS: 'FETCH_SUB_COMPENSADORESACTIVOS',
  FETCH_SUB_COMPENSADORESACTIVOS_SUCCESS: 'FETCH_SUB_COMPENSADORESACTIVOS_SUCCESS',
  FETCH_SUB_COMPENSADORESACTIVOS_FAILURE: 'FETCH_SUB_COMPENSADORESACTIVOS_FAILURE',
  
  // SUB_COMPENSADORESSERIE
  ADD_SUB_COMPENSADORESSERIE: 'ADD_SUB_COMPENSADORESSERIE',
  FETCH_SUB_COMPENSADORESSERIE: 'FETCH_SUB_COMPENSADORESSERIE',
  FETCH_SUB_COMPENSADORESSERIE_SUCCESS: 'FETCH_SUB_COMPENSADORESSERIE_SUCCESS',
  FETCH_SUB_COMPENSADORESSERIE_FAILURE: 'FETCH_SUB_COMPENSADORESSERIE_FAILURE',
  
  // SUB_COMPENSADORESSINCRONOS
  ADD_SUB_COMPENSADORESSINCRONOS: 'ADD_SUB_COMPENSADORESSINCRONOS',
  FETCH_SUB_COMPENSADORESSINCRONOS: 'FETCH_SUB_COMPENSADORESSINCRONOS',
  FETCH_SUB_COMPENSADORESSINCRONOS_SUCCESS: 'FETCH_SUB_COMPENSADORESSINCRONOS_SUCCESS',
  FETCH_SUB_COMPENSADORESSINCRONOS_FAILURE: 'FETCH_SUB_COMPENSADORESSINCRONOS_FAILURE',
  
  // SUB_REACTORES
  ADD_SUB_REACTORES: 'ADD_SUB_REACTORES',
  FETCH_SUB_REACTORES: 'FETCH_SUB_REACTORES',
  FETCH_SUB_REACTORES_SUCCESS: 'FETCH_SUB_REACTORES_SUCCESS',
  FETCH_SUB_REACTORES_FAILURE: 'FETCH_SUB_REACTORES_FAILURE',  

  // SUB_MEDFACTURACION
  ADD_SUB_MEDFACTURACION: 'ADD_SUB_MEDFACTURACION',
  FETCH_SUB_MEDFACTURACION: 'FETCH_SUB_MEDFACTURACION',
  FETCH_SUB_MEDFACTURACION_SUCCESS: 'FETCH_SUB_MEDFACTURACION_SUCCESS',
  FETCH_SUB_MEDFACTURACION_FAILURE: 'FETCH_SUB_MEDFACTURACION_FAILURE', 

  ////////////////////////////////////////////////////////////////////////////////////////////////

  // SUBSTATIONS
  ADD_SUBSTATION: 'ADD_SUBSTATION',
  FETCH_SUBSTATION: 'FETCH_SUBSTATION',
  FETCH_SUBSTATION_SUCCESS: 'FETCH_SUBSTATION_SUCCESS',
  FETCH_SUBSTATION_FAILURE: 'FETCH_SUBSTATION_FAILURE',
  // ELEMENTS
  ADD_ELEMENT: 'ADD_ELEMENT',
  FETCH_ELEMENT: 'FETCH_ELEMENT',
  FETCH_ELEMENT_SUCCESS: 'FETCH_ELEMENT_SUCCESS',
  FETCH_ELEMENT_FAILURE: 'FETCH_ELEMENT_FAILURE',
  // SUBSTATIONELEMENTS
  FETCH_SUBSTATIONELEMENTS: 'FETCH_SUBSTATIONELEMENTS',
  FETCH_SUBSTATIONELEMENTS_SUCCESS: 'FETCH_SUBSTATIONELEMENTS_SUCCESS',
  FETCH_SUBSTATIONELEMENTS_ERROR: 'FETCH_SUBSTATIONELEMENTS_ERROR',
  // REGIONS
  ADD_REGION: 'ADD_REGION',
  FETCH_REGION: 'FETCH_REGION',
  FETCH_REGION_SUCCESS: 'FETCH_REGION_SUCCESS',
  FETCH_REGION_FAILURE: 'FETCH_REGION_FAILURE',
  // REQUEST MODIFICATION
  ADD_WORK_REQUEST_MODIFICATIONS: 'ADD_WORK_REQUEST_MODIFICATIONS',
  FETCH_WORK_REQUEST_MODIFICATIONS: 'FETCH_WORK_REQUEST_MODIFICATIONS',
  FETCH_WORK_REQUEST_MODIFICATIONS_SUCCESS: 'FETCH_WORK_REQUEST_MODIFICATIONS_SUCCESS',
  FETCH_WORK_REQUEST_MODIFICATIONS_ERROR: 'FETCH_WORK_REQUEST_MODIFICATIONS_ERROR',
  // USERS
  CHANGE_USER_COMPANY_ROLES: 'CHANGE_USER_COMPANY_ROLES',
  CHANGE_USER_COMPANY_ROLES_SUCCESS: 'CHANGE_USER_COMPANY_ROLES_SUCCESS',
  CHANGE_USER_COMAPNY_ROLES_ERROR: 'CHANGE_USER_COMPANY_ROLES_ERROR',
  // FILE_TEMPLATES
  FETCH_FILE_TEMPLATES: 'FETCH_FILE_TEMPLATES',
  FETCH_FILE_TEMPLATES_SUCCESS: 'FETCH_FILE_TEMPLATES_SUCCESS',
  FETCH_FILE_TEMPLATES_ERROR: 'FETCH_FILE_TEMPLATES_ERROR',
  // CHANGE USER TYPE
  CHANGE_USER_TYPE: 'CHANGE_USER_TYPE',
  CHANGE_USER_TYPE_SUCCESS: 'CHANGE_USER_TYPE_SUCCESS',
  CHANGE_USER_TYPE_ERROR: 'CHANGE_USER_TYPE_ERROR'
}
