
import client from '../api/apiClient'
import { tokenConfig } from './'

export function uploadPlexosConfig (token, configs) {
  return client().post('/plexos/upload_config/', { configs }, tokenConfig(token))
}

export function fetchPlexosConfig (token) {
  return client().get('/plexos', tokenConfig(token))
}
