import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Card , Button } from 'antd'
import history from '../../utils/history'
import './login.css'
import { goToCognitoLogin } from '../../utils/misc'

class LoginForm extends React.Component {
  componentDidMount () {
    if (this.props.isAuthenticated) {
      history.push('/')
    }
  }

  render() {
    // const buttonStyle = {
    //   width: '10rem',
    //   height: '3.5rem',
    //   fontSize: '1.5rem',
    // }
    return (
      <Card
        style={{ 
          width: '26rem',
          border: '1px solid #e8e8e8',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
          alignSelf: 'center',
          marginTop: '5rem',
      }}
      >
        <h2
          style={{
            marginTop: '2rem',
            textAlign: 'center',
          }}
        >
          Ingreso Programa Mantenimiento Preventivo Mayor
        </h2>
        <Button
          onClick={goToCognitoLogin}
          type="primary"
          block
          style={{
            fontSize: '1.5rem',
            height: '3rem',
            marginTop: '3rem',
          }}
        >
          Entrar
        </Button>
        
      </Card>
    );
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({}, dispatch)
}

function mapStateToProps (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
