
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Layout, Menu, Icon } from 'antd'
import UserAdmin from './UserAdmin'
import CompanyAdmin from './CompanyAdmin'
import ProgramAdmin from './ProgramAdmin'

const { Content, Sider } = Layout

class Admin extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeMenu: 'users'
    }
  }

  componentDidMount () {
    const splitAdminSubMenu = this.props.location.pathname.split('/')
    const subMenuName = splitAdminSubMenu[splitAdminSubMenu.length - 1]
    this.setState({
      activeMenu: subMenuName
    })
  }

  selectMenu = item => {
    this.setState({
      activeMenu: item.key
    }, () => {
      this.props.history.push(`/admin/${item.key}`)
    })
  }

  render () {
    const {
      activeMenu
    } = this.state
    return (
      <Layout>
        <Sider width={200} style={{ background: '#fff' }}>
          <Menu
            selectedKeys={[activeMenu]}
            onClick={this.selectMenu}
            mode="inline"
            defaultSelectedKeys={['users']}
            style={{ height: '100%', borderRight: 0 }}>
            <Menu.Item key="users">
              <span>
                <Icon type="user" />
                Usuarios
              </span>
            </Menu.Item>
            <Menu.Item key="companies">
              <span>
                <Icon type="home" />
                Empresas
              </span>   
            </Menu.Item>
            <Menu.Item key="programs">
              <span>
                <Icon type="snippets" />
                Programas
              </span>   
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            style={{
              background: '#fff',
              padding: 24,
              margin: 0,
              minHeight: 280,
            }} >
            <Switch>
              <Route exact path='/admin/users' component={UserAdmin}/>
              <Route exact path='/admin/companies' component={CompanyAdmin}/>
              <Route exact path='/admin/programs' component={ProgramAdmin}/>
              <Redirect path='*' to='/admin/users' />
            </Switch>
          </Content>
      </Layout>
    </Layout>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch)

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
