import client from '../api/apiClient'
import { tokenConfig } from './'

export function reqCognitoCodeLogin (cognitoCode) {
  return client().post('/auth/token-login/', {
    cognitoCode
  }, {
    headers: {
      Accept: 'application/json'
  }})
}

export function reqTokenValid (token) {
  return client().get('/auth/is-token-valid', tokenConfig(token))
}
