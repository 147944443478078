import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Row, Table, Icon, Button, Form, Modal, Switch} from 'antd'
import {
  getPrograms,
  changePreliminaryEdition
} from '../../store/actions/programs'
import _ from 'lodash'

const { Column } = Table

class ProgramAdmin extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      showHelp: false
    }
  }

  componentDidMount () {
    this.props.getPrograms()
  }


  render () {
    const {
      fetchingPrograms,
      changePreliminaryEdition,
      updatingPreliminaryEdition,
      programs
    } = this.props
    return (
      <React.Fragment>
        <Row>
        <h3>
            <span>Administrador de Programas</span>
            <Button
              style={{marginLeft: '6px'}}
              className={"help-button"}
              type="secondary"
              onClick={()=> this.setState({showHelp: true})}>
              <Icon type="question-circle"/>
            </Button>
          </h3>
        </Row>
        <Row>
          <Table 
            loading={fetchingPrograms}
            pagination={false}
            dataSource={_.map(programs, p => {
              return {
                key: p.id,
                ...p
              }
            })}>
            <Column title="Programa" render={program => program.current? 'Vigente' : 'Preliminar'} key="program" />
            <Column title="Correlativo" dataIndex="correlative" key="email" />
            <Column title="Carga y modificación de solicitudes" key="upload_enabled" 
              render={program => {
                return (
                  <>
                    Deshabilitar
                    <Switch
                        style={{margin: '0 8px'}}
                        loading={updatingPreliminaryEdition}
                        checked={program.edition_enabled}
                        onChange={checked => changePreliminaryEdition(checked)}
                    />
                    Habilitar
                  </>
                )
              }}  
            />
          </Table>
        </Row>
        <Modal
          title="Administrador de Programas"
          visible={this.state.showHelp}
          okButtonProps={{
            style: {display: 'none'}
          }}
          cancelButtonProps={{
            type: "primary"
          }}
          onCancel={()=> this.setState({showHelp: false})}
          cancelText="Ok"
        >
          <p>En esta sección puedes:</p>
          <ul>
            <li>
              Habilitar o deshabilitar la carga y modificación de solicitudes para el programa preliminar.
              Los nuevos programas preliminares están habilitados por defecto. Si el programa preliminar
              esta deshabilitado, su edición será habilitada al pasar a vigente.
            </li>
          </ul>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    getPrograms,
    changePreliminaryEdition
  }, dispatch)

const mapStateToProps = (state) => {
    const { preliminary } = state.entities.programs
    return {
        fetchingPrograms: state.pmpm.fetchingPrograms,
        updatingPreliminaryEdition: state.pmpm.updatingPreliminaryEdition,
        programs: [preliminary]
    }
}



const programAdmin = Form.create({ name: 'userAdmin' })(ProgramAdmin);
export default connect(mapStateToProps, mapDispatchToProps)(programAdmin)
