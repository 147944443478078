import React from 'react'
import { Select } from 'antd'
import { selectSearch } from '../../../utils/misc'

const { Option } = Select

const Component = (props) => {
  const { value, onChange, proyectos, width, disabled, handler } = props
  return (
    <Select
      value={value}
      onChange={(value) => {
        if (handler) {
          handler(value)
        }
        onChange(value)
      }}
      disabled={disabled || false}
      style={{ width: width || '100%' }}
      showSearch
      filterOption={selectSearch}
    >
      {
          proyectos.allIds.map(choiceId => (
          <Option
            key={`proyectos-${choiceId}`}
            value={choiceId}
          >
            {
              `${proyectos.byId[choiceId].id}`
            }
          </Option>
        ))
      }

    </Select>
  )
}

export default Component
