import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Switch } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import esES from 'antd/lib/locale-provider/es_ES'
import HttpsRedirect from 'react-https-redirect'
import * as serviceWorker from './serviceWorker'
import RouteWithLayout from './components/RouteWithLayout'
import Navbar from './components/Navbar'
import PMPM from './containers/PMPM'
import Login from './containers/Login'
import Admin from './containers/Admin'
import CognitoCallback from './containers/CognitoCallback'
import LogoutCallback from './containers/LogoutCallback'
import Authenticated from './containers/Authenticated'

import history from './utils/history'
import configureStore from './store/configStore'

import 'antd/dist/antd.css'
import './index.css'
import './mediaQueries.css'
import 'moment/locale/es'

export const store = configureStore()

ReactDOM.render(
  <HttpsRedirect>
    <ConfigProvider locale={esES}>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <RouteWithLayout exact layout={Navbar} path='/'
              component={Authenticated(PMPM, ['coordinador', 'coordinado', 'superadmin'])} />
            <RouteWithLayout layout={Navbar} path='/admin'
              component={Authenticated(Admin, ['superadmin'])} />
            <RouteWithLayout exact layout={Navbar} path='/login' component={Login} />
            <RouteWithLayout exact layout={Navbar} path='/callback/login' component={CognitoCallback} />
            <RouteWithLayout exact layout={Navbar} path='/callback/logout' component={LogoutCallback} />
          </Switch>
        </Router>
      </Provider>
    </ConfigProvider>
  </HttpsRedirect>,
  document.getElementById('root'))

serviceWorker.unregister()
