import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import user from './user'
import pmpm from './pmpm'
import entities from './entities'
import massive from './massive'
import plexos from './plexos'
import filter from './filter'

const rootReducer = (history) => combineReducers({
  auth,
  user,
  pmpm,
  entities,
  massive,
  plexos,
  filter,
  router: connectRouter(history)
})

export default rootReducer
