import React from 'react'
import {
  Row,
  Col,
  Form,
  Card,
  Button,
} from 'antd'
import './filter.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormItem from '../../components/FormItem'
import { filterWorkRequest } from '../../store/actions/filter'
import { downloadFile } from '../../store/actions/pmpm'
import { selectHistoricProgram } from '../../store/actions/programs'
import StatusFilter from '../../components/FilterItem/StatusFilter'
import CompanyFilter from '../../components/FilterItem/CompanyFilter'
import TypeSelector from '../../components/AddRequestItem/TypeSelector'
import DatePicker from '../../components/AddRequestItem/DateRangeSelector'
import CorrelativeFilter from '../../components/FilterItem/CorrelativeFilter'
import NupFilter from '../../components/FilterItem/NupFilter'
import InstallationSelector from '../../components/AddRequestItem/InstallationSelector'
import OrderDirectionFilter from '../../components/FilterItem/OrderDirectionFilter'
import OrderByFilter from '../../components/FilterItem/OrderByFilter'
//import ElementsSubFilter from '../../components/AddRequestItem/ElementsSubFilter'
import ElementsSubFilter from '../../components/FilterItem/ElementsSubFilter'
import Plexos from '../Plexos'
import ProgramFilter from '../../components/FilterItem/ProgramFilter'
//import { substation } from '../../utils/workRequestUtils'

//5944
//import NumeroNUP from '../../components/AddRequestItem/TextInputSimple'
//

const mapStateToProps = (state) => {
  const { entities, pmpm } = state
  const {
    line,
    unit,
    status,
    infotecnicaCompanies,
    central,
    section,
    sub_barra,
    sub_pano,
    substation,
    sub_transformadores2D,
    sub_transformadores3D,
    sub_bancoscondensadores,
    sub_sistcomper,
    sub_compensadoresactivos,
    sub_condensadoresserie,
    sub_condensadoressincronos,
    sub_reactores,
    sub_medfacturacion,
    programs,
    proyectos
  } = entities
  const { buttonDownloadReportIsLoading } = pmpm
  return {
    line,
    unit,
    status,
    infotecnicaCompanies,
    programs,
    section,
    substation,
    sub_barra,
    sub_pano,
    sub_transformadores2D,
    sub_transformadores3D,
    sub_bancoscondensadores,
    sub_sistcomper,
    sub_compensadoresactivos,
    sub_condensadoresserie,
    sub_condensadoressincronos,
    sub_reactores,
    sub_medfacturacion,
    central,
    buttonDownloadReportIsLoading,
    selectedProgram: programs.selectedProgram,
    proyectos
  }
} 
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    filterWorkRequest,
    selectHistoricProgram,
    downloadFile
  }, dispatch)

const central = {
  mainId: 'central',
  mainText: 'Central',
  subId: 'unit',
  subText: 'Unidad',
  connectionId: 'central'
}

const line = {
  mainId: 'line',
  mainText: 'Línea',
  subId: 'section',
  subText: 'Sección',
  connectionId: 'linea'
}
/*
const substation = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_barra',
  subText: 'Barras',
  connectionId: 'subestacion'
}
*/
const substation_sub_barra = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_barra',
  subText: 'Barras',
  connectionId: 'subestacion'
}
const substation_sub_pano = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_pano',
  subText: 'Paño',
  connectionId: 'subestacion'
}

const substation_sub_transformadores2D = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_transformadores2D',
  subText: 'Transformadores 2D',
  connectionId: 'subestacion'
}

const substation_sub_transformadores3D = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_transformadores3D',
  subText: 'Transformadores 3D',
  connectionId: 'subestacion'
}

const substation_sub_bancoscondensadores = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_bancoscondensadores',
  subText: 'Bancos Condensadores',
  connectionId: 'subestacion'
}

const substation_sub_sistcomper = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_sistcomper',
  subText: 'Sistemas de Compensación Estática de Reactivos',
  connectionId: 'subestacion'
}

const substation_sub_compensadoresactivos = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_compensadoresactivos',
  subText: 'Compensadores Activos',
  connectionId: 'subestacion'
}

const substation_sub_condensadoresserie = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_condensadoresserie',
  subText: 'Condensadores Serie',
  connectionId: 'subestacion'
}

const substation_sub_condensadoressincronos = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_condensadoressincronos',
  subText: 'Condensadores Síncronos',
  connectionId: 'subestacion'
}

const substation_sub_reactores = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_reactores',
  subText: 'Reactores',
  connectionId: 'subestacion'
}

const substation_sub_medfacturacion = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_medfacturacion',
  subText: 'Medidores de facturación',
  connectionId: 'subestacion'
}

/*
const substation_sub_scada = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_scada',
  subText: 'Scada',
  connectionId: 'subestacion'
}

const substation_sub_grupoemergencia = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_grupoemergencia',
  subText: 'Grupo de Emergencia',
  connectionId: 'subestacion'
}

const substation_sub_bateria = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_bateria',
  subText: 'Batería',
  connectionId: 'subestacion'
}

const substation_sub_tendidocable = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_tendidocable',
  subText: 'Tendido de Cable',
  connectionId: 'subestacion'
}

const substation_sub_cargadores = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_cargadores',
  subText: 'Cargadores',
  connectionId: 'subestacion'
}

const substation_sub_telecomunicacion = {
  mainId: 'substation',
  mainText: 'Subestación',
  subId: 'sub_telecomunicacion',
  subText: 'Telecomunicación',
  connectionId: 'subestacion'
}
*/


const installation_subtype = {
  valor: 0
}


const basicRule = (required, message) => ({
  rules: [{
    required, 
    message
  }]
})



const initialState = {
  type: '',
  installation: '',
  sub_elements: '',
  installation_subtype: 0,
  isPlexosVisible: false,

}

//5941
const filterInstallations = (selected, installation, choices) => {
  if (installation === '') {
    return choices
  } else {

    
    
    
    
    
    let filteredInstallations = {
      byId: {}, allIds: []
    }
    const installationId = parseInt(installation)
 
    const validChoices = choices.allIds.filter(elementId => {
      return choices.byId[elementId][`id_${selected.connectionId}`] === installationId
    })
    validChoices.forEach(installationId => {
      filteredInstallations.allIds.push(installationId)
      filteredInstallations.byId[installationId] = {...choices.byId[installationId]}
    })
    return filteredInstallations
  }
}


class FilterRequests extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      ...initialState
    }
  }
  
  openPlexosModal = () => {
    this.setState({
      isPlexosVisible: true
    })
  }
  
  closePlexosModal = () => {
    this.setState({
      isPlexosVisible: false
    })
  }

  handleType = (value) => {
   
    this.setState({
      type: value
    })
    this.props.form.resetFields(['installation', 'sub_installation', 'installation_subtype'])

  }


  handleDownloadReport = () => {
    const params = this.props.form.getFieldsValue()
    
    if(params.program_id) {
       delete params.program_id
    }
    const {environment} = this.props
    const programId = environment.currentHistoric ? environment.currentHistoric.id : (
      environment.selectedProgram === 'current' ? environment.current.id : environment.preliminary.id
    )

    params['program_id'] = programId
    const { downloadFile } = this.props
    downloadFile(params)
  }

  handleInstallation = (value) => {
    this.setState({
      installation: value
    })
    //this.props.form.resetFields(['sub_installation', 'installation_subtype'])
    this.props.form.resetFields(['sub_installation'])
  }

  handleInstallationSUB = (value) => {
    installation_subtype.valor = value
    this.props.form.resetFields(['sub_installation'])
  }
/*
handleInstallationSUB = (value) => {
  this.setState({  
    installation_subtype: value
  })
  this.props.form.resetFields(['sub_installation', 'installation_subtype'])    
}
*/
  cleanFilters = () => {
    const { programs , selectHistoricProgram, filterWorkRequest, form } = this.props
    this.setState({...initialState})
    if (programs.selectedProgram === 'historic')
      selectHistoricProgram(null)
    else{
      
      const program_id = programs[programs.selectedProgram].id
      filterWorkRequest({program_id})
    }
    form.resetFields()
  }

  transformParameters = (values) => {
    let params = {}
    //let arrayNup = {}
    const { programs, selectHistoricProgram } = this.props

    if (values.correlative) {
      params.correlative = values.correlative.trim()
    }

/*
    if (values.nup) {
      //params.nup = values.nup.toString()
      //params.nup = values.nup
      arrayNup = [{
        'nup':values.nup.toString()
    }]
      params.nup = arrayNup['nup']
      console.log('NUPss ->', params.nup)
    }   
*/
  if (values.nup) {
    //params.nup = values.nup.toString()
    params.nup = values.nup
    console.log('NUPss ->', params.nup)
  }   

    

    if (values.type) {
      params.type = values.type
    }
    if (values.installation && values.type) {
      params.installation = values.installation
    }

    if (values.installation_subtype && values.type) {
      params.installation_subtype = values.installation_subtype
    }

    if (values.sub_installation && values.type) {
      params.sub_installation = values.sub_installation
    }

    if (values.sub_installationSUB && values.type) {
      params.sub_installationSUB = values.sub_installationSUB
    }

    if (values.period) {
      params.start = values.period[0].toISOString()
      params.end = values.period[1].toISOString()
    }
    if (values.company) {
      params.company = values.company
      console.log('company ->', params.company)
    }
  
    if (values.status) {
      params.status = values.status
      console.log('status ->', params.status)
    }
    if (values.has_modifications) {
      params.has_modifications = values.has_modifications
    }

    //5941 eleccion de trabajos sobre
    if (values.sub_elements) {
      params.sub_elements = values.sub_elements
    }

    if (values.order_by) {
      params.order_by = values.order_by
    }
    if (values.order) {
      params.order = values.order
    }
    if (programs.selectedProgram === 'historic' && values.program_id) {
      params.program_id = values.program_id
      selectHistoricProgram(programs.historic[values.program_id])
    }else{ 
      params.program_id = programs[programs.selectedProgram].id
    }
    return params
  }

  handleFilter = (event) => {
    event.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const params = this.transformParameters(values)
        console.log('PARAMS ->', params)
        this.props.filterWorkRequest(params)
      } 
    })
  }

  /*
  handleFilterNUP = (event) => {
    event.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const params = this.transformParameters(values)
        
        this.props.filterWorkRequest(params)
      } 
    })
  }  
  */
  render() {
    const statuses = this.props.status 
    //const installation_subtype = this.props.installation_subtype
    const { selectedProgram, proyectos } = this.props
    const companies = this.props.infotecnicaCompanies 
    const subElms = this.props.sub_elements 

    if (this.state.type !== 'substation'){
      installation_subtype.valor = 1
    }

    const installation = 
      this.state.type === 'central' ? central : 
      this.state.type === 'line' ? line : 
      this.state.type === 'substation' && installation_subtype.valor === 11 ? substation_sub_barra : 
      this.state.type === 'substation' && installation_subtype.valor === 13 ? substation_sub_pano : 
      this.state.type === 'substation' && installation_subtype.valor === 16 ? substation_sub_transformadores2D :
      this.state.type === 'substation' && installation_subtype.valor === 17 ? substation_sub_transformadores3D :
      this.state.type === 'substation' && installation_subtype.valor === 20 ? substation_sub_bancoscondensadores :
      this.state.type === 'substation' && installation_subtype.valor === 21 ? substation_sub_sistcomper :
      this.state.type === 'substation' && installation_subtype.valor === 25 ? substation_sub_compensadoresactivos :
      this.state.type === 'substation' && installation_subtype.valor === 22 ? substation_sub_condensadoresserie :
      this.state.type === 'substation' && installation_subtype.valor === 23 ? substation_sub_condensadoressincronos :
      this.state.type === 'substation' && installation_subtype.valor === 24 ? substation_sub_reactores :
      this.state.type === 'substation' && installation_subtype.valor === 33 ? substation_sub_medfacturacion :
      substation_sub_barra

    const installationChoices = this.props[`${installation.mainId}`]

    const subInstallationChoices = 
    filterInstallations(
      installation,
      this.state.installation, 
      this.props[`${installation.subId}`]
    )

     //console.log('state-installation: ',this.state.installation)
    
    const { getFieldDecorator } = this.props.form
    const { buttonDownloadReportIsLoading, programs } = this.props
    const orderByOptions = [
      {
        value: 'programmed_start_date',
        name: 'Fecha Inicio Programada',
      },
    ]
    const hasModificationOptions = [
      { value: '', name: '-' },
      { value: 'true', name: 'Sí' },
      { value: 'false', name: 'No' },
    ]

    const hasNup = [
      { value: '', name: '-' },
    ]    

    return (
      <>
      <Card
        id="request-filter"
        bordered={true}
        className="main-form"
        title='Filtrar'> 
        <Form layout="inline">
          <Row gutter={64} className='form-content'>
            {programs.selectedProgram === 'historic' ?
              <Col xs={24} md={8} xl={4}>
                <FormItem
                  id="program-id-filter-form-item"
                  label='Programa' 
                  decorator={getFieldDecorator}
                  formId='program_id'
                  args={basicRule(false, '')}>
                  <ProgramFilter
                    programs={programs.historic}
                    width={'200px'}
                  />
                </FormItem>
              </Col> : null
            }
            <Col xs={24} md={8} xl={4}>
              <FormItem 
                label='Correlativo' 
                decorator={getFieldDecorator}
                formId='correlative'
                args={basicRule(false, '')}>
                  <CorrelativeFilter onPressEnter={this.handleFilter} width={'200px'}/>
              </FormItem>
            </Col>
            <Col xs={24} md={8} xl={5}>
              <FormItem 
                label='Rango de Fechas' 
                decorator={getFieldDecorator}
                formId='period'
                args={basicRule(false, '')}>
                  <DatePicker width={'250px'} isSearch={true}/>
              </FormItem>
            </Col>
            <Col xs={24} md={8} xl={4}>
              <FormItem 
                label='Modificaciones pendientes:' 
                decorator={getFieldDecorator}
                formId='has_modifications'
                args={{
                  initialValue: '',
                  ...basicRule(false, ''),
                }}>
                  <OrderByFilter
                    options={hasModificationOptions}
                    width={'200px'}
                  />
              </FormItem>
            </Col>
            <Col xs={24} md={8} xl={4}>
              <FormItem 
                label='Estado/s' 
                decorator={getFieldDecorator}
                formId='status'
                args={basicRule(false, '')}>
                  <StatusFilter statuses={statuses} width={'200px'}/>
              </FormItem>
            </Col>
            {
            <Col xs={24} md={8} xl={4}>
              <FormItem 
                span={6} 
                label='Tipo de Instalación' 
                decorator={getFieldDecorator}
                formId='type'
                args={{
                  ...basicRule(false, ''),
                  initialValue: ''
                }}>
                  <TypeSelector handler={this.handleType} includeAll={true} width='250px'/>
              </FormItem>
            </Col>
            }

          
          {
            this.state.type !== '' ? (
              <Col xs={24} md={8} xl={4}>
                <FormItem 
                  label={`Seleccione ${installation.mainText}`} 
                  decorator={getFieldDecorator}
                  formId='installation'
                  args={basicRule(false, '')}>
                    <InstallationSelector
                      main={true}
                      width={'200px'}
                      choices={installationChoices}
                      handler={this.handleInstallation}
                    />
                </FormItem>
              </Col>
            ) : null
          }

          {
           
           this.state.type === 'substation' ? (  
           <Col xs={24} md={12} xl={8}>
             <FormItem 
               label='Trabajo Sobre:' 
               decorator={getFieldDecorator}
               formId='installation_subtype'
               args={{initialValue: 1,...basicRule(false, '')}}>
               <ElementsSubFilter 
                 main={true}
                 choices={subElms} 
                 width={'350px'}
                 handler={this.handleInstallationSUB}                
               />
             </FormItem>
           </Col>
           ) : null
           } 
            
          {
            //Elementos 5941
            this.state.type === 'substation' && installation_subtype.valor === 1 ? null :
            this.state.installation !== '' && this.state.type !== '' && //installation_subtype.valor !== 1 &&
            installation_subtype.valor !== 99 && installation_subtype.valor !== 91 && installation_subtype.valor !== 92  && 
            installation_subtype.valor !== 93  && installation_subtype.valor !== 94  && installation_subtype.valor !== 95 ? ( 
              
              <Col xs={24} md={8} xl={8}>
                <FormItem 
                  label={`Seleccione  ${installation.subText}`} 
                  decorator={getFieldDecorator}
                  formId='sub_installation'
                  args={basicRule(false, '')}>
                    <InstallationSelector
                      main={false}
                      width={'350px'}
                      choices={subInstallationChoices}
                    />
                </FormItem>
              </Col>
            ) : null
          }

            <Col xs={24} md={12} xl={8}>
              <FormItem 
                label='Empresa/s' 
                decorator={getFieldDecorator}
                formId='company'
                args={basicRule(false, '')}>
                  <CompanyFilter companies={companies} width={'350px'}/>
              </FormItem>
            </Col>

            {/*
            <Col xs={24} md={8} xl={4}>
              <FormItem 
                label='NUP' 
                decorator={getFieldDecorator}
                formId='nup'
                args={basicRule(false, '')}>
                  <NupFilter onPressEnter={this.handleFilterNUP} width={'100px'}/>
              </FormItem>
            </Col>
            */}

            <Col xs={24} md={4} xl={2}>
              <FormItem 
                label='NUP'
                formId='nup'
                decorator={getFieldDecorator}
                args={basicRule(false, '')}>
               <NupFilter 
               options={hasNup}
               proyectos={proyectos} width={'90px'}/>
              </FormItem>
            </Col>

            <Col xs={24} md={8} xl={4}>
              <FormItem 
                label='Ordenar por' 
                decorator={getFieldDecorator}
                formId='order_by'
                args={{
                  initialValue: "programmed_start_date",
                  ...basicRule(false, ''),
                }}>
                  <OrderByFilter
                    options={orderByOptions}
                    width={'200px'}
                  />
              </FormItem>
            </Col>
            <Col xs={24} md={8} xl={4}>
              <FormItem 
                label='Orden' 
                decorator={getFieldDecorator}
                formId='order'
                args={{
                  initialValue: "DESC",
                  ...basicRule(false, ''),
                }}>
                  <OrderDirectionFilter width={'200px'}/>
              </FormItem>
            </Col>
          </Row>
          <Row className="form-buttons">
              <Button
                type="primary" 
                icon="filter"
                onClick={this.handleFilter}
              >
                Aplicar Filtros
              </Button>
              {/* Limpiar */}
              <Button 
                id="clean-filters-button"
                icon="delete"
                type="secondary" 
                onClick={this.cleanFilters}
              >
                Limpiar Filtros
              </Button>
              {
                
                selectedProgram !== 'historic' 
                ? <Button
                    id="download-plexos-plp-button"
                    type="preliminary" 
                    icon="file-excel"
                    onClick={this.openPlexosModal}
                  >
                    Informe Plexos - PLP
                  </Button>
                : null
              }
              <Button
                id="download-report-button"
                type="dashed" 
                icon="file-excel"
                onClick={this.handleDownloadReport}
                disabled={buttonDownloadReportIsLoading}
                loading={buttonDownloadReportIsLoading}
              >
                Descargar Reporte
              </Button>
            </Row>
          </Form>
        </Card>
        <Plexos isVisible={this.state.isPlexosVisible} handleCancel={this.closePlexosModal}/>
      </>
    )
  }
}

const wrappedComponent = Form.create({ name: 'filterWorkRequest' })(FilterRequests);
export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent)
