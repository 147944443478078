import {
  filterConstants
} from '../constants/filter'
import {
  filterWorkRequest as Filter
} from '../api/filters'
import { parseJSON } from '../../utils/misc'

export const filterWorkRequest = (params) => {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: filterConstants.FILTER_WORK_REQUEST
    })
    return Filter(auth.token, params)
      .then(parseJSON)
      .then(data => data.map(pmc => (pmc.work_request)))
      .then(response => {
        dispatch({
          type: filterConstants.FILTER_WORK_REQUEST_SUCCESS,
          payload: response
        })
      })
      .catch(error => {
        console.log('FILTER_WORK_REQUEST_ERROR', error)
        dispatch({
          type: filterConstants.FILTER_WORK_REQUEST_ERROR
        })
      })
  }
}
