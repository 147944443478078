import React from 'react'
import {Icon, notification} from 'antd'

export const successIcon = <Icon type="check-circle" style={{ color: '#52c41a' }} />
export const failureIcon = <Icon type="bug" style={{ color: '#eb2f96' }} />
export const warningIcon = <Icon type='warning' style={{color: '#ffffb3'}} />

export const showNotification = (message, description, mode) => {
  let icon = successIcon
  switch (mode) {
    case 'error':
      icon = failureIcon
      break
    case 'warning':
      icon = warningIcon
      break
    default:
      break
  }
  const duration = mode === 'error'? 10: 4.5;
  notification.open({
    duration,
    message,
    description,
    icon
  })
}
