import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Login from '../Login'
import AuthLoader from '../../components/AuthLoader'
import NotFound from '../../components/NotFound'
import {
  checkAuthUser
} from '../../store/actions/auth'

export default function (ComposedComponent, allowedUserTypes) {
  class Authentication extends Component {
    componentDidMount () {
      this.props.checkAuthUser()
    }

    render () {
      if (this.props.authenticating) {
        return (
          <>
            <AuthLoader />
          </>
        )
      } else {
        return (
          <>
            {
              this.props.isAuthenticated
                ? (allowedUserTypes.length === 0 || allowedUserTypes.includes(this.props.userType))
                  ? <ComposedComponent {...this.props} />
                  : <NotFound />
                : <Login />
            }
          </>
        )
      }
    }
  }

  const mapStateToProps = ({ auth, user }) => ({
    isAuthenticated: auth.isAuthenticated,
    authenticating: auth.authenticating,
    userType: user.userType
  })

  const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
      checkAuthUser
    }, dispatch)
  )

  return connect(mapStateToProps, mapDispatchToProps)(Authentication)
}
