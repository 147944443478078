import React from 'react'
import { Card, Form, Button, Icon, Row, Col } from 'antd'
import { showNotification } from '../../../utils/notification'
import AddRequestMassiveForm from '../../../containers/AddRequestMassiveForm'
import FormItem from '../../../components/FormItem'
import Upload from '../../../components/Upload'
import AccessControl from '../../AccessControl'
import CompanySelector from '../../AddRequestItem/CompanySelector'
import moment from 'moment'

class Component extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      initial: true,
      isProcessing: false,
      hasErrors: false,
      companyId: undefined,
      editedCompanyId: false
    }
  }

  componentDidMount() {
    // If the user is superadmin, then he must select a company before
    // uploading the requests.
    if(this.props.userType === 'superadmin')
      this.setState({companyId: this.props.companyId})
  }

  configureData = (data) => {
    if (!data || data === '') { return [] }
    const allKeys = [
      { key: 'tipo_instalacion'},
      { key: 'instalacion'},
      { key: 'sub_instalacion'},
      { key: 'tipo_subestacion'},
      { key: 'descripcion_trabajo'},
      { key: 'riesgo_trabajos'},
      { key: 'fecha_inicio'},
      { key: 'fecha_termino'},
      { key: 'postergable'},
      { key: 'fecha_inicio_alternativa'},
      { key: 'fecha_termino_alternativa'},
      { key: 'comentarios_adicionales'},
      { key: 'nup'},      
    ]
    const rows = data.map((request, index) => {
      let rqObject = {
        index
      }
      allKeys.forEach(config => {
        rqObject[config.key] = config.key in request ? request[config.key] : 'Campo no Encontrado'
      })
      return rqObject
    })
    return rows
  }

  groupRequest = (values) => {
    const { dataset } = this.props
    const datasetLength = dataset.length
    const entries = Object.entries(values)
    const groups = {}
    for(let index = 0; index < datasetLength; index++) {
      const replacement = `${index}_`
      entries.forEach(item => {
        if(item[0].startsWith(replacement)) {
          const newKey = item[0].replace(replacement, '')
          if (groups[index]) {
            groups[index][newKey] = item[1]
          } else {
            groups[index] = {
              [newKey]: item[1],
              file_letter: values.file_letter,
              file_description: values.file_description
            }
          }
        }
      })
    }
    return groups
  }

  composeParams = (raw_params) => {
    const { user } = this.props
    let params = { ...raw_params}
    // Parse Dates
    params.programmed_start_date = params.period[0].toISOString()
    params.programmed_end_date = params.period[1].toISOString()
    delete params.period

    if (params.alternative_period){
      params.alternative_start_date = params.alternative_period[0].toISOString()
      params.alternative_end_date = params.alternative_period[1].toISOString()
      delete params.alternative_period
    }

    
    // Translate Risk
    switch(params.risk) {
      case 'none':
        params.risk = "Sin Riesgo"
        break
      case 'low':
        params.risk = "Riesgo Bajo"
        break
      case 'mid':
        params.risk = "Riesgo Medio"
        break
      case 'high':
        params.risk = "Riesgo Alto"
        break
      default:
        break
    }

    if (params.optional_comments) {
      params.additional_comments = params.optional_comments
      delete params.optional_comments
    }

    params.program_id = this.props.activeKeyProgram.id
    params.user = user.id
    if(!params.company_id) {
      const currentProfile = user.profiles.find(profile => profile.id === user.currentProfile)
      // If current Profile were undefined the user wouldn't reach here, because profiles are
      // used to fill required form fields (e.g. installation).
      if (currentProfile === undefined)
        throw new Error('currentProfile is undefined, the user may have no profiles.')
      params.company = currentProfile.companyId
    } else {
      params.company = params.company_id
      delete params.company_id
    }
    return params
  }

  showErrors = (err) => {
    const entries = Object.entries(err)
    const withErrors = []
    
    let fileErrors = undefined
    entries.forEach(item => {

      const index = parseInt(item[0].split('_')[0])

      if (isNaN(index)) {
        fileErrors = 'Es necesario que ambos documentos sean cargados para realizar la carga masiva'
      }
      if (!withErrors.includes(index) && !isNaN(index)){
        withErrors.push(index)
      }

      
    })
    return `${fileErrors ? `${fileErrors}.\n `: ''}Las solicitudes ${withErrors.map(item => item+1).join(', ')} presentan errores. Sólo se puede realizar la carga masiva si todas las solicitudes son válidas.`
  }

  handleMassiveSubmit = () => {
    if (this.state.isProcessing) {
      return
    }
    const { form, requestCreator, activeKeyProgram } = this.props
    this.setState({isProcessing: true})
    form.validateFields((err, values) => {

      if(err) {
        showNotification(
          'Error de Validación', 
          this.showErrors(err),
          'error'
        )
        this.setState({isProcessing: false})
      }
      else {
        let requests = this.groupRequest(values)
        let mensaje = ""
        requests = Object.entries(requests).map(
          request => {
            const currentRequest = request[1]
            if ( values.company_id ) {
              currentRequest.company_id = values.company_id
            }
            let now = new Date().toISOString()
            let results = this.composeParams(currentRequest)
            let startDate 

            for (let [key, value] of Object.entries(results))
            {
              if( key === 'programmed_start_date')
              {
                startDate = value
                if( value < now )
                {
                  mensaje = "La fecha de inicio deber ser igual o mayor que la fecha actual."
                }
              } else if( key === 'programmed_end_date' )
              {
                if( moment(value).diff(moment(startDate), 'days') < 1 )
                {
                  mensaje = "La fecha de término debe ser mayor a 24 horas desde la fecha de inicio."
                }
              }
              //alert(`${key}: ${value}`)
            }

            return results
          }
        )

        if( mensaje !== "" )
        {
          showNotification(
            'Error de Validación',
            mensaje,
            'error'
          )
        }
        else
        {
          requestCreator(
            requests,
            activeKeyProgram.id
          )
        }

        this.setState({isProcessing: false})
      }
    })
  }

  handleCompany = (value) => {
    const { form, dataset } = this.props
    this.setState({
      companyId: value,
      editedCompanyId: true
    }, () => {
      const fieldsToReset = []
      for (let i = 0; i < dataset.length; i++)
        fieldsToReset.push(`${i}_installation`, `${i}_sub_installation`)
      form.resetFields(fieldsToReset)
    })
  }

  render () {
    const { companyId, editedCompanyId } = this.state
    const { user, companies } = this.props
    const { getFieldDecorator } = this.props.form
    const dataset = this.configureData(this.props.dataset)
    console.log('*******DS***********')
    console.log(dataset)
    const components = dataset.map((item, index) => {
      return (
        <div className='massive-form-item'>  
          <h2>Solicitud de Ingreso Masivo #{index + 1}</h2>
          <Card key={index} style={{background: 'none'}}>
            <AddRequestMassiveForm index={index} item={item} form={this.props.form} company={companyId} editedCompanyId={editedCompanyId}/>
          </Card>
        </div>
      )
    })
    
    return (
      <>
        <Form className='massive-request-form'>
          <AccessControl
            userType={user.userType}
            allowedUserTypes={['superadmin']}
          >
            <Row className='massive-company'>
              <FormItem
                span={10}
                label="Empresa"
                extra="Campo visible solo para usuarios del Coordinador."
                decorator={getFieldDecorator}
                formId="company_id"
                args={{
                  rules: [{
                    required: true, 
                    message: 'Debe seleccionar la empresa a la que corresponde la solicitud.'
                  }],
                  initialValue: this.state.companyId
                }}
              >
                <CompanySelector
                  companies={companies}
                  handler={this.handleCompany}
                />
              </FormItem>
            </Row>
          </AccessControl>
          <Row className='massive-documents'>
            <Col
              span={8}
              id="massive-form-required-documents">
              <h2>Documentos requeridos</h2>
              <FormItem 
                span={10}
                label='PDF Carta de Solicitud'
                decorator={getFieldDecorator}
                formId='file_letter'
                args={{
                  rules: [{
                    required: true, 
                    message: 'Seleccione un archivo'
                  }]
                }}>
                <Upload accept=".pdf"/>
              </FormItem>
              <FormItem 
                span={10}
                label='Excel descripción del mantenimiento'
                decorator={getFieldDecorator}
                formId='file_description'
                args={{
                  rules: [{
                    required: true, 
                    message: 'Seleccione un archivo'
                  }]
                }}>
                <Upload accept=".xlsx,.xls,.xlsm"/>
              </FormItem>
            </Col>
            <Col
              style={{marginLeft: '40px'}}
              span={4}>
              <Button
                id="submit-massive-form-button"
                type='primary'
                onClick={this.handleMassiveSubmit}
                disabled={this.state.isProcessing}
                style={{marginTop: '40px'}}
              >
                <Icon type="ordered-list" /> Validar e Ingresar Mantenimientos
              </Button>
            </Col>
          </Row>
          <h2>Solicitudes a ingresar</h2>
          {components}
        </Form>
      </>
    )
  }
}

export default Form.create({})(Component)
