export const pmpmConstants = {
  TOGGLE_CALENDAR_VIEW: 'TOGGLE_CALENDAR_VIEW',
  OPEN_ADD_REQUEST_MODAL: 'OPEN_ADD_REQUEST_MODAL',
  CLOSE_ADD_REQUEST_MODAL: 'CLOSE_ADD_REQUEST_MODAL',
  OPEN_DELETE_REQUEST_MODAL: 'OPEN_DELETE_REQUEST_MODAL',
  CLOSE_DELETE_REQUEST_MODAL: 'CLOSE_DELETE_REQUEST_MODAL',
  OPEN_APROVAL_REQUEST_MODAL: 'OPEN_APROVAL_REQUEST_MODAL',
  CLOSE_APROVAL_REQUEST_MODAL: 'CLOSE_APROVAL_REQUEST_MODAL',
  OPEN_MASSIVE_ADD_REQUEST_MODAL: 'OPEN_MASSIVE_ADD_REQUEST_MODAL',
  CLOSE_MASSIVE_ADD_REQUEST_MOODAL: 'CLOSE_MASSIVE_ADD_REQUEST_MOODAL',
}
