import React from 'react'
import {Radio} from 'antd'

const {Group, Button} = Radio

const TypeSelector = (props) => {
  const { value, onChange, handler, includeAll } = props
  return (
    <Group
      value={value}
      onChange={({target}) => {
        const { value } = target
        onChange(value)
        handler(value)
      }}
      style={{width: props.width || '100%'}}
    >
      {includeAll ? <Button value=''>Todas</Button> : null }
      <Button value='central'>Central</Button>
      <Button value='line'>Línea</Button>
      <Button value='substation'>Subestación</Button>
    </Group>
  )
}

export default TypeSelector
