import {
  userConstants
} from '../constants/user'
import {
  reqGetUser,
  reqGetUsers,
  reqToggleDisableUser
} from '../api/user'
import { showNotification } from '../../utils/notification'
import { parseJSON } from '../../utils/misc'

export function getUsers () {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({ type: userConstants.FETCH_USER_LIST_REQUEST })
    reqGetUsers (auth.token)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: userConstants.FETCH_USER_LIST_SUCCESS,
          payload: response
        })
      })
      .catch(error => {
        console.error(error)
        dispatch({ type: userConstants.FETCH_USER_LIST_ERROR })
        return showNotification(
          'Ha ocurrido un error',
          'No fue posible cargar el listado de usuarios',
          'error'
        )
      })
  }
}

export function getUser (userId) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({ type: userConstants.FETCH_USER_REQUEST })
    reqGetUser (auth.token, userId)
      .then(response => {
        dispatch({
          type: userConstants.FETCH_USER_SUCCESS,
          payload: response.data.user
        })
      })
      .catch(error => {
        console.error(error)
        dispatch({ type: userConstants.FETCH_USER_ERROR })
      })
  }
}

export function toggleDisableUser (userId) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({ type: userConstants.TOGGLE_DISABLE_USER_REQUEST })
    reqToggleDisableUser (auth.token, userId)
      .then(parseJSON)
      .then(response => {
        dispatch({
          type: userConstants.TOGGLE_DISABLE_USER_SUCCESS,
          payload: response.data
        })
        return showNotification(
          'Operación exitosa',
          'Se cambió el estado de visibilidad/acceso del usuario',
          'success'
        )
      })
      .catch(error => {
        console.error(error)
        dispatch({ type: userConstants.TOGGLE_DISABLE_USER_ERROR })
        return showNotification(
          'Ha ocurrido un error',
          'No fue posible cambiar el estado de visibilidad/acceso del usuario',
          'error'
        )
      })
  }
}

export function changeProfile (profileId) {
  return {
    type: userConstants.CHANGE_PROFILE,
    payload: profileId
  }
}

export function openAdminUserModal (userId) {
  return {
    type: userConstants.OPEN_ADMIN_USER_MODAL,
    payload: userId
  }
}

export function closeAdminUserModal () {
  return {
    type: userConstants.CLOSE_ADMIN_USER_MODAL
  }
}
