import React from 'react'
import {Radio} from 'antd'

const {Group, Button} = Radio

const Component = (props) => {
  const { value, onChange, handler } = props
  return (
    <Group
      value={value}
      onChange={({target}) => {
        const { value } = target
        onChange(value)
        handler(value)
      }} 
      style={{width: props.width || '100%'}}
    >
      <Button value='Libre'>Libre</Button>
      <Button value='Regulado'>Consumo Regulado</Button>
      <Button value='Regulado-Libre'>Consumo Regulado y Libre</Button>
      <Button value='No tiene Consumo Afectado'>No tiene Consumo Afectado</Button>
    </Group>
  )
}

export default Component
