import React from 'react'
import { Select } from 'antd'

const { Option } = Select

const Component = (props) => {
  const {value, onChange, width} = props
  return (
    <Select
      value={value}
      onChange={(value) => {
        onChange(value)
      }}
      style={{width: width || '100%'}}
    >
      <Option
      key="order-DESC"
      value="DESC"
      >
      Descendente
      </Option>
      <Option
      key="order-ASC"
      value="ASC"
      >
      Ascendente
      </Option>
    </Select>
  )
}

export default Component