import {
  programsConstants
} from '../constants/programs'
import {
  reqMoveProgramToHistoric,
  reqGetPrograms,
  reqGetProgramDetails,
  updatePreliminaryEditon
} from '../api/programs'
import { parseJSON } from '../../utils/misc'
import { showNotification } from '../../utils/notification'

export function getPrograms () {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: programsConstants.FETCH_PROGRAMS
    })
    reqGetPrograms (auth.token)
      .then(parseJSON)
      .then(data => {
        dispatch({
          type: programsConstants.FETCH_PROGRAMS_SUCCESS,
          payload: data
        })
      })
      .catch(err => {
        console.log(err)
        dispatch({
          type: programsConstants.FETCH_PROGRAMS_ERROR
        })
      })
  }
}

export function getProgramDetails (programId) {
  return (dispatch, getState) => {
    const { auth } = getState()
    dispatch({
      type: programsConstants.ADD_PROGRAM
    })
    reqGetProgramDetails (auth.token, programId)
      .then(parseJSON)
      .then(data => {
        dispatch({
          type: programsConstants.ADD_PROGRAM_SUCCESS,
          payload: data
        })
      })
      .catch(err => {
        dispatch({
          type: programsConstants.ADD_PROGRAM_ERROR
        })
      })
  }
}

export function selectProgram (program) {
  return {
    type: programsConstants.SELECT_PROGRAM,
    payload: program
  }
}

export function movePrograms (limitDate) {
  return (dispatch, getState) => {
    dispatch({type: programsConstants.MOVE_PROGRAMS})
    const { auth, entities } = getState()
    const currentId = entities.programs.current.id
    reqMoveProgramToHistoric(auth.token, currentId, limitDate)
      .then(()=> {
        dispatch({type: programsConstants.MOVE_PROGRAMS_SUCCESS})
        showNotification('Acción completada',
          'El programa preliminar ha entrado en vigencia.',
          'success')
        dispatch(getPrograms())
      })
      .catch(() => {
        dispatch({type: programsConstants.MOVE_PROGRAMS_ERROR})
        showNotification('Error',
          'Ha ocurrido un error al pasar el programa preliminar a vigente.',
          'error')
      })
  }
}

export function selectHistoricProgram (program) {
  return {
    type: programsConstants.SELECT_HISTORIC_PROGRAM,
    payload: program
  }
}

export function changePreliminaryEdition (editionEnabled) {
  return (dispatch, getState) => {
    dispatch({type: programsConstants.UPDATE_PRELIMINARY_EDITION})
    const { auth, entities: { programs: { preliminary }}} = getState()
    updatePreliminaryEditon(auth.token, preliminary.id, editionEnabled)
      .then(parseJSON)
      .then(data => {
        dispatch({
          type: programsConstants.UPDATE_PRELIMINARY_EDITION_SUCCESS,
          payload: data
        })
        const text = editionEnabled? 'habilitada' : 'desabilitada'
        showNotification('Acción completada',
          `La carga y modificación de solicitudes ha sido ${text}.`,
          'success')
      })
      .catch(() => {
        dispatch({type: programsConstants.UPDATE_PRELIMINARY_EDITION_ERROR})
        showNotification('Error',
          'No se ha podido habilitar o deshabilitar la carga y modificación de solicitudes',
          'error')
      })
  }
}
