import { createReducer } from '../../utils/misc'
import { filterConstants } from '../constants/filter'

const initialState = {
    isFiltering: false
}

export default createReducer(initialState, {
    [filterConstants.FILTER_WORK_REQUEST]: () => ({
        isFiltering: true
    }),
    [filterConstants.FILTER_WORK_REQUEST_SUCCESS]: () => initialState,
    [filterConstants.FILTER_WORK_REQUEST_ERROR]: () => initialState
})