import React from 'react'
import { connect } from 'react-redux'
import {
  Icon,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Button,
  notification,
  Spin
} from 'antd'
import './AprovalModal.css'
import { changeRequestStatus, reloadWorkRequest } from '../../store/actions/workRequests'

const { Item } = Form
const { TextArea } = Input

const mapStateToProps = (state) => ({
  aproval: state.pmpm.aprovalAction,
  workRequestIdBatch: state.pmpm.currentRequestIdBatch,
  shouldSpin: state.pmpm.aprovingWorkRequest,
})

const mapDispatchToProps = (dispatch) => {
  return {
    changeRequestStatus: (requestIdList, params) => {
      dispatch(changeRequestStatus(requestIdList, params))
    },
    reloadWorkRequest: (requestId) => {
      dispatch(reloadWorkRequest(requestId))
    }
  }
}

const initialState = {
  rejection: ''
}

class Component extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      ...initialState
    }
  }

  handleStatusChange = (event) => {
    const { isBatchAproval, workRequestIdBatch, aproval} = this.props
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.changeRequestStatus(workRequestIdBatch, {...values, aproval})
        this.setState({...initialState})
      } else {
        const pluralText = isBatchAproval ? 'las solicitudes' : 'la solicitud'
        notification.open({
          message: 'Se ha producido un error',
          description:
            `Debe indicar una razón para rechazar ${pluralText}.`,
          icon: <Icon type="bug" style={{ color: '#eb2f96' }} />,
        })
      }
    })
  } 


  updateTextArea = (event) => {
    this.setState({
      rejection: event.target.value
    })
  }

  render() {
    const { form, aproval, isVisible, handleCancel, shouldSpin} = this.props
    const { getFieldDecorator } = form
    const isAproval = aproval
    const okButtonText = isAproval ? 'Aprobar' : 'Rechazar'
    return (
      <Modal
        visible={isVisible}
        destroyOnClose={true}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[            
        <Button type="primary" onClick={this.handleStatusChange}>
          {okButtonText}
        </Button>, 
        <Button onClick={handleCancel} className='modalCancelBtn ant-btn-secondary'>
          Cancelar
        </Button>,
        ]}
      >
        <Spin spinning={shouldSpin}>
          <Form
            layout='vertical'
          >
            <Item style={{display: 'none'}}> 
              {getFieldDecorator('aproval', {
                initialValue: isAproval,
                rules: [
                  {
                    required: true,
                    message: "Debe selecionarse una opción"
                  }
                ]
              })(
                <Radio.Group 
                  buttonStyle="solid"
                  onChange={this.aprovalChoiceHandler}
                  disabled={true}
                >
                  <Radio.Button value={true}>Aprobar</Radio.Button>
                  <Radio.Button value={false} >Rechazar</Radio.Button>
                </Radio.Group>
              )}
            </Item>
              <Row>
                <h1 className='ant-modal-title'>Confirmar Cambio de Estado: Pendiente ⟶ {isAproval ? 'Aprobado' : 'Rechazado'}</h1>
              </Row>
              <Item
                label={`${isAproval ? 'Puede indicar' : 'Indique'} el Motivo de ${isAproval ? 'Aprobación' : 'Rechazo'}`}
                help={`Indique por qué se ${isAproval ? 'aprueba' : 'rechaza'} la solicitud (${this.state.rejection.length}/7000)`}
              >
                {getFieldDecorator('rejection', {
                  rules: [
                    {
                      required: !isAproval,
                      message: "Debe indicar el motivo de rechazo"
                    }
                  ]
                })(
                  <TextArea rows={4} maxLength={7000} onChange={(event) => this.updateTextArea(event)}/>
                )}
              </Item>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

const wrappedComponent = Form.create({ name: 'approval' })(Component);

export default connect(mapStateToProps, mapDispatchToProps)(wrappedComponent)
