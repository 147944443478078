import React from 'react'
import { 
  Form,
  Row,
  Col,
  Input,
  Modal,
  Select,
  Table
} from 'antd'
import FormItem from '../../components/FormItem'


const { Item } = Form
const { Column } = Table
const { Option } = Select

const gutter = [16, 16]

const initialState = {
  id: null
}

class UserModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      ...initialState
    }
  }

  changeSelectedProfile = (event) => {
    let value = event.target.value
    this.setState({
      selectedProfile: value
    })
  }

  render() {
    const {
      selectedUser,
      onCancel,
      isVisible,
      rolesById,
      form: { getFieldDecorator }
    } = this.props
    return (
      <Modal
        title='Editar Usuario'
        visible={isVisible}
        onCancel={onCancel}
        onOk={this.props.editUser}
        okText='Editar Usuario'
        width='70%'
        destroyOnClose={true}
      >
      <Form layout="horizontal">
        <Row gutter={gutter}>
          <Col span={8}>
            <Item 
              label="Nombre de usuario"
            >
              <Input disabled value={selectedUser.name || ''}/>
            </Item>
          </Col>
          <Col span={8}>
            <Item 
              label="Email"
            >
              <Input disabled value={selectedUser.email || ''}/>
            </Item>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col span={8}>
            <Item 
              label="Tipo de Usuario"
            > 
              <FormItem
                formId={`user_type`}
                decorator={getFieldDecorator}
                args={{
                  initialValue: selectedUser.user_type
                }}
              >
                <Select>
                  <Option
                    key={`usertype-admin`}
                    value="superadmin">
                    Administrador  
                  </Option>
                  <Option
                    key={`usertype-coordinador`}
                    value="coordinador">
                    Coordinador General
                  </Option>
                  <Option
                    key={`usertype-coordinado`}
                    value="coordinado">
                    Coordinado
                  </Option>
                </Select>
              </FormItem>
            </Item>
          </Col>
          <Col span={8}>
            <Item 
              label="ID Cognito"
            >
              <Input disabled value={selectedUser.cognito_id || ''}/>
            </Item>
          </Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Item 
              label="Perfiles"
            >
              <Table dataSource={selectedUser.profiles || []}
                pagination={false}>
                <Column title="Empresa" dataIndex="company.name" key="companyName" />
                <Column title="RUT" dataIndex="company.rut" key="companyRut" />
                <Column title="Rol" key="companyRole"
                  render={
                    (profile) => {
                      const { role } = profile
                      return (
                        <FormItem 
                          formId={`profile-${profile.id}-role`}
                          decorator={getFieldDecorator}
                          args={{
                            initialValue: role.id,
                          }}
                        >
                          <Select>
                            {
                              Object.keys(rolesById).map(r => {
                                return <Option
                                  key={`role-${rolesById[r].id}`}
                                  value={rolesById[r].id}>
                                  {rolesById[r].name}
                                </Option>
                              })
                            }
                          </Select>
                        </FormItem>
                      )
                    }
                  }/>
              </Table>
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
    )
  }
}

export default UserModal
